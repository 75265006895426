(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$q', '$rootScope', '$state', '$uibModal', '$http', 'showResult', '$sessionStorage', 'Principal','$timeout', '$scope'];

    function SidebarController ($q, $rootScope, $state, $uibModal, $http, showResult,$sessionStorage, Principal,$timeout, $scope) {
        var vm = this;

        $rootScope.isMenuLoaded = false;

        vm.isMenuLoaded = isMenuLoaded;
        function isMenuLoaded(){
            return $rootScope.isMenuLoaded;
        }

        showResult.sendMessage("");
        vm.mostrarPerfil = false;
        vm.showdashboard = false;
        vm.closeSidebar = false;
        vm.showmainevidencias = false;
        vm.indicadores = '';
        vm.canales = '';
        vm.filtrosvisiones = '';
        vm.razonSocial = '';
        vm.indicadorPerfectStore = null;
        vm.mostrarNegocio = false;
        vm.mostrarSegmentacion = false;
        vm.mostrarMainEvidencias = false;
        vm.disabledButtonSegmentacion = true;
        vm.mostrarCanales = false;
        vm.mostrarIndicadores = false;
        vm.mostrarMenu = false;
        vm.mostrarSubMenu = false;
        vm.menuElegido = [];
        vm.mostrarFiltros = false;
        vm.mostrarVision = '';
        vm.mostrarValorVision = '';
        vm.mostrarGeografia = false;
        vm.mostrarListaArea = false;
        vm.mostrarListaEstado = false;
        vm.mostrarListaUbicacion = false;
        vm.mostrarListaRegion = false;
        vm.mostrarValorVisionDate = false;
        vm.mostrarFecha = false;
        vm.disabledButtonMostrarResultados = true;
        vm.medidaSecundariaSeleccionada = [];
        vm.soloEvidencias = false;
        vm.haveMenu = false;
        vm.menuPadreArr = [];
        vm.usuarioId = Principal.identity().$$state.value.Id;

        function regex(datos) {
            var myRe = new RegExp('##[0-9]*##','g');
            return datos.replace(myRe,'');
        }

        if ($sessionStorage) {
            if ($sessionStorage.cliente) {
                vm.cliente = $sessionStorage.cliente;
                vm.razonSocial = $sessionStorage.cliente.RazonSocial;
                vm.imagenbase64 = $sessionStorage.cliente.Imagen;
                vm.nombreProyecto = $sessionStorage.nombreProyecto;
            } else {
                $http.get('api/clientes/user/' + vm.usuarioId).then(function (response) {
                    vm.cliente = response.data;
                    vm.razonSocial = vm.cliente.RazonSocial;
                    vm.imagenbase64 = vm.cliente.Imagen;
                });
            }
        }

        vm.Pais = '';
        // vm.perfil = perfil;
        vm.limpiarFiltroVision = limpiarFiltroVision;
        vm.abrirNegocio = abrirNegocio;
        vm.abrirSegmentacion = abrirSegmentacion;
        vm.abrirMainEvidencias = abrirMainEvidencias;
        vm.abrirCanales = abrirCanales;
        vm.abrirMenu = abrirMenu;
        vm.abrirSubMenu = abrirSubMenu;
        vm.abrirIndicadores = abrirIndicadores;
        vm.abrirFiltros = abrirFiltros;
        vm.abrirVision = abrirVision;
        vm.abrirFecha = abrirFecha;
        vm.cambioCanal = cambioCanal;
        vm.cambioIndicador = cambioIndicador;
        vm.elegirMenu = elegirMenu;
        vm.buscarFiltrosVisiones = buscarFiltrosVisiones;
        vm.openVisionValue = openVisionValue;
        vm.mostrarResultados = mostrarResultados;
        vm.changeProject = changeProject;
        vm.logOut = logOut;
        vm.btnCloseSidebar = btnCloseSidebar;
        vm.asignarVision = asignarVision;
        vm.resetOptions = resetOptions;
        vm.validarFiltros = validarFiltros;
        vm.openModalSecundarias = openModalSecundarias;
        vm.volverDashboard = volverDashboard;
        vm.darMostrar = darMostrar;
        vm.openVisionDate = openVisionDate;
        vm.asignarVisionPredefinida = asignarVisionPredefinida;
        vm.opcionesOpen = false;
        vm.sendEventEvidencias = sendEventEvidencias;
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = (panel.scrollHeight + 14) + "px";
                }
            });
        }

        var showResultSubscribe = showResult.subscribe(function (x) {
            if (x === true) {
                mostrarResultados();
            }
            else if( x === "cargarTendencias"){
                    if(vm.indicadorPerfectStore) {
                            cargarTendenciasPS();
                        }
                    else{
                            cargarTendencias();
                        }
                }
            else if( x === "cargarMapa"){
                    if(vm.indicadorPerfectStore) {
                            cargarMapaPS();
                        }
                    else{
                            cargarMapa();
                        }
                }
            else if( x === "cargarEvidencias"){
                    if(vm.indicadorPerfectStore) {
                        }
                    else{
                            cargarEvidencias();
                        }
                }
            else{
                angular.forEach(vm.indicadores, function (indicador){
                    if(x === indicador.NombreIndicador){
                        cambioIndicador(indicador);
                    }
                })

            }
        });

        function cargarTendenciasPS(){
                vm.cargandoTendencia = true;
                vm.resultadoTendencias = [];
                var resultadotendencia = [];
                var dataPerfectStore = {
                        "idProyecto": "" + vm.proyectoId,
                        "idUsuario": "" + vm.usuarioId,
                        "nombrecanal": "" + vm.canalElegido,
                        "idIndicador": "" + vm.indicadorPerfectStore,
                        "idMenuPadre": "" + vm.idMenuPadreIndicador,
                        "fil": vm.arregloFiltros,
                        "fechadesde": "" + vm.fechas.FechaInicio,
                        "fechahasta": "" + vm.fechas.FechaFin,
                        "vision": null
                };
                $http.post('api/resultado/tendencias/perfectStore', dataPerfectStore).then(function (response) {
                        resultadotendencia = response.data;
                        angular.forEach(resultadotendencia, function (item) {
                                var obj = {
                                        valor: item.resultado,
                                        mes: item.mes,
                                        anio: item.anio,
                                        fechas: angular.copy(vm.fechas)
                                };
                                vm.resultadoTendencias.push(obj);
                            });
                        sortByMonth(vm.resultadoTendencias);
                        vm.cargandoTendencia = false;
                        vm.resultadoEvidencia.Content = [];
                        vm.progresoDeCarga = false;
                        var msg = "cargaChartTendencia";
                        showResult.sendMessage(msg);
                        vm.mostrarTendencia = true;
                    }, function errorCallback() {
                        vm.resultadoTendencias = [];
                        vm.resultadoEvidencia.Content = [];
                        vm.resultadoMapas = [];
                        vm.showdashboard = true;
                        vm.showmainevidencias = false;
                        vm.progresoDeCarga = false;
                        vm.cargando = false;
                        vm.showMessageError = true;
                    });
        }

        function cargarMapaPS(){
                    vm.cargandoMapa = true;
                    vm.resultadoMapas = [];
                    var dataPerfectStore3 = {
                            "idProyecto": "" + vm.proyectoId,
                            "idUsuario": "" + vm.usuarioId,
                            "nombrecanal": "" + vm.canalElegido,
                            "idIndicador": "" + vm.idIndicadorElegido,
                            "idMenuPadre": "" + vm.idMenuPadreIndicador,
                            "fil": vm.arregloFiltros,
                            "fechadesde": "" + vm.fechas.FechaInicio,
                            "fechahasta": "" + vm.fechas.FechaFin,
                            "resultadoBase": "" + vm.resultadoTotales.valor,
                            "vision": []
                    };
                    $http.post('api/mapa/perfectStore', dataPerfectStore3).then(function (response) {
                            vm.resultadoMapas = response.data;
                            var msg = "cargaMapaDatos";
                            showResult.sendMessage(msg);
                        }, function errorCallback() {
                            vm.resultadoMapas = [];
                            vm.cargando = false;
                            vm.cargandoMapa = true;
                            vm.executingQueryResultado = false;
                            vm.showMessageError = true;
                        });
        }

        function cargarEvidencias(){
            vm.cargandoEvidencia = true;
            var dataEvidencias = {
                "page": 1,
                "idProyecto": "" + vm.proyectoId,
                "idUsuario": "" + vm.usuarioId,
                "nombrecanal": "" + vm.canalElegido,
                "idIndicador": "" + vm.idIndicadorElegido,
                "idMenuPadre": "" + vm.idMenuPadreIndicador,
                "fil": vm.arregloFiltros,
                "fechadesde": "" + vm.fechas.FechaInicio,
                "fechahasta": "" + vm.fechas.FechaFin,
                "vision": []
            };

            $http.post('api/evidencias', dataEvidencias).then(function (response) {
                    vm.resultadoEvidencia = response.data;
                    if(vm.resultadoEvidencia.Content.length > 0){
                            sendEventEvidencias();
                            vm.mostrarEvidencia = true;
                        }
                    else{
                            vm.mostrarEvidencia = false;
                    }
                    vm.cargandoEvidencia = false;
                }, function errorCallback(){
                    vm.cargando = false;
                    vm.cargandoEvidencia = true;
                    vm.executingQueryResultado = false;
                    vm.showMessageError = true;
                });
        }

        function cargarTendencias() {
            vm.resultadoTendencias = [];
            vm.cargandoTendencia = true;
            var data2 = {
                    "idProyecto": "" + vm.proyectoId,
                    "idUsuario": "" + vm.usuarioId,
                    "nombrecanal": "" + vm.canalElegido,
                    "idIndicador": "" + vm.idIndicadorElegido,
                    "idMenuPadre": "" + vm.idMenuPadreIndicador,
                    "fil": vm.arregloFiltros,
                    "fechadesde": "" + vm.fechas.FechaInicio,
                    "fechahasta": "" + vm.fechas.FechaFin,
                    "vision": []
            };

            var resultadotendencia = [];
            if (vm.resultado.length > 0 && vm.resultado[0].valor !== 0) {
                    $http.post('api/resultado/tendencias', data2).then(function (response) {
                            resultadotendencia = response.data;
                            vm.progresoDeCarga = false;
                            angular.forEach(resultadotendencia, function (item) {
                                    var obj = {
                                            valor: (item.resultado).toFixed(2),
                                            mes: item.mes,
                                            anio: item.anio,
                                            fechas: angular.copy(vm.fechas)
                                    };
                                    vm.resultadoTendencias.push(obj);
                                });
                            sortByMonth(vm.resultadoTendencias);
                            vm.cargandoTendencia = false;
                            var msg = "cargaChartTendencia";
                            showResult.sendMessage(msg);
                        }, function errorCallback(){
                            vm.cargando = false;
                            vm.cargandoTendencia = true;
                            vm.executingQueryResultado = false;
                            vm.showMessageError = true;
                        });
                }
            else {
                    vm.resultadoTendencias = [];
                }
        }

        function cargarMapa() {
                vm.cargandoMapa = true;
                vm.resultadoMapas = [];
                var data = {
                        "idProyecto": "" + vm.proyectoId,
                        "idUsuario": "" + vm.usuarioId,
                        "nombrecanal": "" + vm.canalElegido,
                        "idIndicador": "" + vm.idIndicadorElegido,
                        "idMenuPadre": "" + vm.idMenuPadreIndicador,
                        "fil": vm.arregloFiltros,
                        "fechadesde": "" + vm.fechas.FechaInicio,
                        "fechahasta": "" + vm.fechas.FechaFin,
                        "vision": null
                };

               $http.post('api/mapa', data).then(function (response) {
                        vm.resultadoMapas = response.data;
                        var msg = "cargaMapaDatos";
                        showResult.sendMessage(msg);
                    }, function errorCallback(){
                        vm.cargando = false;
                        vm.cargandoMapa = true;
                        vm.executingQueryResultado = false;
                        vm.showMessageError = true;
                    });
        }

        function logOut() {
            showResult.desubscribe();
            vm.proyectoId = null;
            $rootScope.proyectoId = null;
            $state.go('home');
        }

        function darMostrar(showResult) {
            vm.data = function () {
                return showResult.getMessage();
            }
        }

        function openVisionDate() {
            vm.mostrarValorVisionDate = !vm.mostrarValorVisionDate;
        }

        function volverDashboard() {
            vm.showdashboardResultado = !vm.showdashboardResultado;
        }

        function changeProject() {
            vm.openModalProjects();
            vm.indicadores = '';
            vm.perfectStores = '';
            vm.canales = '';
            vm.filtrosvisiones = '';
            vm.idIndicadorElegido = '';
            vm.canalElegido = '';
            vm.indicadorElegido = '';
            vm.idMenuPadreIndicador = '';
            vm.visionSeleccionada = "null";
            vm.filtros = {Geografia: '', Distribucion: '', Adicionales: ''};
            vm.fechas = {FechaInicio: "null", FechaFin: "null"};
            vm.visionSeleccionada = [];
            resetOptions();
            vm.showdashboard = false;
            vm.showmainevidencias = false;
        }

        function resetOptions() {
            vm.mostrarNegocio = false;
            vm.mostrarSegmentacion = false;
            vm.mostrarMainEvidencias = false;
            vm.disabledButtonSegmentacion = true;
            vm.mostrarCanales = false;
            vm.mostrarIndicadores = false;
            vm.mostrarMenu = false;
            vm.mostrarSubMenu = false;
            vm.mostrarSegmentacion = false;
            vm.mostrarFiltros = false;
            vm.mostrarVision = '';
            vm.mostrarValorVision = '';
            vm.menuPadreArr = [];
        }

        function limpiarGraficasSecundarias() {
            var msg = "limpiar";
            showResult.sendMessage(msg);
        }

        function btnCloseSidebar() {
            vm.closeSidebar = !vm.closeSidebar;
        }

        vm.openModal = function (filtro) {
            //var objeto = {filtros: filtro, select: vm.filtros.Geografia};
            var objeto = {filtros: filtro,
                select: vm.filtros.Geografia,
                proyectoId: "" + vm.proyectoId,
                nombreCanal: "" + vm.canalElegido,
                idIndicador: "" + vm.idIndicadorElegido,
                idUsuario: "" + vm.usuarioId,
                fechaDesde: "null",
                fechaHasta: "null",
                arregloFiltros: vm.arregloFiltros
            };
            $uibModal.open({
                templateUrl: 'app/components/geografia/geografia.html',
                controller: 'GeografiaController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    filterList: objeto
                }
            }).result.then(function (result) {
                vm.filtros.Geografia = result;
                mostrarResultados();
            });
        };

        vm.openModalPerfil = function () {
            var nombre = {nombre: vm.nombreProyecto};
            $uibModal.open({
                templateUrl: 'app/components/perfil/perfil.html',
                controller: 'PerfilController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    proyectoSeleccionado: nombre
                }
            }).result.then(function (result) {
                vm.razonSocial = result.razonSocial;
            });
        };

        vm.openModalCadena = function (filtro) {
            var objeto = {filtros: filtro,
                select: vm.filtros.Distribucion,
                proyectoId: "" + vm.proyectoId,
                nombreCanal: "" + vm.canalElegido,
                idIndicador: "" + vm.idIndicadorElegido,
                idUsuario: "" + vm.usuarioId,
                fechaDesde: "null",
                fechaHasta: "null",
                arregloFiltros: vm.arregloFiltros
            };


            /* Parche para ocultar filtro de Tiendas en el login Genomma y DuracellArg*/

			var indiceTienda = filtro.Group.map(function (e) { return e.GroupFilter; }).indexOf('NombreNegocio');
            if (indiceTienda >= 0 && vm.usuarioId == "05" || (vm.usuarioId == "04" && vm.nombreProyecto == "DURACELLARG" ) ) {
                filtro.Group.splice(indiceTienda, 1);
            }

            /* -------------------------------------------------- */

            $uibModal.open({
                templateUrl: 'app/components/cadena/cadena.html',
                controller: 'CadenaController',
                controllerAs: 'vm',
                size: 'md',
                bindings: {
                    eviShown: '=',
                    proyectoId: '=',
                    canalElegido: '=',
                    usuarioId: '='
                },
                resolve: {
                    filterList: objeto
                }
            }).result.then(function (result) {
                var resultado = result;
                angular.forEach(resultado, function (obj) {
                    obj.tipo = filtro.SelectFilter;
                });
                if (vm.filtros.Distribucion === '' || vm.filtros.Distribucion.length === 0) {
                    vm.filtros.Distribucion = [];
                } else {
                    var arregloFix = [];
                    angular.forEach(vm.filtros.Distribucion, function (value) {
                        if (value.tipo !== filtro.SelectFilter) {
                            arregloFix.push(value);
                        }
                    });
                    vm.filtros.Distribucion = arregloFix;
                }
                vm.filtros.Distribucion = vm.filtros.Distribucion.concat(resultado);
                mostrarResultados();
            });

        };

        function openVisionValue(indice) {
            if (vm.mostrarValorVision === indice) {
                vm.mostrarValorVision = '';
            } else {
                vm.mostrarValorVision = indice;
            }
        }

        vm.openModalFecha = function () {
            $uibModal.open({
                templateUrl: 'app/components/fecha/fecha.html',
                controller: 'FechaController',
                controllerAs: 'vm',
                size: 'md'
            }).result.then(function (result) {
                vm.fechas.FechaInicio = result.fechaIni;
                vm.fechas.FechaFin = result.fechaFin;
                mostrarResultados();
            });
        };

        function openModalSecundarias() {
            var objeto = {filtros: vm.medidasSecundarias, select: vm.medidaSecundariaSeleccionada};
            $uibModal.open({
                templateUrl: 'app/components/medidaSecundaria/medidaSecundaria.html',
                controller: 'MedidaSecundariaController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    filterList: objeto
                }
            }).result.then(function (result) {
                vm.medidaSecundariaSeleccionada = result;
                cargarMedidasSecundarias();
            });
        }

        /**
         * Carga los indicadores de un kpi y modifica el estado del menu *sidebar
         * @param {*} opcion
         * @param {*} onlyLoadKpis para poder cargar los kpi sin afectar el menu
         */
        function cambioCanal(opcion, onlyLoadKpis) {
            if(!onlyLoadKpis){
                vm.canalElegido = opcion.Nombre;
                vm.idIndicadorElegido = null;
                vm.indicadorElegido = null;
                vm.idMenuPadreIndicador = null;
                vm.haveMenu = false;

                vm.showmainevidencias = false;
                vm.mostrarCanales = !vm.mostrarCanales;
                vm.mostrarIndicadores = !vm.mostrarIndicadores;
                vm.mostrarMenu = !vm.mostrarMenu;
                vm.mostrarSubMenu = false;
                vm.menuPadreArr = [];
            }

            angular.forEach(vm.canales, function (canal){
                if(canal.Nombre === vm.canalElegido){
                    vm.indicadores = canal.Indicadores;
                    // checkIndicadorMenu(canal);
                }
            });

        }


        function checkIndicadorMenu(canal) {
            angular.forEach(vm.indicadores, function (indicador){
                if(canal.IdIndicador === indicador.IdIndicador){
                    cambioIndicador(indicador);
                }
            })
        }


        //CARGA DE MENU
        //busco canales e indicadores al inicio, luego de la selección del proyecto
        var watch = $rootScope.$watch('proyectId', function (proyectId) {
            //proyect change!
            //notify => the menu is loading
            $rootScope.isMenuLoaded = false;
            LoadMenu(proyectId);
        }, true);

        function LoadMenu(proyectId){
            console.log('load menu... [proyectId] =>', proyectId);//sidebarModel.projectId);

            //si proyectId es null, puede significar que el proyecto ya fue seleccionado y se refresco el browser
            //por lo que le asingo el valor vm.proyectoId que es el que deberia tener el proyecto guardado en session.storage
            if(!proyectId) {
                console.log('proyecto seleccionado anteriormente [proyectId] =>', vm.proyectoId);
                proyectId = vm.proyectoId;
            }

            if(proyectId){
                console.log('busco canales proyectId:', proyectId);
                $http.get('api/canales/' + proyectId + '/with-kpis').then(function (response) {
                        vm.canales = response.data;
                        console.log('canales with kpis', vm.canales);
                        //notify => the menu is loaded!
                        $rootScope.isMenuLoaded = true;
                });
            }
        }

        function buscarFiltrosVisiones() {
            if(vm.idIndicadorElegido !== '') {
                vm.cargando = true;
                $http.get('api/filtros-visiones/' + vm.proyectoId + '/' + vm.idIndicadorElegido + '/' + vm.canalElegido).then(function (response) {
                    vm.filtrosvisiones = null;
                    vm.filtrosvisiones = response.data;

                    /* Parche para ocultar visión de Tiendas en el login Genomma */

                    if (vm.usuarioId == "05") {
                        vm.filtrosvisiones.m_Item2[2].VisionValues.splice(2, 1);
                    }

                    /* -------------------------------------------------- */

                    vm.validarFiltros();
                    abrirSegmentacion();
                });
            }
        }

        function limpiarFiltroVision() {
            vm.visionSeleccionada = [];
            vm.filtros = {Geografia: [], Distribucion: [], Adicionales: []};
            vm.fechas = {FechaInicio: "null", FechaFin: "null"};
        }

        function cambioIndicador(opcion) {
            vm.limpiarFiltroVision();
            vm.cargando = true;
            vm.indicadorElegido = opcion.NombreIndicador;
            vm.idIndicadorElegido = opcion.IdIndicador;
            vm.indicadorPerfectStore = opcion.IdPerfectStore;
            vm.idMenuPadreIndicador = opcion.IdMenuPadre;
            vm.menuPadreIndicador = opcion.MenuPadre;
            vm.mostrarIndicadores = false;
            vm.mostrarMenu = false;
            vm.mostrarSubMenu = false;
            vm.disabledButtonSegmentacion = false;
            vm.showmainevidencias = false;
            vm.buscarFiltrosVisiones();
        }

        function elegirMenu(menu){
            var cierreMenu = false;
            //console.log(vm.menuEntrante[vm.canalElegido]);
            angular.forEach(vm.menuElegido, function(m, index){
                if (m === menu.IdMenu){
                    vm.menuElegido.splice(index);
                    vm.menuPadreArr.splice(index);
                    cierreMenu = true;
                }
            });
            if (menu.IdMenuPadre === null){
                vm.menuElegido = [];
                vm.menuPadreArr = [];
            }
            if (menu.IdIndicador !== null && !cierreMenu){
                vm.cargando = true;
                vm.indicadorElegido = menu.NombreMenu;
                if (menu.NombreMenu === 'Perfect Store'){
                    vm.indicadorPerfectStore = menu.IdPerfectStore;
                }
                else{
                    vm.idIndicadorElegido = menu.IdIndicador;
                }
                vm.idMenuPadreIndicador = menu.IdMenuPadre;
                vm.menuPadreIndicador = vm.menuPadreArr;
                vm.mostrarIndicadores = false;
                vm.mostrarMenu = false;
                vm.mostrarSubMenu = false;
                vm.disabledButtonSegmentacion = false;
                vm.showmainevidencias = false;
                vm.buscarFiltrosVisiones();
            }
            if (menu.IdIndicador === null && !cierreMenu){
                if (menu.IdMenu !== vm.menuElegido[vm.menuElegido.length - 1]){
                    vm.menuPadreArr.push(menu.NombreMenu);
                    vm.menuElegido.push(menu.IdMenu);
                }
            }
            //console.log(vm.menuElegido)
        }

        function buscarMedidasSecundarias(id) {
            $http.get('api/medidas/secundarias/'+ id).then(function(response) {
                vm.medidaSecundariaSeleccionada = response.data;
                mostrarResultados();
            });
        }

        function validarFiltros() {
            if(vm.filtros.Adicionales !== "" || vm.filtros.Distribucion !== "" || vm.filtros.Geografia !== ""){
                var encontrado = false;
                if(vm.filtros.Adicionales !== "")
                {
                    var adicionalesEncontrados = [];
                    angular.forEach(vm.filtros.Adicionales, function (seleccionado) {
                        encontrado = false;
                        angular.forEach(vm.filtrosvisiones.m_Item1, function (filtros) {
                            if (!encontrado) {
                                filtros.Group.find(function (value) {
                                    if (value.GroupFilter === seleccionado.columna) {
                                        value.ValueFilter.find(function (value2) {
                                            if (value2.OptionText === seleccionado.valor) {
                                                adicionalesEncontrados.push(seleccionado);
                                                encontrado = true;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    });
                    if(adicionalesEncontrados.length === 0){
                        vm.filtros.Adicionales = "";
                    }
                    else {
                        vm.filtros.Adicionales = adicionalesEncontrados;
                    }
                }
                if(vm.filtros.Geografia !== "") {
                    var geografiaEncontrados = [];
                    angular.forEach(vm.filtros.Geografia, function (seleccionado) {
                        encontrado = false;
                        angular.forEach(vm.filtrosvisiones.m_Item1, function (filtros) {
                            if (!encontrado) {
                                filtros.Group.find(function (value) {
                                    if (value.GroupFilter === seleccionado.columna) {
                                        value.ValueFilter.find(function (value2) {
                                            if (value2.OptionText === seleccionado.valor) {
                                                geografiaEncontrados.push(seleccionado);
                                                encontrado = true;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    });
                    if(geografiaEncontrados.length === 0){
                        vm.filtros.Geografia = "";
                    }
                    else{
                        vm.filtros.Geografia = geografiaEncontrados;
                    }
                }
                if(vm.filtros.Distribucion !== "") {
                    var distribucionEncontrados = [];
                    angular.forEach(vm.filtros.Distribucion, function (seleccionado) {
                        encontrado = false;
                        angular.forEach(vm.filtrosvisiones.m_Item1, function (filtros) {
                            if (!encontrado) {
                                filtros.Group.find(function (value) {
                                    if (value.GroupFilter === seleccionado.columna) {
                                        value.ValueFilter.find(function (value2) {
                                            if (value2.OptionText === seleccionado.valor) {
                                                distribucionEncontrados.push(seleccionado);
                                                encontrado = true;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    });
                    if(distribucionEncontrados.length === 0){
                        vm.filtros.Distribucion = "";
                    }
                    else{
                        vm.filtros.Distribucion = distribucionEncontrados;
                    }
                }
            }
            buscarMedidasSecundarias(vm.idIndicadorElegido);
        }

        function abrirNegocio(){
            vm.showmainevidencias = false;
            vm.mostrarNegocio = !vm.mostrarNegocio;
            vm.mostrarSegmentacion = false;
            vm.mostrarMainEvidencias = false;
        }

        function abrirSegmentacion(){
            vm.showmainevidencias = false;
            vm.mostrarSegmentacion = !vm.mostrarSegmentacion;
            vm.mostrarNegocio = false;
            vm.mostrarMainEvidencias = false;
        }

        function abrirMainEvidencias(){
            vm.mostrarSegmentacion = false;
            vm.mostrarNegocio = false;
            vm.mostrarCanales = false;
            vm.mostrarIndicadores = false;
            vm.mostrarMenu = false;
            vm.mostrarSubMenu = false;
            vm.indicadorElegido = null;
            vm.idMenuPadreIndicador = null;
            vm.showdashboard = false;
            vm.showmainevidencias = !vm.showmainevidencias;
            vm.mostrarMainEvidencias = !vm.mostrarMainEvidencias;
            vm.menuPadreArr = [];
            if(vm.mostrarMainEvidencias){
                vm.closeSidebar = true;
                vm.mostrarSidebar = false;
            }
        }

        function abrirCanales(){
            vm.mostrarCanales = !vm.mostrarCanales;
            vm.mostrarIndicadores = !vm.mostrarIndicadores;
            vm.mostrarMenu = !vm.mostrarMenu;
            vm.mostrarFiltros = false;
            vm.mostrarIndicadores = false;
            vm.mostrarMenu = false;
            vm.mostrarSubMenu = false;
            vm.menuPadreArr = [];
        }

        function abrirIndicadores(){
            vm.mostrarIndicadores = !vm.mostrarIndicadores;
            vm.mostrarCanales = false;
            vm.mostrarFiltros = false;
        }

        function abrirMenu(){
            vm.menuElegido = [];
            vm.mostrarMenu = !vm.mostrarMenu;
            vm.mostrarCanales = false;
            vm.mostrarFiltros = false;
            vm.menuPadreArr = [];
        }

        function abrirSubMenu(){
            vm.mostrarSubMenu = !vm.mostrarSubMenu;
            vm.mostrarCanales = false;
            vm.mostrarFiltros = false;
        }

        function abrirFiltros(){
            vm.mostrarFiltros = !vm.mostrarFiltros;
            vm.mostrarVision = false;
        }

        function abrirVision(){
            vm.mostrarVision = !vm.mostrarVision;
            vm.mostrarFiltros = false;
        }

        function cargarMedidasSecundarias() {
            if(vm.medidaSecundariaSeleccionada.length > 0){
                var visiones = visionesSeleccionadas(vm.visionSeleccionada);

                vm.arregloFiltros = [];
                cargarFiltros();
                vm.resultadoSecundario = [];

                angular.forEach(vm.resultado,function (resultado){
                    resultado.medidasSecundarias = [];
                });

                angular.forEach(vm.medidaSecundariaSeleccionada, function (medidaSecundaria) {
                    const dataSecundaria = {
                        "medidaSecundariaId": medidaSecundaria.IdMedidaSecundaria,
                        "idProyecto": "" + vm.proyectoId,
                        "idUsuario": "" + vm.usuarioId,
                        "nombrecanal": "" + vm.canalElegido,
                        "idIndicador": "" + vm.idIndicadorElegido,
                        "idMenuPadre": "" + vm.idMenuPadreIndicador,
                        "fil": vm.arregloFiltros,
                        "fechadesde": "" + vm.fechas.FechaInicio,
                        "fechahasta": "" + vm.fechas.FechaFin,
                        "vision": visiones
                    };
                    $http.post('api/resultado/secundario', dataSecundaria).then(function (response) {
                        const cantRes = vm.resultado.length,
                            resSecundarios = response.data;
                        var contIgualdad = 0,
                            y = 0,
                            z = 0;

                        if(cantRes === 1) {
                            angular.forEach(resSecundarios, function (obj) {
                                obj.Medida = angular.copy(medidaSecundaria);
                                vm.resultado[0].medidasSecundarias.push(obj);
                            });
                        }
                        else {
                            angular.forEach(resSecundarios, function (obj, index) {
                                obj.Medida = angular.copy(medidaSecundaria);
                                for (y = 0; y < cantRes; y++){
                                    contIgualdad = 0;
                                    for (z = 0; z < resSecundarios[index].nombresFiltros.length; z++){
                                        if (vm.resultado[y].nombresColumnasGenerales[z] === resSecundarios[index].nombresFiltros[z]){
                                            contIgualdad++;
                                        }
                                        else {
                                            break;
                                        }
                                    }
                                    if (contIgualdad === resSecundarios[index].nombresFiltros.length) {
                                        vm.resultado[y].medidasSecundarias.push(obj);
                                        break;
                                    }
                                }
                            });
                        }
                    });
                });
            }
        }

        function regex(datos) {
            var myRe = new RegExp('##[0-9]*##','g');
            return datos.replace(myRe,'');
        }
        function regexProd(datos) {
            var fields = datos.split('##');
            return fields[0] + ' ID:' + fields[1];
        }

        function cargarMedidasRendimiento(visiones) {
            var fechaHasta = new Date(),
                fechaDesde = new Date();

            fechaDesde.setDate(fechaHasta.getDate() - 90);
            fechaHasta = fechaHasta.getFullYear() + '-' + (1 + fechaHasta.getMonth()) + '-' + fechaHasta.getDate();
            fechaDesde = fechaDesde.getFullYear() + '-' + (1 + fechaDesde.getMonth()) + '-' + fechaDesde.getDate();

            const dataRendimiento = {
                "idProyecto": "" + vm.proyectoId,
                "idUsuario": "" + vm.usuarioId,
                "nombrecanal": "" + vm.canalElegido,
                "idIndicador": "" + vm.idIndicadorElegido,
                "idMenuPadre": "" + vm.idMenuPadreIndicador,
                "fil": vm.arregloFiltros,
                "fechadesde": "" + fechaDesde,
                "fechahasta": "" + fechaHasta,
                "vision": visiones,
                "top1000" : 1
            };
            $http.post('api/resultado/indicadores', dataRendimiento).then(function (response) {
                const resRendimiento = response.data;
                var contIgualdad = 0,
                    y = 0,
                    z = 0;

                if(vm.resultado.length === 1) {
                    angular.forEach(resRendimiento, function (obj) {
                        vm.resultado[0].medidaRendimiento = obj.resultado;
                    });
                }
                else {
                    angular.forEach(resRendimiento, function (obj, index) {
                        for (y = 0; y < vm.resultado.length; y++){
                            contIgualdad = 0;
                            for (z = 0; z < resRendimiento[index].nombresFiltros.length; z++){
                                if (vm.resultado[y].nombresColumnasGenerales[z] === resRendimiento[index].nombresFiltros[z]){
                                    contIgualdad++;
                                }
                                else {
                                    break;
                                }
                            }
                            if (contIgualdad === resRendimiento[index].nombresFiltros.length) {
                                vm.resultado[y].medidaRendimiento = obj.resultado;
                                break;
                            }
                        }
                    });
                }
            });
        }

        function cargarMedidasRendimientoPerfectStore(visiones) {
            var fechaHasta = new Date();
            var fechaDesde = new Date();
            fechaDesde.setDate(fechaHasta.getDate() - 90);
            fechaHasta = fechaHasta.getFullYear() + '-' + (1 + fechaHasta.getMonth()) + '-' + fechaHasta.getDate();
            fechaDesde = fechaDesde.getFullYear() + '-' + (1 + fechaDesde.getMonth()) + '-' + fechaDesde.getDate();

            const dataRendimientoPS = {
                "idProyecto": "" + vm.proyectoId,
                "idUsuario": "" + vm.usuarioId,
                "nombrecanal": "" + vm.canalElegido,
                "idIndicador": "" + vm.indicadorPerfectStore,
                "idMenuPadre": "" + vm.idMenuPadreIndicador,
                "fil": vm.arregloFiltros,
                "fechadesde": "" + fechaDesde,
                "fechahasta": "" + fechaHasta,
                "vision": visiones
            };
            $http.post('api/resultado/perfectStore/multipleKpi', dataRendimientoPS).then(function (response) {
                const resRendimientoPS = response.data;
                var band = true;
                angular.forEach(resRendimientoPS, function (obj) {
                    if (band) {
                        band = false;
                        vm.resultadoTotales.medidaRendimiento = obj.resultado;
                    }
                    else{
                        vm.resultado.find(function (value) {
                            if (obj.nombresFiltros.every(function (value1) {return value.nombresColumnas.includes(value1);})) {
                                value.medidaRendimiento = obj.resultado;
                            }
                        });
                    }
                });
            });
        }

        function asignarVisionPredefinida(vision) {
            var visionPredefinida = {
                OptionText: vision,
                OptionValue: vision
            };
            asignarVision(visionPredefinida);
        }

        function asignarVision(vision) {
            var elimino = false;
            vm.visionSeleccionada.find(function(value, index) {
                if (value.OptionText === vision.OptionText) {
                    vm.visionSeleccionada.splice(index,1);
                    elimino = true;
                }
            });
            if(!elimino){
                vm.visionSeleccionada.push(vision);
                mostrarResultados();
            }
            vm.mostrarValorVision = '';
        }

        function abrirFecha(){
            vm.mostrarFecha = !vm.mostrarFecha;
        }

        vm.executingQueryResultado = false;

        function cargarFiltros() {
            var valueFilterGeo = vm.filtros.Geografia;
            var colFilterGeo = vm.filtros.ColumnaGeografia;
            var valueFilterDis = vm.filtros.Distribucion;
            var colFilterDis = vm.filtros.ColumnaDistribucion;
            var valueAdicional = vm.filtros.Adicionales;

            if (vm.filtros.Geografia === '') {
                valueFilterGeo = "null";
                colFilterGeo = "null";
            }
            if (vm.filtros.Distribucion === '') {
                valueFilterDis = "null";
                colFilterDis = "null";
            }
            if (vm.filtros.Adicionales === '') {
                valueAdicional = "null";
            }

            if (valueFilterGeo !== "null") {
                angular.forEach(valueFilterGeo, function (item) {
                    vm.arregloFiltros.push({
                        valorFiltroGeografia: item.valor.toString(),
                        columnaFiltroGeografia: item.columna.toString(),
                        valorFiltroDistribucion: "null",
                        columnaFiltroDistribucion: "null",
                        table: item.table.toString()
                    })
                });
            }
            if (valueFilterDis !== "null") {
                angular.forEach(valueFilterDis, function (item) {
                    vm.arregloFiltros.push({
                        valorFiltroDistribucion: item.valor.toString(),
                        columnaFiltroDistribucion: item.columna.toString(),
                        valorFiltroGeografia: "null",
                        columnaFiltroGeografia: "null",
                        table: item.table.toString()
                    })
                });
            }
            if (valueAdicional !== "null") {
                angular.forEach(valueAdicional, function (item) {
                    var geografia = false;
                    angular.forEach(vm.filtrosvisiones.m_Item2[0].VisionValues, function (filtro){
                        if(filtro.OptionValue === item.columna){
                            geografia = true;
                        }
                    });
                    if(geografia){
                        vm.arregloFiltros.push({
                            valorFiltroGeografia: item.valor.toString(),
                            columnaFiltroGeografia: item.columna.toString(),
                            valorFiltroDistribucion: "null",
                            columnaFiltroDistribucion: "null",
                            table: item.table.toString()
                        })
                    }
                    else if (item.columna.toString() === "Año" || item.columna.toString() === "Mes"
                        || item.columna.toString() === "Quarter" || item.columna.toString() === "Dia"){
                        vm.arregloFiltros.push({
                            valorFiltroGeografia: "null",
                            columnaFiltroGeografia: "null",
                            valorFiltroDistribucion: item.valor.toString(),
                            columnaFiltroDistribucion: item.columna.toString(),
                            table: "RespuestaEncuesta"
                        })
                    }
                    else{
                        vm.arregloFiltros.push({
                            valorFiltroGeografia: "null",
                            columnaFiltroGeografia: "null",
                            valorFiltroDistribucion: item.valor.toString(),
                            columnaFiltroDistribucion: item.columna.toString(),
                            table: item.table.toString()
                        })
                    }
                });
            }
            return vm.arregloFiltros;
        }

        function sortByMonth(arr) {
            arr.sort(function(a,b){
                    if( a.anio < b.anio ){
                        return -1;
                    }
                    if( a.anio > b.anio){
                        return 1;
                    }
                    if( a.anio === b.anio){
                        if(a.mes < b.mes ) {
                            return -1;
                        }
                        else{
                            return 1;
                        }
                    }
                    return 0;
                }
            );
        }

        function sendEventEvidencias() {
            var msg = "cargaEvidencias";
            showResult.sendMessage(msg);
        }

        function mostrarResultados() {
            if(!(vm.indicadorElegido === '' || vm.canalElegido === '' || vm.executingQueryResultado)) {
                vm.cargando = true;
                vm.showMessageError = false;
                vm.arregloFiltros = [];
                cargarFiltros();
                vm.soloEvidencias = false;

                if (vm.arregloFiltros.length === 0) {
                    vm.arregloFiltros = [{
                        valorFiltroGeografia: "null",
                        columnaFiltroGeografia: "null",
                        valorFiltroDistribucion: "null",
                        columnaFiltroDistribucion: "null"
                    }]
                }

                if(vm.indicadorElegido === 'Evidencias'){
                    vm.cargandoEvidencia = true;
                    vm.showdashboard = true;
                    vm.showmainevidencias = false;
                    vm.soloEvidencias = true;

                    const dataEvidencias = {
                        "page": 1,
                        "idProyecto": "" + vm.proyectoId,
                        "idUsuario": "" + vm.usuarioId,
                        "nombrecanal": "" + vm.canalElegido,
                        "idIndicador": "" + vm.idIndicadorElegido,
                        "idMenuPadre": "" + vm.idMenuPadreIndicador,
                        "fil": vm.arregloFiltros,
                        "fechadesde": "" + vm.fechas.FechaInicio,
                        "fechahasta": "" + vm.fechas.FechaFin,
                        "vision": []
                    };

                    $http.post('api/evidencias', dataEvidencias).then(function (response) {
                        vm.resultado = [];
                        vm.resultadoTotales = null;
                        vm.resultadoTendencias = [];
                        vm.resultadoMapas = [];
                        vm.resultadoEvidencia.Content = [];
                        vm.resultadoEvidencia = response.data;
                        vm.cargandoEvidencia = false;
                        $timeout(function () {
                            vm.mostrarEvidencia = true;
                        }, 100);
                        vm.cargando = false;
                        vm.mostrarMapa = false;
                        vm.mostrarTabla = false;
                        vm.mostrarTendencia = false;
                        vm.mostrar = false;
                        sendEventEvidencias();
                    });
                }
                else if(vm.indicadorPerfectStore){
                    var visiones = visionesSeleccionadas(vm.visionSeleccionada);
                    vm.mostrarMapa = false;
                    vm.mostrarTabla = false;
                    vm.mostrarTendencia = false;
                    vm.mostrarEvidencia = false;

                    const dataPerfectStore = {
                        "idProyecto": "" + vm.proyectoId,
                        "idUsuario": "" + vm.usuarioId,
                        "nombrecanal": "" + vm.canalElegido,
                        "idIndicador": "" + vm.indicadorPerfectStore,
                        "idMenuPadre": "" + vm.idMenuPadreIndicador,
                        "fil": vm.arregloFiltros,
                        "fechadesde": "" + vm.fechas.FechaInicio,
                        "fechahasta": "" + vm.fechas.FechaFin,
                        "vision": visiones
                    };

                    if(visiones.length === 0){
                        //Calculo del PS y los KPIs que lo componen
                        $http.post('api/resultado/perfectStore/multipleKpi', dataPerfectStore).then(function (response) {
                            vm.resultado = [];
                            vm.resultadoTotales = [];
                            vm.resultadoTendencias = [];
                            vm.resultadoMapas = [];
                            vm.resultadoEvidencia.Content = [];
                            const resMultipleKpi = response.data;
                            var nombre = '',
                                nombreGeneral = '',
                                difFechas = null,
                                fecha1 = 0,
                                fecha2 = 0,
                                obj = {};

                            angular.forEach(resMultipleKpi, function (item) {
                                nombre = '';
                                nombreGeneral = '';

                                if (item.nombresFiltros !== null) {
                                    nombre = [];
                                    nombreGeneral = [];
                                    angular.forEach(item.nombresFiltros, function (filtro){
                                        nombre.push(regex(filtro));
                                        nombreGeneral.push(filtro);
                                    });
                                }

                                difFechas = null;
                                if(vm.fechas.FechaFin !== "null" && vm.fechas.FechaInicio !== "null"){
                                    fecha1 = new Date(vm.fechas.FechaFin).getTime();
                                    fecha2 = new Date(vm.fechas.FechaInicio).getTime();
                                    difFechas = (fecha1 - fecha2)/(1000*60*60*24);
                                }

                                obj = {};
                                if(item.resultado < 0){
                                    obj = {
                                        valor: -1,
                                        nombresColumnas: nombre,
                                        nombresColumnasGenerales: nombreGeneral,
                                        vision: angular.copy(vm.visionSeleccionada),
                                        canal: angular.copy(vm.canalElegido),
                                        indicador: angular.copy(vm.indicadorElegido),
                                        filtros: angular.copy(vm.arregloFiltros),
                                        fechas: angular.copy(vm.fechas),
                                        difFechas: angular.copy(difFechas),
                                        multipleKpi: true,
                                        mascara: item.Mascara,
                                        preMascara: item.PreMascara,
                                        mostrarTooltip: false,
                                        medidasSecundarias: [],
                                        medidaRendimiento: 0
                                    };
                                }
                                else{
                                    obj = {
                                        valor: (item.resultado * 100).toFixed(2),
                                        nombresColumnas: nombre,
                                        vision: angular.copy(vm.visionSeleccionada),
                                        canal: angular.copy(vm.canalElegido),
                                        indicador: angular.copy(vm.indicadorElegido),
                                        filtros: angular.copy( vm.arregloFiltros),
                                        fechas: angular.copy(vm.fechas),
                                        difFechas: angular.copy(difFechas),
                                        multipleKpi: true,
                                        mascara: item.Mascara,
                                        preMascara: item.PreMascara,
                                        mostrarTooltip: false,
                                        medidasSecundarias: [],
                                        medidaRendimiento: 0
                                    };
                                }

                                if(vm.resultadoTotales.length === 0){
                                    vm.resultadoTotales = obj;
                                }
                                else{
                                    vm.resultado.push(obj);
                                }
                            });

                            vm.showdashboard = true;
                            vm.showmainevidencias = false;
                            cargarMedidasRendimientoPerfectStore(visiones);

                            $http.post('api/resultado/perfectStore/secundario', dataPerfectStore).then(function (secundariasResponse) {
                                vm.resultadoTotales.medidasSecundarias = secundariasResponse.data;
                            });

                            vm.cargando = false;
                        }, function errorCallback() {
                            vm.resultado = [];
                            vm.resultadoTendencias = [];
                            vm.resultadoEvidencia.Content = [];
                            vm.resultadoMapas = [];
                            vm.showdashboard = true;
                            vm.showmainevidencias = false;
                            vm.progresoDeCarga = false;
                            vm.cargando = false;
                            vm.showMessageError = true;
                        })
                    }
                    else{
                        //Sin vision para el calculo total del PS
                        const dataPerfectStore2 = {
                            "idProyecto": "" + vm.proyectoId,
                            "idUsuario": "" + vm.usuarioId,
                            "nombrecanal": "" + vm.canalElegido,
                            "idIndicador": "" + vm.indicadorPerfectStore,
                            "idMenuPadre": "" + vm.idMenuPadreIndicador,
                            "fil": vm.arregloFiltros,
                            "fechadesde": "" + vm.fechas.FechaInicio,
                            "fechahasta": "" + vm.fechas.FechaFin,
                            "vision": []
                        };
                        $http.post('api/resultado/perfectStore', dataPerfectStore2).then(function (response) {
                            vm.resultadoTendencias = [];
                            vm.resultadoMapas = [];
                            vm.resultadoEvidencia.Content = [];
                            vm.resultadoTotales = null;
                            vm.progresoDeCarga = false;
                            const resPerfectStore2 = response.data;
                            angular.forEach(resPerfectStore2, function (item) {
                                if(item.resultado < 0){
                                    var obj = {
                                        valor: -1
                                    };
                                }
                                else{
                                    var obj = {
                                        valor: (item.resultado * 100).toFixed(2)
                                    };
                                }
                                vm.resultadoTotales = obj;
                            });

                            $http.post('api/resultado/perfectStore/secundario', dataPerfectStore2).then(function (secundariasResponse) {
                                vm.resultadoTotales.medidasSecundarias = secundariasResponse.data;
                            });

                        }, function errorCallback(){
                            vm.cargando = false;
                            vm.executingQueryResultado = false;
                            vm.showMessageError = true;
                        });

                        //Con vision para el calculo del ps por vision
                        $http.post('api/resultado/perfectStore', dataPerfectStore).then(function (response) {
                            vm.resultado = [];
                            var nombre = '',
                                nombreGeneral = '',
                                difFechas = null,
                                obj = {},
                                fecha1 = 0,
                                fecha2 = 0;
                            const resPerfectStore = response.data;
                            angular.forEach(resPerfectStore, function (item) {
                                nombre = '';
                                nombreGeneral = '';
                                difFechas = null;
                                obj = {};

                                if (item.nombresFiltros !== null) {
                                    nombre = [];
                                    nombreGeneral = [];
                                    angular.forEach(item.nombresFiltros, function (filtro){
                                        nombre.push(regex(filtro));
                                        nombreGeneral.push(filtro);
                                    });
                                }

                                if (vm.fechas.FechaFin !== "null" && vm.fechas.FechaInicio !== "null") {
                                    fecha1 = new Date(vm.fechas.FechaFin).getTime();
                                    fecha2 = new Date(vm.fechas.FechaInicio).getTime();
                                    difFechas = (fecha1 - fecha2) / (1000 * 60 * 60 * 24);
                                }

                                if (item.resultado < 0) {
                                    obj = {
                                        valor: -1,
                                        nombresColumnas: nombre,
                                        nombresColumnasGenerales: nombreGeneral,
                                        vision: angular.copy(vm.visionSeleccionada),
                                        canal: angular.copy(vm.canalElegido),
                                        indicador: angular.copy(vm.indicadorElegido),
                                        filtros: angular.copy( vm.arregloFiltros),
                                        fechas: angular.copy(vm.fechas),
                                        difFechas: angular.copy(difFechas),
                                        multipleKpi: true,
                                        mascara: item.Mascara,
                                        preMascara: item.PreMascara,
                                        medidasSecundarias: [],
                                        medidaRendimiento: 0
                                    };
                                }
                                else {
                                    obj = {
                                        valor: (item.resultado * 100).toFixed(2),
                                        nombresColumnas: nombre,
                                        nombresColumnasGenerales: nombreGeneral,
                                        vision: angular.copy(vm.visionSeleccionada),
                                        canal: angular.copy(vm.canalElegido),
                                        indicador: angular.copy(vm.indicadorElegido),
                                        filtros: angular.copy( vm.arregloFiltros),
                                        fechas: angular.copy(vm.fechas),
                                        difFechas: angular.copy(difFechas),
                                        multipleKpi: true,
                                        mascara: item.Mascara,
                                        preMascara: item.PreMascara,
                                        medidasSecundarias: [],
                                        medidaRendimiento: 0
                                    };
                                }

                                vm.resultado.push(obj);
                            });

                            $http.post('api/resultado/perfectStore/secundario', dataPerfectStore).then(function (secundariasResponse) {
                                for (var i = 0; i < secundariasResponse.data.length; i=i+2 ) {
                                    var secundarias = [secundariasResponse.data[i], secundariasResponse.data[i+1]];
                                    for (var y=0; y < vm.resultado.length; y++){
                                        var contIgualdad = 0;
                                        for (var z=0; z < secundariasResponse.data[i].nombresFiltros.length; z++){
                                            if (vm.resultado[y].nombresColumnasGenerales[z] === secundariasResponse.data[i].nombresFiltros[z]){
                                                contIgualdad++;
                                            }
                                            else {
                                                break;
                                            }
                                        }
                                        if (contIgualdad === secundariasResponse.data[i].nombresFiltros.length) {
                                            vm.resultado[y].medidasSecundarias = secundarias;
                                            break;
                                        }
                                    }
                                }
                            });

                            vm.showdashboard = true;
                            vm.showmainevidencias = false;
                            vm.executingQueryResultado = false;
                            vm.cargando = false;

                        }, function errorCallback() {
                            vm.resultado = [];
                            vm.resultadoTendencias = [];
                            vm.resultadoEvidencia.Content = [];
                            vm.resultadoMapas = [];
                            vm.showdashboard = true;
                            vm.showmainevidencias = false;
                            vm.executingQueryResultado = false;
                            vm.progresoDeCarga = false;
                            vm.cargando = false;
                            vm.showMessageError = true;
                        });
                    }
                }
                else{
                    vm.progresoDeCarga = false;
                    vm.executingQueryResultado = true;
                    vm.resultadoTendencias = [];
                    vm.resultadoMapas = [];
                    vm.resultadoEvidencia.Content = [];
                    vm.mostrarMapa = false;
                    vm.mostrarTabla = false;
                    vm.mostrarTendencia = false;
                    vm.mostrarEvidencia = false;
                    var visiones = visionesSeleccionadas(vm.visionSeleccionada);

                    const dataCantKPI = {
                        "idProyecto": "" + vm.proyectoId,
                        "idUsuario": "" + vm.usuarioId,
                        "nombrecanal": "" + vm.canalElegido,
                        "idIndicador": "" + vm.idIndicadorElegido,
                        "idMenuPadre": "" + vm.idMenuPadreIndicador,
                        "fil": vm.arregloFiltros,
                        "fechadesde": "" + vm.fechas.FechaInicio,
                        "fechahasta": "" + vm.fechas.FechaFin,
                        "vision": visiones,
                        "cantTotal" : 1
                    };
                    //Se utiliza para calcular la cantidad total de tarjetas
                    $http.post('api/resultado/indicadores', dataCantKPI).then(function (response) {
                        const res = response.data;
                        const cantidadTotal = res[0].resultado;

                        const dataTop1000 = {
                            "idProyecto": "" + vm.proyectoId,
                            "idUsuario": "" + vm.usuarioId,
                            "nombrecanal": "" + vm.canalElegido,
                            "idIndicador": "" + vm.idIndicadorElegido,
                            "idMenuPadre": "" + vm.idMenuPadreIndicador,
                            "fil": vm.arregloFiltros,
                            "fechadesde": "" + vm.fechas.FechaInicio,
                            "fechahasta": "" + vm.fechas.FechaFin,
                            "vision": visiones,
                            "top1000" : 1
                        };
                        //Se utiliza para calcular los valores de las visiones del kpi
                        $http.post('api/resultado/indicadores', dataTop1000).then(function (response) {
                            const resTop1000 = response.data;
                            vm.resultado = [];
                            var difFechas = null,
                                fecha1 = 0,
                                fecha2 = 0,
                                nombre = '',
                                nombreGeneral = '',
                                obj = {},
                                val = -1;

                            if(vm.fechas.FechaFin !== "null" && vm.fechas.FechaInicio !== "null"){
                                fecha1 = new Date(vm.fechas.FechaFin).getTime();
                                fecha2 = new Date(vm.fechas.FechaInicio).getTime();
                                difFechas = (fecha1 - fecha2)/(1000*60*60*24);
                            }

                            angular.forEach(resTop1000, function (item) {
                                nombre = '';
                                nombreGeneral = '';
                                obj = {};
                                val = -1;

                                if (item.nombresFiltros !== null) {
                                    nombre = [];
                                    nombreGeneral = [];
                                    angular.forEach(item.nombresFiltros, function (filtro){
                                        if (filtro.includes("ProdFilter")){
                                            nombre.push(regexProd(filtro));
                                            nombreGeneral.push(filtro.replace(/ProdFilter/g, '').trim());
                                        }
                                        else{
                                            nombre.push(regex(filtro));
                                            nombreGeneral.push(filtro);
                                        }
                                    });
                                }

                                if (item.resultado >= 0) {
                                    val = (item.resultado * 100).toFixed(2);
                                }

                                obj = {
                                    cantidadTotal: cantidadTotal,
                                    valor: val,
                                    nombresColumnas: nombre,
                                    nombresColumnasGenerales: nombreGeneral,
                                    vision: angular.copy(vm.visionSeleccionada),
                                    canal: angular.copy(vm.canalElegido),
                                    indicador: angular.copy(vm.indicadorElegido),
                                    filtros: angular.copy( vm.arregloFiltros),
                                    fechas: angular.copy(vm.fechas),
                                    difFechas: angular.copy(difFechas),
                                    mascara: item.Mascara,
                                    preMascara: item.PreMascara,
                                    medidasSecundarias: [],
                                    medidaRendimiento: 0
                                };

                                vm.resultado.push(obj);
                            });
                            vm.resultadoTotales = null;
                            if (vm.visionSeleccionada.length === 0) {
                                vm.cargando = false;
                                vm.showdashboard = true;
                                vm.showmainevidencias = false;
                            }
                            else{
                                const dataTotalKpi = {
                                    "idProyecto": "" + vm.proyectoId,
                                    "idUsuario": "" + vm.usuarioId,
                                    "nombrecanal": "" + vm.canalElegido,
                                    "idIndicador": "" + vm.idIndicadorElegido,
                                    "idMenuPadre": "" + vm.idMenuPadreIndicador,
                                    "fil": vm.arregloFiltros,
                                    "fechadesde": "" + vm.fechas.FechaInicio,
                                    "fechahasta": "" + vm.fechas.FechaFin,
                                    "vision": []
                                };
                                //Se utiliza para calcular el total del kpi cuando se aplican visiones
                                $http.post('api/resultado/indicadores', dataTotalKpi).then(function (response) {
                                    vm.progresoDeCarga = false;
                                    vm.resultadoTotales = null;
                                    const resTotalKpi = response.data;
                                    angular.forEach(resTotalKpi, function (item) {
                                        if(item.resultado < 0){
                                            var obj = {
                                                preMascara: item.PreMascara,
                                                mascara: item.Mascara,
                                                valor: -1
                                            };
                                        }
                                        else{
                                            var obj = {
                                                preMascara: item.PreMascara,
                                                mascara: item.Mascara,
                                                valor: (item.resultado * 100).toFixed(2)
                                            };
                                        }
                                        vm.resultadoTotales = obj;
                                    });
                                    vm.cargando = false;
                                    vm.showdashboard = true;
                                    vm.showmainevidencias = false;
                                }, function errorCallback(){
                                    vm.cargando = false;
                                    vm.showdashboard = true;
                                    vm.showmainevidencias = false;
                                    vm.executingQueryResultado = false;
                                    vm.showMessageError = true;
                                });
                            }
                            cargarMedidasSecundarias();
                            cargarMedidasRendimiento(visiones);
                            vm.executingQueryResultado = false;
                            vm.progresoDeCarga = true;
                        });
                    }, function errorCallback(){
                        vm.showdashboard = true;
                        vm.showmainevidencias = false;
                        vm.cargando = false;
                        vm.cargandoTendencia = false;
                        vm.cargandoMapa = false;
                        vm.cargandoEvidencia = false;
                        vm.executingQueryResultado = false;
                        vm.showMessageError = true;
                    });
                }
            }
            else {
                vm.cargando = false;
            }
        }

        function visionesSeleccionadas(visiones){
            var arr = [];
            angular.forEach(visiones, function (vision) {
                if(vision.Table == null){
                    arr.push(vision.OptionText);
                }
                else if(vision.Table === "Negocio"){
                    arr.push("Ne." + vision.OptionText);
                }
                else if(vision.Table === "RespuestaEncuesta"){
                    arr.push("Re." + vision.OptionText);
                }
                else if(vision.Table === "PreguntasEncuesta"){
                    arr.push("Pe." + vision.OptionText);
                }
                else if(vision.Table === "Producto"){
                    arr.push("P." + vision.OptionText);
                }
                else if(vision.Table === "RutaOperativa"){
                    arr.push("Ro." + vision.OptionText);
                }
                else if(vision.Table === "OpcionesSeleccionMultiple"){
                    arr.push("Op." + vision.OptionText);
                }
                else if(vision.Table === "ItemPromocional"){
                    arr.push("Ip." + vision.OptionText);
                }
                else if(vision.Table === "Marcas"){
                    arr.push("M." + vision.OptionText);
                }
                else if(vision.Table === "Empresas"){
                    arr.push("E." + vision.OptionText);
                }
                else if(vision.Table === "CategoriasProducto"){
                    arr.push("Cp." + vision.OptionText);
                }
                else if(vision.Table === "UbicacionesEnTienda"){
                    arr.push("Ut." + vision.OptionText);
                }
                else if(vision.Table === "TiposDeItemsPromocionales"){
                    arr.push("Tip." + vision.OptionText);
                }
                else if(vision.Table === "TipoTiendaProyecto"){
                    arr.push("Ttp." + vision.OptionText);
                }
                else if(vision.Table === "CapacidadProductos"){
                    arr.push("Re." + vision.OptionText);
                }
            });
            return arr;
        }

        $scope.$watch("vm.indicadorCanalEntrante",function(){
            if(vm.indicadorCanalEntrante != undefined){
                cambioCanal(vm.indicadorCanalEntrante.canal);
                cambioIndicador(vm.indicadorCanalEntrante.indicador);
            }
        },true);
    }
})();
