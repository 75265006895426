(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('MapaModalController', MapaModalController);

    MapaModalController.$inject = ['$http', '$uibModalInstance','$state', 'datosTienda'];

    function MapaModalController ($http, $uibModalInstance,$state, datosTienda) {
        var vm = this;

        vm.aceptarProyecto = aceptarProyecto;
        vm.volver = volver;
        vm.datosTienda = datosTienda;
        vm.mostrarDatos = true;
        vm.tiempoImagen = '-';
        vm.buscarTienda = buscarTienda;
        vm.ampliarImagen = ampliarImagen;

        function volver() {
            $uibModalInstance.dismiss('cancel');
            $state.go('home');
        }

        function aceptarProyecto() {
            $uibModalInstance.close({ proyecto: vm.ProyectoSeleccionado, cliente: vm.cliente});
        }


        vm.filtrar = function(){
            $uibModalInstance.close({filtro:vm.datosTienda});
        }

        vm.base64Imagen = null;

        buscarTienda();
        function buscarTienda() {
            $http.get('api/mapa/' + vm.datosTienda.idNegocio).then(function(response) {
                vm.TiendaInfoPath = response.data;
                if(vm.TiendaInfoPath.PathEvidencia !== "") {
                    vm.base64Imagen = vm.TiendaInfoPath.PathEvidencia;
                }
                vm.tiempoImagen = vm.TiendaInfoPath.Fecha;
            });
        }

        function ampliarImagen() {
            vm.mostrarDatos = !vm.mostrarDatos;
        }
    }
})();
