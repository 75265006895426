(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .factory('LoginService', LoginService);

    LoginService.$inject = ['$state'];

    function LoginService ($state) {
        var service = {
            open: open
        };

        return service;

        function open () {
            $state.go('home');
        }
    }
})();
