(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('GraficaPrincipalController', GraficaPrincipalController);

    GraficaPrincipalController.$inject = ['$scope'];

    function GraficaPrincipalController($scope) {
        var vm = this;
        var myChart;
        vm.cont = 0;

        var colorBorder = ["#F98866", "#FF420E", "#eb0058", "#9aeb59","#f100c5","#98DBC6","#5BC8AC","#E6D72A"];
        var colorBorderBack = ["#F98866", "#FF420E", "#eb0058", "#9aeb59","#f100c5","#98DBC6","#5BC8AC","#E6D72A"];
        var ctx = document.getElementById('trimestre-info-bar').getContext('2d');
        var whiteGradient = ctx.createLinearGradient(0, 0, 0, 300);
        whiteGradient.addColorStop(0, '#ffffff');
        whiteGradient.addColorStop(1, 'transparent');

        $scope.$watch('vm.ultDatos', function (newVal) {
            if(vm.cont >= 1){
                if(vm.cont === 1){
                    myChart.data.labels = [];
                    myChart.data.labels = vm.labelsDatos;
                    myChart.data.datasets = [];
                    myChart.options.legend.display = true;
                    /*
                    var option = myChart.options.legend.labels;
                    option.fontColor = 'FFF';
                    myChart.options.legend.labels = option;*/
                }
                var data = {
                    label: vm.labels[vm.cont - 1],
                    data: vm.ultDatos[vm.cont - 1],
                    backgroundColor: whiteGradient,
                    hoverBackgroundColor: whiteGradient,
                    borderColor: colorBorder[vm.cont - 1],
                    fill: false,
                    pointBackgroundColor: colorBorderBack[vm.cont - 1],
                    pointBorderWidth: 2,
                    pointRadius: 4,
                    pointHoverRadius: 4
                };
                myChart.data.datasets.push(data);
                myChart.update();
                vm.cont++;
            }
            else{
                if(myChart){
                    myChart.destroy();
                }
                cargaGrafico();
                vm.cont++;
            }
        }, true);

        function cargaGrafico() {
            myChart = new Chart(document.getElementById("trimestre-info-bar"), {
                /*plugins: [{
                    afterDatasetsDraw: function(chart, easing) {
                        // To only draw at the end of animation, check for easing === 1

                        var ctx = chart.ctx;
                        chart.data.datasets.forEach(function (dataset, i) {
                            if(i === 0){
                                var meta = chart.getDatasetMeta(i);
                                if (!meta.hidden) {
                                    meta.data.forEach(function(element, index) {
                                        // Draw the text in black, with the specified font
                                        ctx.fillStyle = 'rgb(255, 255, 255)';
                                        var fontSize = 12;
                                        var fontStyle = 'normal';
                                        var fontFamily = 'Roboto';
                                        ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                                        // Just naively convert to string for now
                                        var dataString = chart.data.labels[index];
                                        // Make sure alignment settings are correct
                                        ctx.textAlign = 'center';
                                        ctx.textBaseline = 'middle';
                                        var padding = 10;
                                        var position = element.tooltipPosition();
                                        ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
                                    });
                                }
                            }
                        });
                    }
                }],*/
                type: 'line',
                data: {
                    labels: vm.labelsDatos,
                    datasets: [{
                        label: '',
                        data: vm.ultDatos[0],
                        backgroundColor: whiteGradient,
                        hoverBackgroundColor: whiteGradient,
                        borderColor: "#FFFFFF",
                        fill: true,
                        pointBackgroundColor: "#0c2646",
                        pointBorderWidth: 3,
                        pointRadius: 5,
                        pointHoverRadius: 6
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: 20,
                            right: 20,
                            top: 80,
                            bottom: 70
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                fontColor: "#FFFFFF",
                                fontFamily: "Arial",
                                fontSize: 12
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                fontColor: "#FFFFFF",
                                fontFamily: "Arial",
                                fontSize: 12,
                                min: 0
                            }
                        }]
                    },
                    title: {
                        display: false,
                        text: 'Información general de encuestas contestadas en los últimos 3 meses'
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                                if (label) {
                                    label += ': ';
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;
                                if(vm.listaCantidad && vm.listaCantidad[tooltipItem.datasetIndex] && vm.listaCantidad[tooltipItem.datasetIndex][tooltipItem.index]){
                                    label += " | Encuestas: " + vm.listaCantidad[tooltipItem.datasetIndex][tooltipItem.index];
                                }
                                return label;
                            }
                        },
                        backgroundColor: '#FFF',
                        titleFontSize: 16,
                        titleFontColor: '#000',
                        bodyFontColor: '#000',
                        bodyFontSize: 14,
                        displayColors: false,
                        caretSize: 5,
                        fontFamily: "Arial",
                        xPadding: 20,
                        yPadding: 10
                    }
                }
            });
        }
    }
})();
