(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('TarjetasController', TarjetasController);

    TarjetasController.$inject = ['showResult', '$uibModal','$sessionStorage'];

    function TarjetasController(showResult,$uibModal,$sessionStorage) {
        var vm = this;
        var ctx = document.getElementById('chart-pos-1').getContext('2d');

        var orangeGradient = ctx.createLinearGradient(0, 0, 0, 270);
        orangeGradient.addColorStop(0, '#fdbba9');
        orangeGradient.addColorStop(1, 'white');

        var greenGradient = ctx.createLinearGradient(0, 0, 0, 270);
        greenGradient.addColorStop(0, '#bfefc4');
        greenGradient.addColorStop(1, 'white');

        var lightblueGradient = ctx.createLinearGradient(0, 0, 0, 270);
        lightblueGradient.addColorStop(0, '#80cbff');
        lightblueGradient.addColorStop(1, 'white');

        vm.titulo = ["-","-","-"];

        vm.subTitulo = ["-","-","-"];

        vm.tipo = [null,null,null];

        
        if($sessionStorage.nombreProyecto == "DURACELLARG"){
            vm.mostrarlabel = [true, true, true];
        }
        else{
            vm.mostrarlabel = [false, false, false];
        }

        var colors = ["#f96332", "#18ce0f", "#44b2ff"];

        var Chart1 = [null,null,null];

        vm.cargarGrafico = cargarGrafico;
        vm.configurarGraficos = configurarGraficos;
        vm.cambiarTipo = cambiarTipo;
        vm.cambiarLabel = cambiarLabel;
        vm.sendEventGraficaSecundariaCarga = sendEventGraficaSecundariaCarga;

        showResult.subscribe(function(x) {
            if (x === "cargaGrafico1") {
                mostrarLabelsSobreGraficos();
                cargarGrafico(1);
            }
            if (x === "cargaGrafico2") {
                mostrarLabelsSobreGraficos();
                cargarGrafico(2);
            }
            if (x === "cargaGrafico3") {
                mostrarLabelsSobreGraficos();
                cargarGrafico(3);
            }
            if (x === "limpiar"){
                limpiarGraficos();
            }
        });


        function mostrarLabelsSobreGraficos(){
            if($sessionStorage.nombreProyecto == "DURACELLARG"){
                vm.mostrarlabel = [true, true, true];
            }
            else{
                vm.mostrarlabel = [false, false, false];
            }
        }

        function limpiarGraficos() {
            if(Chart1[0] != null){
                Chart1[0].destroy();
            }
            if(Chart1[1] != null){
                Chart1[1].destroy();
            }
            if(Chart1[2] != null){
                Chart1[2].destroy();
            }
        }

        function cambiarTipo(indice) {
            angular.forEach(vm.resultadoGraficaSecundaria, function (obj, index) {
                if(obj.Pos === (indice + 1)){
                    var tipo = obj.tipoGrafica.TiposGrafica;
                    if(tipo === "line"){
                        obj.tipoGrafica.TiposGrafica = 'bar';
                    }
                    else{
                        obj.tipoGrafica.TiposGrafica = 'line';
                    }
                    if(Chart1[indice] != null){
                        Chart1[indice].destroy();
                    }
                    cargarGrafico(index + 1);
                }
            });
        }

        function cambiarLabel(indice) {
            vm.mostrarlabel[indice] = !vm.mostrarlabel[indice];
            angular.forEach(vm.resultadoGraficaSecundaria, function (obj, index) {
                if(obj.Pos === (indice + 1)){
                    Chart1[indice].destroy();
                    cargarGrafico(index + 1);
                }
            });
        }

        function cargarGrafico(i) {
            var labels = [];
            var data = [];

            

            angular.forEach(vm.resultadoGraficaSecundaria[i-1].resultados, function (obj) {
                if(obj.resultado >= 0.01){
                    var labelToPush = "";
                    angular.forEach(obj.nombresFiltros, function (label) {
                        labelToPush = labelToPush + "" + label;
                    });
                    labels.push(labelToPush);
                    data.push(Math.round(obj.resultado * 100));
                }
            });

            var pos = vm.resultadoGraficaSecundaria[i-1].Pos;

            vm.titulo[pos - 1] = vm.resultadoGraficaSecundaria[i-1].grafica.TituloGrafica;
            vm.subTitulo[pos - 1] = vm.resultadoGraficaSecundaria[i-1].grafica.SubTituloGrafica;
            vm.tipo[pos - 1] = vm.resultadoGraficaSecundaria[i-1].tipoGrafica.TiposGrafica;
            var tipo = vm.resultadoGraficaSecundaria[i-1].tipoGrafica.TiposGrafica;

            var gradient;
            if(i === 1){
                gradient = orangeGradient;
            }
            if(i === 2){
                gradient = greenGradient;
            }
            if(i === 3){
                gradient = lightblueGradient;
            }

            var plugins = null;

            if(vm.mostrarlabel[pos-1])
            {
                plugins = [{
                    afterDatasetsDraw: function (chart, easing) {
                        // To only draw at the end of animation, check for easing === 1
                        var ctx = chart.ctx;
                        chart.data.datasets.forEach(function (dataset, i) {
                            var band = false;
                            var meta = chart.getDatasetMeta(i);
                            if (!meta.hidden) {
                                meta.data.forEach(function (element, index) {
                                    // Draw the text in black, with the specified font
                                    ctx.fillStyle = 'rgb(0, 0, 0)';
                                    var fontSize = 8;
                                    var fontStyle = 'normal';
                                    var fontFamily = 'Roboto';
                                    ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
                                    // Just naively convert to string for now
                                    var dataString = chart.data.labels[index];
                                    // Make sure alignment settings are correct
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = 'middle';
                                    //var padding = 10;
                                    var padding = 0;
                                    var position = element.tooltipPosition();
                                    if (band) {
                                        band = false;
                                        ctx.fillText(dataString, position.x, position.y + (fontSize / 2) + padding);
                                    } else {
                                        band = true;
                                        ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
                                    }
                                });
                            }
                        });
                    }
                }];
            }

            Chart1[pos-1] = new Chart(document.getElementById("chart-pos-"+pos), {
                plugins: plugins,
                type: tipo,
                data: {
                    labels: labels,
                    datasets: [{
                        label: vm.resultadoGraficaSecundaria[i-1].grafica.DescripcionResultado,
                        data: data,
                        borderColor: colors[i-1],
                        fill: true,
                        backgroundColor: gradient,
                        hoverBackgroundColor: gradient,
                        hoverBorderWidth: 2,
                        pointBackgroundColor: colors[i-1],
                        pointBorderColor: "#FFFFFF",
                        pointBorderWidth: 2,
                        pointRadius: 4
                    }]
                },
                options: {
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            display: false,
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                fontColor: "#000000",
                                fontFamily: "Arial",
                                fontSize: 8
                            }
                        }],
                        yAxes: [{
                            display: true,
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                fontColor: "#000000",
                                fontFamily: "Arial",
                                fontSize: 12,
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
        }

        function sendEventGraficaSecundariaCarga() {
            var msg = "cargarGraficas";
            showResult.sendMessage(msg);
        }

        function configurarGraficos() {
            var graficosActuales = vm.graficasSecundarias;
            vm.proyectoId = $sessionStorage.proyectoId;
            $uibModal.open({
                templateUrl: 'app/components/tarjetas/tarjetas-modal.html',
                controller: 'TarjetasModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    filterList: function (){
                        return graficosActuales;
                    },
                    entity: ['$http', function($http) {
                        return $http.get('api/grafica/all/' + vm.proyectoId);
                    }]
                }
            }).result.then(function (result) {
                if(Chart1[0] != null){
                    Chart1[0].destroy();
                }
                if(Chart1[1] != null){
                    Chart1[1].destroy();
                }
                if(Chart1[2] != null){
                    Chart1[2].destroy();
                }
                vm.sendEventGraficaSecundariaCarga();
            });
        }
    }
})();
