(function () {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('EviPaginationController', EviPaginationController);

    EviPaginationController.$inject = ['$scope', '$http', '$timeout', 'showResult'];

    function EviPaginationController($scope, $http, $timeout, showResult) {
        var vm = this;
        vm.min = 0;
        vm.max = 4;
        vm.numbers = [0,1,2,3,4];
        function resetPages(){
            if(vm.page > 3){
                vm.min = vm.page-2;
                vm.max = vm.page+2;
            }
            else{
                vm.min = 0;
                vm.max = 4;
            }
            if(vm.max > vm.size-1){
                vm.max = vm.size;
            }
            vm.numbers = [];
            for(var i = vm.min; i<= vm.max;i++){
                vm.numbers.push(i);
            }
        }
        $scope.$watch("vm.page",resetPages,true);
        $scope.$watch("vm.size",resetPages,true);
        vm.setPage = function(number){
            vm.page = number;
        }
        vm.next = function(){
            if(vm.page+1 <= vm.size) vm.page++;
            //console.log(vm.size);
        }
        vm.prev = function(){
            if(vm.page-1 >= 0) vm.page--;
        }
    }
})();
