(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('InfoboxController', InfoboxController);
    InfoboxController.$inject = ['$sessionStorage'];

    function InfoboxController ($sessionStorage) {
        var vm = this;
        if ($sessionStorage.proyectoId == null){
            $sessionStorage.proyectoId = 0;
        }
        if (vm.proyectoId != $sessionStorage.proyectoId){
            vm.proyectoId = angular.copy($sessionStorage.proyectoId);
        }

        vm.rotateCard = function(){
            vm.rotate = !vm.rotate;
        }
        vm.agregarFiltro = function (obj, nombresColumnasGenerales, index){
            vm.filtroSelected = {
                filtro:obj,
                filtroGenerales: nombresColumnasGenerales,
                indice: index
            };
        }
        vm.impreciso = function(){
            if ((vm.objResultado.valor < 0) || 7 >= vm.objResultado.difFechas && vm.objResultado.difFechas){
                return true;
            }
            return false;
        }
        vm.sube = function(){
            if ((vm.objResultado.valor >= 0) && ((vm.objResultado.valor/100).toFixed(3) >= vm.objResultado.medidaRendimiento) && !vm.objResultado.difFechas < 7){
                return true;
            }
            return false;
        }
        vm.baja = function(){
            if ((vm.objResultado.valor >= 0) && (vm.objResultado.medidaRendimiento > (vm.objResultado.valor/100).toFixed(3)) && !vm.objResultado.difFechas < 7){
                return true;
            }
            return false;
        }

        //Formato pesos para Colombia Precios
        vm.proyectColombia = function proyectColombia(indi, secu) {
            if (vm.proyectoId == 25 || vm.proyectoId == 60){
                if (indi.indicador == "Precios" && secu.Medida.IdMedidaSecundaria == 36){
                    vm.valor = parseFloat(secu.resultado).format(2, 3, '.', ',');
                    return true;
                }
            }
            return false;
        }

        Number.prototype.format = function(n, x, s, c) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = this.toFixed(Math.max(0, ~~n));
            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        };
    }
})();
