(function () {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('MapaController', MapaController);

    MapaController.$inject = ['$http','$scope', '$compile', '$uibModal', 'showResult', '$timeout'];

    function MapaController($http,$scope, $compile, $uibModal, showResult, $timeout) {
        var vm = this;
        vm.mostrar = false;
        vm.cargando = false;
        vm.initMap = initMap;
        vm.limpiarMapa = limpiarMapa;
        vm.openModal = openModal;
        vm.openTiendasModal = openTiendasModal;
        vm.cargarMapa = cargarMapa;
        vm.mostrarModal = false;
        vm.mostrarModalTiendas = false;
        vm.rutaOperativa = '';
        vm.resultadoFiltrado = [];
        vm.abrirModalTiendas = abrirModalTiendas;

        var showResultSubscribe = showResult.subscribe(function (x) {
            if (x === "cargaMapaDatos") {
                if (vm.resultadoMapaIndicador.length > 0) {
                    vm.initMap();
                }
                else {
                    $timeout(function () {
                        if (vm.resultadoMapaIndicador.length > 0) {
                            vm.initMap();
                        }
                    }, 2000);
                }
                vm.cargandoMapas = false;
                vm.mostrarMapas = true;
            }
        });

        vm.pos = {
            lat: 23.7248607,
            lng: -103.4066612
        };

        function openModal(objeto) {
            $uibModal.open({
                templateUrl: 'app/components/mapa/mapa.modal.html',
                controller: 'MapaModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    datosTienda: objeto
                }
            }).result.then(function (result) {
                var found = false;
                if(!vm.filtrosResultado.Distribucion){
                    vm.filtrosResultado.Distribucion = [];
                } else{
                    angular.forEach(vm.filtrosResultado.Distribucion,function(filtro){
                        if(filtro.columna == "NombreNegocio" && filtro.valor == result.filtro.nombreCompleto){
                            found = true;
                        }
                    });
                }
                if(!found){
                    vm.filtrosResultado.Distribucion.push({
                        columna: "NombreNegocio",
                        table: "Negocio",
                        tipo: "Cadena",
                        valor: result.filtro.nombreCompleto,
                        valorA: result.filtro.nombreNegocio,
                        valorMostrar: "Tiendas"
                    })
                    showResult.sendMessage(true);
                }
            });
        }

        function openTiendasModal(objeto) {
            var fechaInicio = vm.fechasElegidas.FechaInicio;
            var fechaFin = vm.fechasElegidas.FechaFin;
          
            console.log("Fecha Inicio: " + fechaInicio);
            console.log("Fecha Fin: " + fechaFin);
          
            $uibModal.open({
              templateUrl: 'app/components/mapa/mapa.tiendas.modal.html',
              controller: 'TiendasModalController',
              controllerAs: 'vm',
              size: 'lg',
              resolve: {
                datosTienda: function() {
                  return { 
                    tiendas: objeto,
                    fechasElegidas: vm.fechasElegidas
                 };
                }
              }
            }).result.then(function(result) {
              vm.resultadoFiltrado = [];
            });
          }
          

        function cargarMapa() {
            var msg = "cargarMapa";
            showResult.sendMessage(msg);
        }

        function limpiarMapa() {
            angular.element(document.getElementById("display")).children("div").remove();
        }

        function regex(datos) {
            var myRe = new RegExp('##[0-9]*##', 'g');
            return datos.replace(myRe, '');
        }

        /* function GetIdNegocio(datos){
            var myRe = new RegExp('##[0-9]*##', 'g');
            const regex = /\d+/g;
            return datos.match(regex)[0];//devuelve un solo valor
        } */

        function GetIdNegocio(datos){
            const regex = /##(\d+)##/;
            const match = datos.match(regex);
            
            var result = match ? parseInt(match[1]) : null;
            return result;
        }

        function initMap() {
            vm.map = new GMaps(
                {
                    div: "#map",
                    lat: vm.pos.lat,
                    lng: vm.pos.lng,
                    markerClusterer: function (map) {
                        /* return new MarkerClusterer(map, map.markers, { 
                            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                            minimumClusterSize: 10,
                            
                         }); */
                         var markerClusterer = new MarkerClusterer(map, [], { 
                            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                            minimumClusterSize: 10
                        });
                
                        // Agrega un escucha de eventos que se activará cuando se haga clic en un cluster
                        google.maps.event.addListener(markerClusterer, 'clusterclick', function(cluster) {
                            
                            var markers = cluster.getMarkers();
                            // Obtener el zoom máximo del mapa
                            var maxZoom = map.maxZoom;
                            // Obtener el zoom actual del mapa
                            var currentZoom = map.getZoom();

                            var idNegociosGrupo = markers.map(function(objeto) {
                                return Number(GetIdNegocio(objeto.title)) 
                            });

                            //obtengo los negocios del grupo
                            vm.resultadoFiltrado = vm.resultadoMapaIndicador.filter(function(indicador) {
                                return idNegociosGrupo.some(function(idNegocio) {
                                  return idNegocio === indicador.idNegocio;
                                });
                              }).map(function(indicador) {
                                return {
                                  idNegocio: indicador.idNegocio,
                                  negocio: regex(indicador.nombreNegocio),
                                  latitud: indicador.latitud,
                                  longitud: indicador.longitud,
                                  pathImage: "",
                                  direccionGoogle: ""
                                };
                              });  

                           
                            

                             //ordernar por latitud y longitud
                              vm.resultadoFiltrado.sort(function(a, b) {
                                if (a.latitud < b.latitud) {
                                  return -1;
                                } else if (a.latitud > b.latitud) {
                                  return 1;
                                } else {
                                  if (a.longitud < b.longitud) {
                                    return -1;
                                  } else if (a.longitud > b.longitud) {
                                    return 1;
                                  } else {
                                    return 0;
                                  }
                                }
                              });

                            
                            

                            if(currentZoom >= 20){
                                  abrirModalTiendas();
                            }
                            
                        });

                        // Agrega un escucha de eventos para capturar el clic derecho en un cluster
                        google.maps.event.addListener(markerClusterer, 'rightclick', function (cluster) {
                            console.log('Se hizo clic derecho en un cluster');
                            // Realiza las acciones que deseas realizar al capturar el evento de clic derecho en un cluster
                        });   

                        return markerClusterer;
                    }
                });

            vm.styles = [{
                "featureType": "landscape", "stylers": [

                    { "saturation": -100 }

                    ,

                    { "lightness": 65 }

                    ,

                    { "visibility": "on" }

                ]
            }, {
                "featureType": "poi", "stylers": [

                    { "saturation": -100 }

                    ,

                    { "lightness": 51 }

                    ,

                    { "visibility": "simplified" }

                ]
            }, {
                "featureType": "road.highway", "stylers": [

                    { "saturation": -100 }

                    ,

                    { "visibility": "simplified" }

                ]
            }, {
                "featureType": "road.arterial", "stylers": [

                    { "saturation": -100 }

                    ,

                    { "lightness": 30 }

                    ,

                    { "visibility": "on" }

                ]
            }, {
                "featureType": "road.local", "stylers": [

                    { "saturation": -100 }

                    ,

                    { "lightness": 40 }

                    ,

                    { "visibility": "on" }

                ]
            }, {
                "featureType": "transit", "stylers": [

                    { "saturation": -100 }

                    ,

                    { "visibility": "simplified" }

                ]
            }, {
                "featureType": "administrative.province", "stylers": [

                    { "visibility": "off" }

                ]
            }, {
                "featureType": "water", "elementType": "labels", "stylers": [

                    { "visibility": "on" }

                    ,

                    { "lightness": -25 }

                    ,

                    { "saturation": -100 }

                ]
            }, {
                "featureType": "water", "elementType": "geometry", "stylers": [

                    { "hue": "#ffff00" }

                    ,

                    { "lightness": -25 }

                    ,

                    { "saturation": -97 }

                ]
            }];

            vm.map.addStyle({
                styledMapName: "Styled Map",
                styles: vm.styles,
                mapTypeId: "map_style"
            });

            vm.map.setStyle("map_style");

            var bounds = new google.maps.LatLngBounds();
            angular.forEach(vm.resultadoMapaIndicador, function (marcador) {
                var pt = new google.maps.LatLng(parseFloat(marcador.latitud), parseFloat(marcador.longitud));
                bounds.extend(pt);
                //var nombre = regex(marcador.nombreNegocio);
                var nombre = marcador.nombreNegocio;
                var icon;
                    if (marcador.objetivo === 0) {
                        icon = 'content/images/pinMapaAzul.png'
                    }
                    else if(marcador.resultado >= marcador.objetivo){
                        icon = 'content/images/pinMapaVerde.png'
                    }
                    else if(marcador.resultado < marcador.objetivo){
                        icon = 'content/images/pinMapaNaranja.png'
                    }
                    vm.map.addMarker({
                        lat: parseFloat(marcador.latitud),
                        lng: parseFloat(marcador.longitud),
                        title: nombre,
                        icon: icon,
                        click: function (e) {
                            vm.cargando = true;
                            vm.idNegocio = marcador.idNegocio;
                            vm.nombreNegocio = nombre;
                            vm.nombreCadena = regex(marcador.nombreCadena);
                            vm.formato = regex(marcador.formato);
                            vm.direccion = marcador.direccion;
                            vm.latitud = marcador.latitud;
                            vm.longitud = marcador.longitud;
                            vm.mostrarModal = true;
                            //obtener rutaoperativa 
                            $http.get('api/mapa/rutaoperativa/' + vm.idNegocio).then(function(response) {
                                vm.rutaOperativa = response.data;
                                var objeto = {
                                    nombreCompleto:marcador.nombreNegocio,
                                    idNegocio: vm.idNegocio, nombreNegocio: vm.nombreNegocio, nombreCadena: vm.nombreCadena,
                                    formato: vm.formato, direccion: vm.direccion, latitud: vm.latitud, longitud: vm.longitud,
                                    rutaOperativa: vm.rutaOperativa
                                };
                                vm.cargando = false;
                                vm.openModal(objeto);
                            });
                            
                        }
                    });
            });
            vm.map.fitLatLngBounds(bounds);
            vm.map.fitBounds(bounds);
        }

        function abrirModalTiendas(){
            vm.mostrarModalTiendas = true;
            vm.openTiendasModal(vm.resultadoFiltrado);
        }
    }
})();
