(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('eviCarousel', {
            templateUrl: 'app/components/eviCarousel/eviCarousel.html',
            controller: 'EviCarouselController',
            controllerAs: 'vm',
            bindings: {
                items: '=',
                slide:'=',
                carHidden:'='
            }
        });
})();
