(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('RowController', RowController);
        RowController.$inject = ['$scope'];

    function RowController ($scope) {
        var vm = this;
    }
})();
