(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('ProyectoController', ProyectoController);

    ProyectoController.$inject = ['$rootScope', '$http', '$uibModalInstance', '$state', 'Principal','showResult'];

    function ProyectoController ($rootScope, $http, $uibModalInstance, $state, Principal,showResult) {
        var vm = this;

        vm.proyectoUnico = false;

        vm.aceptarProyecto = aceptarProyecto;
        vm.volver = volver;

        vm.proyectos = [];
        vm.disableProyectos = true;
        vm.usuarioId = Principal.identity().$$state.value.Id;
        vm.cliente = '';
        vm.ProyectoSeleccionado = {IdProyecto: 0, nombre: ""};

        function limpiarGraficasSecundarias() {
            var msg = "limpiar";
            showResult.sendMessage(msg);
        }

        $http.get('api/clientes/user/' + vm.usuarioId).then(function(response) {
            vm.cliente = response.data;
            $http.get('api/usuarioProyecto/' + vm.usuarioId).then(function(response) {
                const usuarioProyectosResponse = response.data;
                if (usuarioProyectosResponse.length > 0){
                    var idProyecto;
                    vm.disableProyectos = false;
                    if(usuarioProyectosResponse.length == 1){
                        idProyecto = usuarioProyectosResponse[0].IdProyecto;
                        $http.get('api/proyectos/get/' + idProyecto).then(function(response) {
                            vm.proyectoUnico = true;
                            vm.ProyectoSeleccionado = response.data;
                            aceptarProyecto();
                        });
                    }
                    else{
                        angular.forEach(usuarioProyectosResponse, function(items) {
                            idProyecto = items.IdProyecto;
                            $http.get('api/proyectos/get/' + idProyecto).then(function(response) {
                                vm.proyectos.push(response.data);
                            });
                        });
                    }
                }
                else{
                    $http.get('api/proyectos/' + vm.cliente.IdCliente).then(function(response) {
                        vm.proyectos = response.data;
                        if(vm.proyectos.length > 0){
                            vm.disableProyectos = false;
                        }
                        if(vm.proyectos.length === 1){
                            vm.proyectoUnico = true;
                            vm.ProyectoSeleccionado = vm.proyectos[0];
                            aceptarProyecto();
                        }
                    });
                }
            });
        });

        function volver() {
            showResult.desubscribe();
            $uibModalInstance.dismiss('cancel');
            $state.go('home');
        }

        function aceptarProyecto() {
            if(vm.ProyectoSeleccionado.IdProyecto){
                limpiarGraficasSecundarias();
                //seteo la variable global
                $rootScope.proyectId = vm.ProyectoSeleccionado.IdProyecto;
                $uibModalInstance.close({proyecto: vm.ProyectoSeleccionado.IdProyecto, cliente: vm.cliente,
                nombreProyecto: vm.ProyectoSeleccionado.NombreProyecto, proyectoUnico: vm.proyectoUnico});
            }
        }
    }
})();
