(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('tabla', {
            templateUrl: 'app/components/tabla/tabla.html',
            controller: 'TablaController',
            controllerAs: 'vm',
            bindings: {
                resultadoTablaIndicador: '=',
                mostrarTablas: '='
            }
        });
})();
