(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('navbar', {
            templateUrl: 'app/components/navbar/navbar.html',
            controller: 'NavbarController',
            controllerAs: 'vm'
        });
})();
