(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .factory('showResult', showResult);

    showResult.$inject = [];

    function showResult () {
        return {
            subject: new Rx.BehaviorSubject(),
            subscribe: function(callback) {
              return this.subject.subscribe(callback);
            },
            sendMessage: function(msg) {
              this.subject.onNext(msg);
            },
            desubscribe: function () {
                this.subject.observers = [];
            }
          };
    }
})();
