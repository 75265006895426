(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .factory('PasswordResetFinish', PasswordResetFinish);

    PasswordResetFinish.$inject = ['$http'];

    function PasswordResetFinish($http) {
        return {
            save : resetPassword
        };

        function resetPassword(data) {
            return $http.post('api/account/reset-password', data);
        }
    }
})();
