(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('TarjetasModalController', TarjetasModalController);

    TarjetasModalController.$inject = ['$uibModalInstance', '$http', 'filterList', '$sessionStorage', 'entity'];

    function TarjetasModalController($uibModalInstance, $http, filterList, $sessionStorage, entity) {
        var vm = this;

        vm.posiciones = [1,2,3];

        vm.graficas = [];

        vm.graficas = entity.data;

        vm.posicionElegida = null;

        vm.graficaElegida = null;

        vm.graficasElegidas = [{Pos: 1}, {Pos: 2}, {Pos: 3}];

        vm.proyectoId = $sessionStorage.proyectoId;

        vm.mostrarGuardar = false;

        angular.forEach(vm.graficasElegidas, function (obj) {
            filterList.find(function(value) {
                if (value.Pos === obj.Pos) {
                    obj.IdGrafica = value.IdGrafica;
                    obj.IdProyecto = value.IdProyecto;
                    vm.graficas.find(function(grafica) {
                        if (grafica.IdGrafica === obj.IdGrafica) {
                            obj.NombreGrafica = grafica.SubTituloGrafica + " - " + grafica.TituloGrafica;
                        }
                    })
                }
            });
        });

        vm.agregarGrafica = agregarGrafica;
        vm.cambioPos = cambioPos;
        vm.save = save;
        vm.deleteGrafica = deleteGrafica;



        function agregarGrafica() {
            angular.forEach(vm.graficasElegidas, function (obj) {
                if(obj.Pos === vm.posicionElegida){
                    obj.IdGrafica = vm.graficaElegida.IdGrafica;
                    obj.NombreGrafica = vm.graficaElegida.SubTituloGrafica + " - " + vm.graficaElegida.TituloGrafica;
                    obj.IdProyecto = vm.proyectoId;
                }
            });
        }

        function cambioPos() {
            vm.mostrarGuardar = false;
            angular.forEach(vm.graficasElegidas, function (obj) {
                if(obj.Pos === vm.posicionElegida){
                    if(obj.IdGrafica){
                        vm.graficas.find(function(value) {
                            if (value.IdGrafica === obj.IdGrafica) {
                                vm.graficaElegida = value;
                                vm.mostrarGuardar = true;
                            }
                        })
                    }
                }
            })
        }

        function save() {
            $http.post('api/grafica/' + vm.proyectoId, vm.graficasElegidas).then(function () {
                $uibModalInstance.close();
            });
        }

        function deleteGrafica(pos){
            vm.graficasElegidas[pos-1] = {Pos: pos};
        }
    }
})();
