(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', '$localStorage', '$sessionStorage', '$q'];

    function AuthServerProvider ($http, $localStorage, $sessionStorage, $q) {
        var service = {
            getToken: getToken,
            login: login,
            loginWithToken: loginWithToken,
            storeAuthenticationToken: storeAuthenticationToken,
            logout: logout
        };

        return service;

        function getToken () {
            return $localStorage.authenticationToken || $sessionStorage.authenticationToken;
        }

        function toParams(obj) {
            var params = [];

            for (var key in obj) {
                params.push(key + '=' + encodeURIComponent(obj[key]));
            }

            return params.join('&');
        }

        function login (credentials) {
            var data = {
                username: credentials.username,
                password: credentials.password,
                grant_type: credentials.grant_type
            };

            return $http.post( 'token', toParams(data)).success(authenticateSuccess);

            function authenticateSuccess (data) {
                if (angular.isDefined(data.access_token) && data.token_type === 'bearer') {
                    service.storeAuthenticationToken(data.access_token, credentials.rememberMe);

                    return data.access_token;
                }
            }
        }

        function loginWithToken(jwt, rememberMe) {
            var deferred = $q.defer();
            if (angular.isDefined(jwt)) {
                this.storeAuthenticationToken(jwt, rememberMe);
                deferred.resolve(jwt);
            } else {
                deferred.reject();
            }

            return deferred.promise;
        }

        function storeAuthenticationToken(jwt, rememberMe) {
            if(rememberMe){
                $localStorage.authenticationToken = jwt;
            } else {
                $sessionStorage.authenticationToken = jwt;
            }
        }

        function logout () {
            delete $localStorage.authenticationToken;
            delete $sessionStorage.authenticationToken;
        }
    }
})();
