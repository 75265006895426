(function () {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('FiltrosTableroController', FiltrosTableroController);

    FiltrosTableroController.$inject = ['$http', '$uibModalInstance', 'filtros', 'fechas', 'proyectoId', 'canalElegido', 'indicadores', '$uibModal'];

    function FiltrosTableroController($http, $uibModalInstance, filtros, fechas, proyectoId, canalElegido, indicadores, $uibModal) {
        var vm = this;
        vm.fechas = fechas;
        vm.proyectoId = proyectoId;
        vm.canalElegido = canalElegido;
        vm.filtrosSelected = {};
        vm.filtros = filtros;
        vm.filtrosList = {};
        vm.loaded = false;
        loadFiltros();
        vm.aceptar = function (indicePadre) {
            // console.log(vm.filtros);
            $uibModalInstance.close({filtros:vm.filtros, fechas:vm.fechas});
        }

        vm.abrirGeografia = function () {
            var objeto = { filtros: vm.filtrosList['Geografía'], select: vm.filtros.Geografia };
            $uibModal.open({
                templateUrl: 'app/components/geografia/geografia.html',
                controller: 'GeografiaController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    filterList: objeto
                }
            }).result.then(function (result) {
                vm.filtros.Geografia = result;
            });
        }

        vm.abrirDistribucion = function () {
            var objeto = { filtros: vm.filtrosList['Distribución'], select: vm.filtros.Distribucion };
            $uibModal.open({
                templateUrl: 'app/components/cadena/cadena.html',
                controller: 'CadenaController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    filterList: objeto
                }
            }).result.then(function (result) {
                if (!vm.filtros.Distribucion) vm.filtros.Distribucion = {}
                var resultado = result;
                angular.forEach(resultado, function (obj) {
                    obj.tipo = vm.filtrosList['Distribución'].SelectFilter;
                });
                if (vm.filtros.Distribucion === '' || vm.filtros.Distribucion.length === 0) {
                    vm.filtros.Distribucion = [];
                }
                else {
                    var arregloFix = [];
                    angular.forEach(vm.filtros.Distribucion, function (value) {
                        if (value.tipo !== vm.filtrosList['Distribución'].SelectFilter) {
                            arregloFix.push(value);
                        }
                    });
                    vm.filtros.Distribucion = arregloFix;
                }
                vm.filtros.Distribucion = vm.filtros.Distribucion.concat(resultado);
            });
        }

        vm.abrirModal = function (categoria) {
            var filtro = vm.filtrosList[categoria];
            var objeto = { filtros: filtro, select: vm.filtros.Distribucion };
            $uibModal.open({
                templateUrl: 'app/components/cadena/cadena.html',
                controller: 'CadenaController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    filterList: objeto
                }
            }).result.then(function (result) {
                var resultado = result;
                angular.forEach(resultado, function (obj) {
                    obj.tipo = filtro.SelectFilter;
                });
                if (vm.filtros.Distribucion === '' || vm.filtros.Distribucion.length === 0) {
                    vm.filtros.Distribucion = [];
                } else {
                    var arregloFix = [];
                    angular.forEach(vm.filtros.Distribucion, function (value) {
                        if (value.tipo !== filtro.SelectFilter) {
                            arregloFix.push(value);
                        }
                    });
                    vm.filtros.Distribucion = arregloFix;
                }
                vm.filtros.Distribucion = vm.filtros.Distribucion.concat(resultado);
            });
        }

        vm.filtroValido = function (categoria) {
            var filtro = vm.filtrosList[categoria];
            if (filtro != undefined){
                return true;
            }
            return false;
        }

        vm.abrirFecha = function () {
            $uibModal.open({
                templateUrl: 'app/components/fecha/fecha.html',
                controller: 'FechaController',
                controllerAs: 'vm',
                size: 'md'
            }).result.then(function (result) {
                vm.fechas.fechadesde = result.fechaIni;
                vm.fechas.fechahasta = result.fechaFin;
            });
        }

        function loadFiltros() {
            var filtrosList = [];
            var count = 0;
            var listaIndicadores = [];
            angular.forEach(indicadores, function(indi, key){ 
                angular.forEach(indi, function(i, key){
                    if (i.info.IdMedidaIndicador !== 1068) listaIndicadores.push(i);             
                });
            });
            angular.forEach(Object.values(listaIndicadores), function (indicador, index) {
                $http.get('api/filtros-visiones/' + vm.proyectoId + '/' + indicador.info.IdIndicador + '/' + vm.canalElegido).then(function (response) {
                    filtrosList.push(response.data.m_Item1);
                    count++;
                    if (count == Object.values(listaIndicadores).length) {
                        vm.filtrosList = intersect();
                        vm.loaded = true;
                    }
                });
            });
            function intersect() {
                var filtros = filtrosList[0];
                if (filtrosList.length > 1) {
                    angular.forEach(filtrosList, function (filtro, indexFiltro) {
                        if (indexFiltro != 0) {
                            angular.forEach(filtro, function (group, indexGroup) {
                                angular.forEach(group.Group, function (subgroup, indexSubgroup) {
                                    if (filtros[indexGroup] && filtros[indexGroup].Group[indexSubgroup]) {
                                        var values = {};
                                        angular.forEach(subgroup.ValueFilter, function (valueFilter) {
                                            values[valueFilter.OptionText] = true;
                                        })
                                        var newArr = [];
                                        angular.forEach(filtros[indexGroup].Group[indexSubgroup].ValueFilter,function(value){
                                            if(values[value.OptionText]) newArr.push(value);
                                        });
                                        filtros[indexGroup].Group[indexSubgroup].ValueFilter = newArr;
                                    }
                                })
                            })
                        }
                    })
                }
                var filtrosFinal = {};
                angular.forEach(filtros, function (filtro) {
                    filtrosFinal[filtro.SelectFilter] = filtro;
                })
                return filtrosFinal;
            }
        }

        vm.removeFilter = function (group, filtro) {
            // console.log({filtros:vm.filtros[group],filtro:filtro});
            var newArr = [];
            angular.forEach(vm.filtros[group],function(value){
                if(value.table != filtro.table || value.valor != filtro.valor)
                newArr.push(value)
            })
            vm.filtros[group] = newArr;
            // console.log(filtros[group])
        }
        vm.removeFecha = function(){
            vm.fechas = {fechadesde:'null', fechahasta:'null'};
        }
    }
})();
