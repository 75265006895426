(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('PerfilController', PerfilController);

    PerfilController.$inject = ['$http', '$uibModalInstance', '$state', 'Principal', 'Auth', 'proyectoSeleccionado'];

    function PerfilController ($http, $uibModalInstance, $state, Principal, Auth, proyectoSeleccionado) {
        var vm = this;
        window.scrollTo(0, 0);
        vm.cliente = '';
        vm.nuevoPassword = '';
        vm.proyectoNombre = proyectoSeleccionado.nombre;
        vm.usuarioId = Principal.identity().$$state.value.Id;
        $http.get('api/clientes/user/' + vm.usuarioId).then(function(response) {
            vm.cliente = response.data;
            vm.contacto = vm.cliente.Contacto;
            vm.correo = vm.cliente.Correo;
            vm.direccion = vm.cliente.Direccion;
            vm.rfc = vm.cliente.RFC;
            vm.razonSocial = vm.cliente.RazonSocial;
            vm.telefono = vm.cliente.Telefono;
            vm.idCliente = vm.cliente.IdCliente;
            vm.base64Imagen = vm.cliente.Imagen;
        });

        vm.mostrar = false;
        vm.guardarysalir = guardarysalir;
        vm.cambiarfoto = cambiarfoto;
        vm.descartarcambios = descartarcambios;
        vm.cerrarModal = cerrarModal;
        vm.cambiarImagen = cambiarImagen;
        vm.buscarImagen = buscarImagen;
        vm.cambiarPassword = cambiarPassword;
        var modal = document.getElementById('myModal');

        function buscarImagen($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (e) {
                    vm.base64Imagen = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                };
            }
        }

        function cerrarModal() {
            modal.style.display = "none";
        }

        function cambiarfoto() {
            modal.style.display = "block";
        }

        function cambiarPassword() {
            var data = {
                "NewPassword": vm.nuevoPassword
            };
            $http.post('api/account/change-password', data).then(function(response) {
            });
            $uibModalInstance.close();
        }


        function cambiarImagen() {
            vm.base64Imagen = vm.nuevaImagen;
            modal.style.display = "none";
        }

        function guardarysalir () {
            var data = {
                "Contacto": "" + vm.contacto,
                "Correo": "" + vm.correo,
                "Direccion": "" + vm.direccion,
                "IdCliente": "" + vm.idCliente,
                "RFC": "" + vm.rfc,
                "RazonSocial": vm.razonSocial,
                "Telefono": vm.telefono,
                "Imagen": vm.base64Imagen
            };
            $http.put('api/clientes/', data).then(function(response) {
            });
            $uibModalInstance.close({razonSocial: vm.razonSocial});
        }

        function descartarcambios() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

