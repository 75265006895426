(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('ResultadoController', ResultadoController);

    ResultadoController.$inject = ['showResult', '$scope','$compile', '$timeout'];

    function ResultadoController (showResult, $scope, $compile, $timeout) {
        var vm = this;
        vm.mostrar = true;
        vm.mostrarmenu = false;
        vm.mostrarFiltros = false;
        vm.mostrarVision = false;
        vm.tooltip = false;
        vm.volverDashboard = volverDashboard;
        vm.agregarFiltro = agregarFiltro;
        vm.cambiarIndicador = cambiarIndicador;
        vm.quitarFiltro = quitarFiltro;
        vm.mostrarMenu = mostrarMenu;
        vm.pedirMostrar = pedirMostrar;
        vm.sendEvent = sendEvent;
        vm.showHover = {};
        vm.positive = positive;
        vm.negative = negative;
        vm.positiveTotal = positiveTotal;
        vm.negativeTotal = negativeTotal;
        vm.rotateCard = rotateCard;
        vm.sumarPagina = sumarPagina;
        vm.restarPagina = restarPagina;
        vm.pagina = 1;
        vm.paginas = 1;
        vm.rotate = false;
        vm.total = 1;

        function rotateCard(obj) {
            obj.isRotated = !obj.isRotated;
        }

        function positive(index) {
            vm.showHover[index] = true;
        }

        function negative(index) {
            vm.showHover[index] = false;
        }

        function positiveTotal() {
            vm.resultadoTotal.mostrarTooltip = true;
        }

        function negativeTotal() {
            vm.resultadoTotal.mostrarTooltip = false;
        }

        function volverDashboard() {
            vm.showdashboardResultado = !vm.showdashboardResultado;
        }

        function sendEvent() {
            showResult.sendMessage(true);
        }

        function pedirMostrar(){
            sendEvent();
        }

        $scope.$watch("vm.resultadoFiltroSelected",function(value){
            if(vm.resultadoFiltroSelected) agregarFiltro(value.filtro, value.filtroGenerales, value.indice);
        })

        function agregarFiltro(filtro, filtroGenerales, indice) {
            var filtroGeneral = '',
                filtroEspecifico = '',
                columna = '',
                valorMostrar = '',
                table = '',
                encontrado = false,
                i = 0;
            if (filtro != null && filtroGenerales != null & indice != null){
                filtroGeneral = filtroGenerales[indice];
                columna = vm.resultadoIndicador[0].vision[indice].OptionText;
                valorMostrar = vm.resultadoIndicador[0].vision[indice].OptionValue;
                table = vm.resultadoIndicador[0].vision[indice].Table;
                encontrado = false;
                if(vm.filtrosResultado.Geografia !== ''){
                    vm.filtrosResultado.Geografia.find(function(value) {
                        if (value.valorA === filtro && value.columna === columna) {
                            encontrado = true;
                        }
                    });
                }
                if(vm.filtrosResultado.Distribucion !== ''){
                    vm.filtrosResultado.Distribucion.find(function(value) {
                        if (value.valorA === filtro && value.columna === columna) {
                            encontrado = true;
                        }
                    });
                }
                if(!encontrado) {
                    if (vm.filtrosResultado.Adicionales === '') {
                        vm.filtrosResultado.Adicionales = [];
                    }
                    vm.filtrosResultado.Adicionales.push({valor: filtroGeneral, valorA: filtro, columna: columna, table: table, valorMostrar: valorMostrar});
                    pedirMostrar();
                }
            } 
            else {
                for (i = 0; i < filtroGenerales.length; i++) {
                    filtroGeneral = filtroGenerales[i];
                    filtroEspecifico = filtro[i];
                    columna = vm.resultadoIndicador[0].vision[i].OptionText;
                    valorMostrar = vm.resultadoIndicador[0].vision[i].OptionValue;
                    table = vm.resultadoIndicador[0].vision[i].Table;
                    encontrado = false;
                    if (vm.filtrosResultado.Geografia !== '') {
                        vm.filtrosResultado.Geografia.find(function (value) {
                            if (value.valorA === filtroEspecifico && value.columna === columna) {
                                encontrado = true;
                            }
                        });
                    }
                    if (vm.filtrosResultado.Distribucion !== '') {
                        vm.filtrosResultado.Distribucion.find(function (value) {
                            if (value.valorA === filtroEspecifico && value.columna === columna) {
                                encontrado = true;
                            }
                        });
                    }
                    if (!encontrado) {
                        if (vm.filtrosResultado.Adicionales === '') {
                            vm.filtrosResultado.Adicionales = [];
                        }
                        vm.filtrosResultado.Adicionales.push({ valor: filtroGeneral, valorA: filtroEspecifico, columna: columna, table: table, valorMostrar: valorMostrar });
                    }
                }
                pedirMostrar();
            }          
        }

        function cambiarIndicador(nombreIndicador) {
            showResult.sendMessage(nombreIndicador);
        }

        function quitarFiltro(filtro, indice) {
            if(vm.filtrosResultado.Adicionales !== '' || vm.filtrosResultado.Adicionales !== []){
                vm.filtrosResultado.Adicionales.find(function(value, index) {
                    if (value.valor === filtro && value.columna === vm.resultadoIndicador[0].vision[indice]) {
                        vm.filtrosResultado.Adicionales.splice(index,1);
                    }
                });
            }
        }

        function mostrarMenu() {
            vm.mostrarmenu = !vm.mostrarmenu;
        }
        
        function sumarPagina(){
            if(vm.pagina < vm.paginas) {
                vm.pagina = vm.pagina + 1;
                vm.rows = [];
                var numMax = vm.pagina * 51,
                    numMin = numMax - 50,
                    columnas = 3,
                    filas = 0,
                    res = [],
                    orderedResultados = [],
                    z = 0,
                    i = 0,
                    j = 0;
                if (vm.pagina == vm.paginas){                                                 
                    for (numMin; numMin <= numMax; numMin++) {
                        if (vm.resultadoIndicador[numMin] !== undefined){
                        res[z] = vm.resultadoIndicador[numMin];
                        z++;
                        }
                    }
                }
                else {                              
                    for (numMin; numMin <= numMax; numMin++) {
                        res[z] = vm.resultadoIndicador[numMin];
                        z++;                      
                    }
                }
                orderedResultados = angular.copy(res);
                filas = Math.ceil(orderedResultados.length / 3);    
                filas = filas > 17 ? 17 : filas;
                for(i = 0; i < filas; i++){
                    vm.rows[i] = [];
                    for(j = 0; j < columnas; j++){
                        vm.rows[i][j] = {
                            resultado: orderedResultados[i*columnas+j],
                            rotate:false
                        };
                    }
                }              
                window.scrollTo(0, 225);
            }
        }

        function restarPagina(){
            if(vm.pagina > 1) {
                vm.pagina = vm.pagina - 1;
                vm.rows = [];
                var numMax = vm.pagina * 51,
                    numMin = numMax - 50,
                    columnas = 3,
                    filas = 17,      
                    res = [],
                    orderedResultados,
                    z = 0,
                    i = 0,
                    j = 0;
                for (numMin; numMin <= numMax; numMin++) {
                    res[z] = vm.resultadoIndicador[numMin];
                    z++;
                }
                orderedResultados = angular.copy(res);
                for(i = 0; i < filas; i++){
                    vm.rows[i] = [];
                    for(j = 0; j < columnas; j++){
                        vm.rows[i][j] = {
                            resultado: orderedResultados[i*columnas+j],
                            rotate: false
                        };
                    }
                }
                window.scrollTo(0, 225);
            }
        }

        $scope.$watch("vm.resultadoIndicador", function (value) {
            vm.total = 0;
            var cantRes = vm.resultadoIndicador.length,
                columnas = 3,
                res = [],
                orderedResultados = [],
                cantTarjetas = 0,
                filas = 0,
                i = 0,
                j = 0;
            if (cantRes > 0){
                vm.total = vm.resultadoIndicador[0].cantidadTotal;
                vm.rows = [];
                if (cantRes > 1){
                    vm.paginas = Math.ceil(cantRes / 51);
                    vm.paginas = vm.paginas > 20 ? 20 : vm.paginas;
                    vm.pagina = 1;          
                }        
                vm.resultadoIndicador.sort(function(a,b){
                    return b.valor-a.valor;
                });
                cantTarjetas = cantRes < 51 ? cantRes : 51;
                for (i = 0; i < cantTarjetas; i++){
                    res[i] = vm.resultadoIndicador[i];
                }
                orderedResultados = angular.copy(res);      
                filas = Math.ceil(orderedResultados.length / 3);
                filas = filas > 17 ? 17 : filas;
                for(i = 0; i < filas; i++){
                    vm.rows[i] = [];
                    for(j = 0; j < columnas; j++){
                        vm.rows[i][j] = {
                            resultado: orderedResultados[i*columnas+j],
                            rotate: false
                        };
                    }
                }
            }
            $timeout(function () {
                $compile( document.getElementById('contentArea') )( $scope)
              });
        }, true);
    }
})();
