(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('TiendasModalController', TiendasModalController);

    TiendasModalController.$inject = ['$http','$uibModalInstance', '$state', 'datosTienda'];

    function TiendasModalController ($http, $uibModalInstance, $state, datosTienda) {
        var vm = this;
        vm.cargando = false;
        vm.volver = volver;
        vm.ExportToXLSX = ExportToXLSX
        vm.markers = datosTienda;
        vm.mostrarDatos = false;
        vm.descargandoExcel = false;
        vm.fechasElegidas = datosTienda.fechasElegidas;
        vm.fotosfachada = [];

        getFotoFachada();
        function getFotoFachada(){
            vm.mostrarDatos = false;
            vm.cargando = true;
            var idNegocios = vm.markers.tiendas.map(function(objeto) {
                return Number(objeto.idNegocio) 
            });

            $http.post('api/mapa/negocios', idNegocios).then(function(response) {
                vm.fotosfachada = response.data;

                vm.markers.tiendas.forEach(function(tienda) {
                    vm.fotosfachada.forEach(function(foto) {
                      if (foto.IdNegocio === tienda.idNegocio) {
                        tienda.pathImage = foto.PathEvidencia;
                      }
                    });
                  });
                
                vm.cargando = false;
                vm.mostrarDatos = true;
            });

            
        }


        function volver() {
            $uibModalInstance.dismiss('cancel');
            $state.go('home');
        }

        function ExportToXLSX() {
            vm.descargandoExcel = true;
            var fechas = vm.fechasElegidas;
            var idNegocios = vm.markers.tiendas.map(function(tienda) {
                return tienda.idNegocio;
              });
            
            var data = {
                "idnegocios": idNegocios,
                "fechadesde": "" + vm.fechasElegidas.FechaInicio,
                "fechahasta": "" + vm.fechasElegidas.FechaFin,
            }

            $http.post('api/mapa/descargarXLSX', data , { responseType: 'arraybuffer' })
                .then(function (response) {
                
                const newDate = new Date();
                const date = newDate.getDate()+ "-" + (newDate.getMonth() + 1) + "-" + newDate.getFullYear();
                const file = new Blob([response.data], { type: 'text/xlsx' });
                saveAs(file,'TiendasMapa_' + date + '.xlsx');
                vm.descargandoExcel = false;
            })
            .catch(function(error) {
                console.log('Error Server', error);
              });;
        }

        function aceptarProyecto() {
            $uibModalInstance.close();
        }
    }
})();