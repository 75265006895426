(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('FechaController', FechaController);

    FechaController.$inject = ['$http', '$uibModalInstance'];

    function FechaController ($http, $uibModalInstance) {
        
        var vm = this;
        vm.disabledButtonFecha = false;
        vm.selectFecha = true;
        vm.anyo = false;
        vm.mes = false;
        vm.quarter = false;
        vm.dia = false;
        vm.aceptarFecha = aceptarFecha;
        vm.buscarFecha = buscarFecha;
        vm.buscarQuarter = buscarQuarter;
        vm.names = ["Año", "Quarter", "Mes", "Dia", "Fecha"];
        
        var date = new Date();
        var maxYear =  date.getFullYear();
        var minYear = 2019;
        var meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
        var ultMeses = [];
        var ultQuarter = [];
        var fechaQuarter = new Date();

        //Carga meses  
        for (var i = 12; i >= 0; i--) {
            var fecha = new Date();
            fecha.setDate(1);
            var mes = (date.getMonth()+i-12);
            fecha.setMonth(mes);
            ultMeses[i] = fecha;
        }
  
        //Carga quarters
        if (fechaQuarter.getMonth() + 1 == 3 || fechaQuarter.getMonth() + 1 == 6 || fechaQuarter.getMonth() + 1 == 9 || fechaQuarter.getMonth() + 1 == 12){
            fechaQuarter.setMonth(fechaQuarter.getMonth() + 0);
        }
        else if (fechaQuarter.getMonth() + 1 == 2 || fechaQuarter.getMonth() + 1 == 5 || fechaQuarter.getMonth() + 1 == 8 || fechaQuarter.getMonth() + 1 == 11){
            fechaQuarter.setMonth(fechaQuarter.getMonth() + 1);
        }
        else if (fechaQuarter.getMonth() + 1 == 1 || fechaQuarter.getMonth() + 1 == 4 || fechaQuarter.getMonth() + 1 == 7 || fechaQuarter.getMonth() + 1 == 10){
            fechaQuarter.setMonth(fechaQuarter.getMonth() + 2);
        }

        for (var i = 0; i < 8; i++) {
            var fecha;
            if ((fechaQuarter.getMonth() + 1) == 3){
                fecha = fechaQuarter.getFullYear() + "-Q1";
            }
            else if ((fechaQuarter.getMonth() + 1) == 6){
                fecha = fechaQuarter.getFullYear() + "-Q2";
            }
            else if ((fechaQuarter.getMonth() + 1) == 9){
                fecha = fechaQuarter.getFullYear() + "-Q3";
            }
            else if ((fechaQuarter.getMonth() + 1) == 12){
                fecha = fechaQuarter.getFullYear() + "-Q4";
            }
            ultQuarter.push(fecha);
            fechaQuarter.setMonth(fechaQuarter.getMonth() - 3);
        }
        ultQuarter = ultQuarter.reverse();

        function buscarFecha(mes){
            return meses[ultMeses[(mes - 1)].getMonth()] + '-' + ultMeses[(mes - 1)].getFullYear();
        }

        function buscarQuarter(quarter){
            return ultQuarter[quarter - 1];
        }

        vm.sliderMes = {
            minValue: 1,
            maxValue: 13,
            options: {
                floor: 1,
                ceil: 13,
                step: 1,
                showTicks: true,
                minRange: 0,
                translate: function(value) {
                    var mes = buscarFecha(value);
                    return mes;
                    }
            }
        };

        vm.sliderQuarter = {
            minValue: 1,
            maxValue: 8,
            options: {
                floor: 1,
                ceil: 8,
                step: 1,
                showTicks: true,
                minRange: 0,
                translate: function(value) {
                    var quarter = buscarQuarter(value);
                    return quarter;
                }
            }
        };

        vm.sliderAnyo = {
            minValue: minYear,
            maxValue: maxYear,
            options: {
                floor: minYear,
                ceil: maxYear,
                step: 1,
                showTicks: true,
                minRange: 0
            }
        };

        vm.today = function() {
            vm.dt = new Date();
            vm.dt.setDate(vm.dt.getDate());
            vm.dt2 = new Date();
        };
        vm.today();

        vm.clear = function() {
            vm.dt = new Date();
            vm.dt.setMonth(vm.dt.getMonth() - 1);
            vm.dt2 = new Date();
        };

        vm.inlineOptions = {
            customClass: getDayClass,
            minDate: new Date(),
            showWeeks: true
        };

        vm.dateOptions = {
            // dateDisabled: disabled,
            formatYear: 'yy',
            maxDate: new Date(),
            minDate: new Date(2019,1,1),
            startingDay: 1
        };

        // Disable weekend selection
        // function disabled(data) {
        //     var date = data.date,
        //         mode = data.mode;
        //     return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
        // }

        vm.toggleMin = function() {
            vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
            vm.dateOptions.minDate = vm.inlineOptions.minDate;
        };

        vm.toggleMin();

        vm.open1 = function() {
            vm.popup1.opened = true;
        };

        vm.open2 = function() {
            vm.popup2.opened = true;
        };

        vm.setDate = function(year, month, day) {
            vm.dt = new Date(year, month, day);
        };
        vm.setDate2 = function(year, month, day) {
            vm.dt2 = new Date(year, month, day);
        };

        vm.formats = ['dd-MMMM-yyyy', 'yyyy-MM-dd', 'dd.MM.yyyy', 'shortDate'];
        vm.format = vm.formats[1];
        vm.altInputFormats = ['M!/d!/yyyy'];

        vm.popup1 = {
            opened: false
        };

        vm.popup2 = {
            opened: false
        };

        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var afterTomorrow = new Date();
        afterTomorrow.setDate(tomorrow.getDate() + 1);
        vm.events = [
            {
                date: tomorrow,
                status: 'full'
            },
            {
                date: afterTomorrow,
                status: 'partially'
            }
        ];

        function getDayClass(data) {
            var date = data.date,
                mode = data.mode;
            if (mode === 'day') {
                var dayToCheck = new Date(date).setHours(0,0,0,0);

                for (var i = 0; i < vm.events.length; i++) {
                    var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

                    if (dayToCheck === currentDay) {
                        return vm.events[i].status;
                    }
                }
            }
            return '';
        }

        var lastday = function(y,m){
            return  new Date(y, m +1, 0).getDate();
        }

        function aceptarFecha() {
            var fecha = {fechaIni: '', fechaFin: ''};
            if(vm.selectFecha){
                fecha.fechaIni = vm.dt.getFullYear() + '-' + (1 + vm.dt.getMonth()) + '-' + vm.dt.getDate();
                fecha.fechaFin = vm.dt2.getFullYear() + '-' + (1 + vm.dt2.getMonth()) + '-' + vm.dt2.getDate();
                var fI = new Date(fecha.fechaIni).setMilliseconds(0);
                var fF = new Date(fecha.fechaFin).setMilliseconds(0);
                if (fI > fF) {
                    fecha.fechaFin = new Date();
                    fecha.fechaFin = fecha.fechaFin.getFullYear() + "-" + (1 + fecha.fechaFin.getMonth()) + "-" + fecha.fechaFin.getDate();
                }
            }
            else if (vm.dia){
                fecha.fechaIni = vm.dt.getFullYear() + '-' + (1 + vm.dt.getMonth()) + '-' + vm.dt.getDate();
                fecha.fechaFin = vm.dt.getFullYear() + '-' + (1 + vm.dt.getMonth()) + '-' + vm.dt.getDate();
            }
            else if(vm.anyo){
                fecha.fechaIni = vm.sliderAnyo.minValue + '-1-1';
                fecha.fechaFin = vm.sliderAnyo.maxValue + '-12-31';
            }
            else if(vm.mes){
                if(ultMeses[vm.sliderMes.maxValue-1].getMonth() === 11){
                    fecha.fechaIni = ultMeses[vm.sliderMes.minValue-1].getFullYear() + '-' + ((ultMeses[vm.sliderMes.minValue-1].getMonth())+1) + '-1';
                    fecha.fechaFin = (ultMeses[vm.sliderMes.maxValue-1].getFullYear()) + '-12-31';
                }
                else{
                    var anioFin = ultMeses[vm.sliderMes.maxValue-1].getFullYear();
                    var mesFin = ultMeses[vm.sliderMes.maxValue-1].getMonth()+1;
                    fecha.fechaIni = ultMeses[vm.sliderMes.minValue-1].getFullYear() + '-' + ((ultMeses[vm.sliderMes.minValue-1].getMonth())+1) + '-1';
                    fecha.fechaFin = anioFin + '-' + mesFin + '-' + lastday(anioFin, mesFin-1);
                }
            }
            else if(vm.quarter){
                var indice = vm.sliderQuarter.minValue - 1;
                var anio = ultQuarter[indice].slice(0,4);
                var q = ultQuarter[indice].slice(ultQuarter[indice].length-1,ultQuarter[indice].length);
                if (q == 1){
                    fecha.fechaIni = anio + '-' + 1 + '-' + 1;    
                    fecha.fechaFin = anio + '-' + 3 + '-' + lastday(anio, 2);
                }
                else if (q == 2){
                    fecha.fechaIni = anio + '-' + 4 + '-' + 1;    
                    fecha.fechaFin = anio + '-' + 6 + '-' + lastday(anio, 5);
                }
                else if (q == 3){
                    fecha.fechaIni = anio + '-' + 7 + '-' + 1;    
                    fecha.fechaFin = anio + '-' + 9 + '-' + lastday(anio, 8);
                }
                else {
                    fecha.fechaIni = anio + '-' + 10 + '-' + 1;    
                    fecha.fechaFin = anio + '-' + 12 + '-' + lastday(anio, 11);
                }
            }
            $uibModalInstance.close(fecha);
        }
    }
})();
