(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('infobox', {
            templateUrl: 'app/components/resultado/infobox.html',
            controller: 'InfoboxController',
            controllerAs: 'vm',
            bindings: {
                objResultado: '=',
                rotate: '=',
                filtroSelected: '=',
                isTotal: '='
            }
        });
})();
