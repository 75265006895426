(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$rootScope', '$state','Principal','$uibModal','$http', '$sessionStorage', 'showResult', '$scope', 'IS_DEV'];

    function DashboardController ($rootScope, $state, Principal, $uibModal, $http, $sessionStorage, showResult, $scope, IS_DEV) {
        var vm = this;
        vm.isDev = IS_DEV;
        //load data only after menu is loaded
        var watch = $rootScope.$watch('isMenuLoaded', function (ìsMenuLoaded) {
            if(ìsMenuLoaded){
                loadData();
            }
        }, true);

        vm.imagenbase64 = null;
        vm.eliminarFiltros = eliminarFiltros;
        vm.showdashboard = false;
        vm.showmainevidencias = false;
        vm.mostrarSidebar = true;
        vm.canalElegido = '';
        vm.indicadorElegido = '';
        vm.idIndicadorElegido = '';
        vm.idMenuPadreIndicador = '';
        vm.cargando = false;
        vm.mostrarTendencias = false;
        vm.mostrarMapas = false;
        vm.mostrarEvidencias = false;
        vm.mostrarTablas = false;
        vm.mostrarResultado = true;
        vm.cargandoTendencias = false;
        vm.cargandoMapa = false;
        vm.cargandoEvidencias = false;
        vm.graficaNoConfigurada = [true,true,true];
        vm.cargandoGraficaSecundarias = [true,true,true];
        vm.resultado = [];
        vm.resultadoTotal = null;
        vm.resultadoTendencias = [];
        vm.resultadoMapa = [];
        vm.resultadoEvidencia = [];
        vm.resultadoGraficaSecundaria = [];
        vm.proyectoId = '';
        vm.nombreProyecto = '';
        vm.visionSeleccionada = [];
        vm.showMessageError = false;
        vm.inicio = true;
        vm.labelsDatos = ['','','','','','','','','','','',''];
        vm.labels = [];
        vm.ultDatos = [[0,0,0,0,0,0,0,0,0,0,0,0]];
        vm.listaCantidad = [[0,0,0,0,0,0,0,0,0,0,0,0]];
        vm.cont = 0;
        vm.filtros = {Geografia: '', Distribucion: '', Adicionales: ''};
        vm.fechas = {FechaInicio: "null", FechaFin: "null"};

        vm.soloEvidencias = false;

        vm.collapseToggle = collapseToggle;
        vm.borrarFiltros = borrarFiltros;
        vm.borrarFiltroCanalIndicador = borrarFiltroCanalIndicador;
        vm.borrarFiltroGeografia = borrarFiltroGeografia;
        vm.borrarFiltroDistribucion = borrarFiltroDistribucion;
        vm.borrarFiltroAdicionales = borrarFiltroAdicionales;
        vm.borrarFecha = borrarFecha;
        vm.borrarVision = borrarVision;
        vm.init = init;
        vm.openModalProyecto = openModalProyecto;
        vm.reload = reload;

        vm.pedirMostrar = pedirMostrar;
        vm.sendEvent = sendEvent;
        vm.sendEventGraficaSecundaria = sendEventGraficaSecundaria;

        vm.usuarioId = Principal.identity().$$state.value.Id;

        function init() {
            vm.proyectoId = $sessionStorage.proyectoId;
            if($sessionStorage.nombreProyecto){
                vm.nombreProyecto = $sessionStorage.nombreProyecto;
            }
            vm.cliente = $sessionStorage.cliente;
            if(vm.cliente){
                vm.razonSocial = $sessionStorage.cliente.RazonSocial;
            }
            if($sessionStorage.Imagen != null) {
                vm.imagenbase64 = $sessionStorage.Imagen;
            }
            if (!vm.proyectoId) {
                openModalProyecto();
            } else {
                $http.get('api/proyectoCanalIndicadores/' + vm.proyectoId).then(function(response) {
                    vm.proyectoCanalIndicador = response.data;
                });
                $http.get('api/proyectos/get/' + vm.proyectoId).then(function(response) {
                    vm.proyecto = response.data;
                    vm.nombreProyecto = $sessionStorage.nombreProyecto = vm.proyecto.NombreProyecto;
                });
            }
        }

        function sendEvent() {
            showResult.sendMessage(true);
        }

        function limpiarGraficasSecundarias() {
            var msg = "limpiar";
            showResult.sendMessage(msg);
        }

        function sendEventGraficaSecundaria(i) {
            var msg = "cargaGrafico" + i;
            showResult.sendMessage(msg);
        }

        function pedirMostrar(){
            sendEvent();
        }

        function reload() {
            sendEvent();
        }

        showResult.subscribe(function(x) {
            if (x == "cargarGraficas") {
                cargarGraficasSecundarias();
            }
        });

        function openModalProyecto() {
            $uibModal.open({
                templateUrl: 'app/components/proyecto/proyecto.html',
                controller: 'ProyectoController',
                controllerAs: 'vm',
                size: 'md',
                backdrop  : 'static',
                keyboard  : false
            }).result.then(function (result) {
                vm.proyectoId = $sessionStorage.proyectoId = result.proyecto;
                vm.cliente = $sessionStorage.cliente = result.cliente;
                vm.proyectoUnico = $sessionStorage.proyectoUnico = result.proyectoUnico;
                vm.imagenbase64 = $sessionStorage.Imagen = vm.cliente.Imagen;
                vm.nombreProyecto = $sessionStorage.nombreProyecto = result.nombreProyecto;
                $http.get('api/proyectoCanalIndicadores/' + vm.proyectoId).then(function(response) {
                    vm.proyectoCanalIndicador = response.data.Content;
                });
                limpiarGraficaPrincipal();

                //actualizo la variable global para cargar los canales e indicadores del menu
                $rootScope.proyectId = vm.proyectoId;
            });
        }

        function limpiarGraficaPrincipal() {
            vm.cont = 0;
            vm.labelsDatos = [];
            vm.labels = [];
            vm.ultDatos = [];
            vm.listaCantidad = [];
        }


        function loadData() {
            if(vm.isDev == false){
                $http.get('api/resultado/graficaPrincipal/' + vm.proyectoId + '/' + vm.usuarioId).then(function (response) {
                    var responseFecha = new Date(response.data);
                    responseFecha.setDate(responseFecha.getDate() + 2);
                    var hasta = responseFecha.getFullYear() + "-" + (responseFecha.getMonth() + 1) + "-" + responseFecha.getDate();
                    var desde = '';
                    if ( (responseFecha.getMonth() + 1) != 12){
                        desde = (responseFecha.getFullYear() - 1) + "-" + (responseFecha.getMonth() + 2) + "-01";
                    }
                    else{
                        desde = responseFecha.getFullYear() + "-01" + "-01";
                    }
                    $http.get('api/indicadores/perfect/' + vm.proyectoId).then(function (response) {
                        var listaPerfect = response.data;
                        vm.labelsDatos = [];
                        var fechaDesde = new Date(desde);
                        var label = '';
                        fechaDesde.setMonth(fechaDesde.getMonth() + 1);
                        for(var i = 0; i < 12; i++){
                            if (i == 0 && fechaDesde.getMonth() == 0){
                                label = "1 - " + fechaDesde.getFullYear();
                                vm.labelsDatos.push(label);
                            }
                            else{
                                if (fechaDesde.getMonth() == 0){
                                    label = "12 - " + (fechaDesde.getFullYear() - 1);
                                    vm.labelsDatos.push(label);
                                    if ( i != 11){
                                        label = "1 - " + fechaDesde.getFullYear();
                                        vm.labelsDatos.push(label);
                                        fechaDesde.setMonth(fechaDesde.getMonth() + 1);
                                        i++;
                                    }
                                }
                                else{
                                    label = fechaDesde.getMonth() + " - " + fechaDesde.getFullYear();
                                    vm.labelsDatos.push(label);
                                }
                            }
                            fechaDesde.setMonth(fechaDesde.getMonth() + 1);
                        }
                        angular.forEach(listaPerfect, function (item) {
                            var dataPerfectStore = {
                                "idProyecto": "" + item.IdProyecto,
                                "idUsuario": "" + vm.usuarioId,
                                "nombrecanal": "" + item.NombreCanal,
                                "idIndicador": "" + item.IdPerfectStore,
                                "fil": [],
                                "fechadesde": "" + desde,
                                "fechahasta": "" + hasta,
                                "vision": ["Mes"]
                            };
    
                            $http.post('api/resultado/perfectStore', dataPerfectStore).then(function (response) {
                                var resultados = [];
                                angular.forEach(response.data, function (resultado){
                                resultado.anio = parseInt(resultado.nombresFiltros[0].substring(0,4));
                                resultado.mes = parseInt(resultado.nombresFiltros[0].substring(5,7));
                                resultados.push(resultado);
                                });
                                resultados.sort(function(a,b){
                                        if( a.anio < b.anio ){
                                            return -1;
                                        }
                                        if( a.anio > b.anio){
                                            return 1;
                                        }
                                        if( a.anio === b.anio){
                                            if(a.mes < b.mes ) {
                                                return -1;
                                            }
                                            else{
                                                return 1;
                                            }
                                        }
                                        return 0;
                                });
                                if(resultados.length === 13){
                                    resultados.splice(0, 1);
                                }
                                else if (resultados.length < 13 && resultados.length > 0){
                                    while (resultados.length < 12) {
                                        resultados.unshift(null);
                                    }
                                    var fechaHasta = new Date(hasta);
                                    var mesMax = (fechaHasta.getMonth() +1);
                                    var anioMax = (fechaHasta.getFullYear());
                                    if (resultados[11].anio != anioMax || resultados[11].mes != mesMax){
                                        var cant = 0;
                                        if (resultados[11].anio != anioMax){
                                            cant = 12 + mesMax - resultados[11].mes;
                                            for (var j=0; j<cant; j++){
                                                resultados.shift();
                                                resultados.splice(11+j,0,null);
                                            }
                                        }
                                        else if (resultados[11].mes != mesMax){
                                            cant = cant + (mesMax - resultados[11].mes);
                                            for (var j=0; j<cant; j++){
                                                resultados.shift();
                                                resultados.splice(11+j,0,null);
                                            }
                                        }
                                    }
                                    for(var i = 11; i > 0; i--){
                                        if (resultados[i] == null || resultados[i-1] == null){
                                            continue;
                                        }
                                        if(resultados[i].mes == 1){
                                            if ( ( resultados[i].mes + 11 != resultados[i-1].mes ) && ( resultados[i].anio - 1 != resultados[i-1].anio ) ){
                                                var cant = (resultados[i].mes + 11) - (resultados[i-1].mes);
                                                for (var j=0; j<cant; j++){
                                                    resultados.shift();
                                                    resultados.splice(i+j-1,0,null);
                                                }
                                            }
                                        }
                                        else if( (resultados[i].mes - 1) != (resultados[i-1].mes) ){
                                            var cant = (resultados[i].mes - 1) - (resultados[i-1].mes);
                                            for (var j=0; j<cant; j++){
                                                resultados.shift();
                                                resultados.splice(i+j-1,0,null);
                                            }
                                        }
                                    }
                                }
    
                                if(resultados.length !== 0){
                                    $http.post('api/resultado/perfectStore/cantidadEncuestas', dataPerfectStore).then(function (responseCantidad) {
                                        var resultadosCantidad = [];
                                        angular.forEach(responseCantidad.data, function (resultadoCantidad) {
                                            resultadoCantidad.anio = parseInt(resultadoCantidad.nombresFiltros[0].substring(0, 4));
                                            resultadoCantidad.mes = parseInt(resultadoCantidad.nombresFiltros[0].substring(5, 7));
                                            resultadosCantidad.push(resultadoCantidad);
                                        });
                                        resultadosCantidad.sort(function (a, b) {
                                                if (a.anio < b.anio) {
                                                    return -1;
                                                }
                                                if (a.anio > b.anio) {
                                                    return 1;
                                                }
                                                if (a.anio === b.anio) {
                                                    if (a.mes < b.mes) {
                                                        return -1;
                                                    } else {
                                                        return 1;
                                                    }
                                                }
                                                return 0;
                                            }
                                        );
                                        if(resultadosCantidad.length === 13){
                                            resultadosCantidad.splice(0, 1);
                                        }
                                        if(resultadosCantidad.length !== 0) {
                                            var lista = [];
                                            var i = 0;
                                            angular.forEach(resultadosCantidad, function (resultado) {
                                                if (i >= 12){
                                                    return;
                                                }
                                                if (resultados[i] != null){
                                                    lista.push(resultado.resultado);
                                                }
                                                else{
                                                    do{
                                                        lista.push(null);
                                                        i = i + 1;
                                                    } while(resultados[i] == null && i <= 11)
                                                    if (i <= 11){
                                                        lista.push(resultado.resultado);
                                                    }
                                                }
                                                i = i + 1;
                                            });
                                            while ( i <= 11 ){
                                                lista.push(null);
                                                i = i + 1;
                                            }
                                        }
                                        vm.listaCantidad.push(lista);
                                    });
                                    if(vm.inicio){
                                        vm.inicio = false;
                                        vm.ultDatos = [];
                                        vm.listaCantidad.splice(0,1);
                                    }
                                    var ultimos = [];
                                    if(resultados.length !== 0) {
                                        angular.forEach(resultados, function (item) {
                                            if(item){
                                                ultimos.push((item.resultado * 100).toFixed(2));
                                            }
                                            else{
                                                ultimos.push(null);
                                            }
                                        });
                                    }
                                    vm.labels.push(" "+ item.NombreCanal + " - " + item.NombreIndicador);
                                    vm.ultDatos.push(ultimos);
                                }
                            });
                        });
                        cargarGraficasSecundarias();
                    });
                });
            }
            
        }


        function cargarGraficasSecundarias() {
            limpiarGraficasSecundarias();
            vm.graficaNoConfigurada = [true,true,true];
            vm.cargandoGraficaSecundarias = [true,true,true];
            $http.get('api/grafica/' + vm.proyectoId).then(function(graficasProyecto) {
                vm.graficasSecundarias = graficasProyecto.data;
                vm.resultadoGraficaSecundaria = [];
                vm.cargandoGraficaSecundarias = [false,false,false];
                angular.forEach(vm.graficasSecundarias, function (obj) {
                    vm.graficaNoConfigurada[obj.Pos - 1] = false;
                    vm.cargandoGraficaSecundarias[obj.Pos - 1] = true;
                });
                var cont = 0;
                if(vm.graficasSecundarias.length !== 0) {
                    angular.forEach(vm.graficasSecundarias, function (obj) {
                        $http.get('api/resultado/graficaSecundaria/' + obj.IdGrafica + "/" + vm.proyectoId + "/" + vm.usuarioId).then(function (response) {
                            var resultado = response.data;
                            angular.forEach(resultado.resultados, function (res) {
                                angular.forEach(res.nombresFiltros, function (filtro, i) {
                                    filtro = regex(filtro);
                                    res.nombresFiltros[i] = filtro;
                                });
                            });
                            vm.cargandoGraficaSecundarias[obj.Pos - 1] = false;
                            resultado.Pos = obj.Pos;
                            cont = cont + 1;
                            vm.resultadoGraficaSecundaria.push(resultado);
                            sendEventGraficaSecundaria(cont);
                        });
                    });
                }
            });
        }

        function regex(datos) {
            var myRe = new RegExp('##[0-9]*##','g');
            return datos.replace(myRe,'');
        }

        function collapseToggle(){
            vm.mostrarSidebar = !vm.mostrarSidebar;
        }

        function borrarFiltroCanalIndicador(){
            vm.canalElegido = '';
            vm.indicadorElegido = '';
            vm.idMenuPadreIndicador = '';
            vm.showdashboard = false;
            vm.showmainevidencias = false;
        }

        function eliminarFiltros(){
            if(vm.visionSeleccionada.length !== 0 || vm.filtros.Geografia.length !== 0 || vm.filtros.Distribucion.length !== 0 || vm.filtros.Adicionales.length !== 0 || vm.fechas.FechaFin !== "null" && vm.fechas.FechaInicio !== "null") {
                return true;
            }
            return false;
        }


        function borrarFiltros(){
            vm.visionSeleccionada = [];
            vm.filtros = {Geografia: [], Distribucion: [], Adicionales: []};
            vm.fechas = {FechaInicio: "null", FechaFin: "null"};
            $scope.$watch('vm.visionSeleccionada', function(){
                if(vm.visionSeleccionada.length === 0){
                    pedirMostrar();
                }
            });
        }

        function borrarVision(indice){
            if (indice > -1) {
                vm.visionSeleccionada.splice(indice, 1);
                pedirMostrar();
            }
        }

        function borrarFiltroGeografia(indice){
            if (indice > -1) {
                vm.filtros.Geografia.splice(indice, 1);
                pedirMostrar();
            }
        }

        function borrarFiltroDistribucion(indice){
            if (indice > -1) {
                vm.filtros.Distribucion.splice(indice, 1);
                pedirMostrar();
            }
        }

        function borrarFiltroAdicionales(indice){
            if (indice > -1) {
                vm.filtros.Adicionales.splice(indice, 1);
                pedirMostrar();
            }
        }

        function borrarFecha(){
            vm.fechas.FechaInicio = "null";
            vm.fechas.FechaFin = "null";
            pedirMostrar();
        }
    }
})();
