(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope','Principal', '$state', '$timeout', 'Auth', '$uibModalInstance'];

    function LoginController ($rootScope, $state, Principal, $timeout, Auth, $uibModalInstance) {
        var vm = this;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.grant_type = 'password';
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true,
                grant_type: 'password'
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login (event) {
            if(event != null) {
                event.preventDefault();
            }

            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe,
                grant_type: vm.grant_type
            }).then(function () {
                vm.authenticationError = false;
                $uibModalInstance.close();
                $state.go('home', {},{reload:true});

                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (resp) {
                if (resp.data.error === 'invalid_grant') {
                    vm.authenticationError = true;
                }

                if (resp.data.error === 'no_client') {
                    vm.noClientError = true;
                }
            });
        }

        function register () {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
