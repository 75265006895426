(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('eviPagination', {
            templateUrl: 'app/components/eviPagination/eviPagination.html',
            controller: 'EviPaginationController',
            controllerAs: 'vm',
            bindings: {
                size: '=',
                page: '=',
            }
        });
})();
