(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('GeografiaController', GeografiaController);

    GeografiaController.$inject = ['$http', '$uibModalInstance', 'DASHBOARD_API_URL', 'filterList', '$timeout'];

    function GeografiaController ($http, $uibModalInstance, DASHBOARD_API_URL, filterList, $timeout) {
        var vm = this;
        vm.cargando = false;
        vm.proyectoId = filterList.proyectoId;
        vm.usuarioId = filterList.idUsuario;
        vm.canalElegido = filterList.nombreCanal;
        vm.idIndicador = filterList.idIndicador;
        vm.arregloFiltros = [];//filterList.arregloFiltros;
        vm.fechasElegidas = {FechaInicio: 'null', FechaFin: 'null'};
        vm.geografia = {};
        vm.ultimoFiltroElegidoMunicipios = [];
        vm.ultimoFiltroElegidoEstados = [];
        vm.ultimoFiltroElegidoAreasNielsen = [];
        vm.ultimoFiltroElegidoPaises = [];
        vm.filtroElegido = [];
        vm.agregados = [];

        vm.disabledButtonGeografia = true;

        vm.disabledEstado = true;
        vm.disabledUbicacion = true;

        vm.tieneMunicipios = false;
        vm.tieneEstados = false;
        vm.tieneAreaNielsen = false;
        vm.tienePaises = false;

        vm.areas = '';
        vm.estados = '';
        vm.ubicaciones = '';

        vm.filterList = filterList.filtros;
        vm.datosTabla = angular.copy(filterList.filtros);
        vm.aplicarFiltro = aplicarFiltro;
        vm.aceptarGeografia = aceptarGeografia;
        vm.mostrarListaClic = mostrarListaClic;
        vm.seleccionoValor = seleccionoValor;
        vm.busqueda = busqueda;

        vm.seleccionarTodos = seleccionarTodos;
        vm.seleccionoTodos = false;
        vm.SeleccionarTodosText = "Seleccionar Todos";
        // vm.cargarSeleccionados = cargarSeleccionados;

        vm.mostrarLista = [];
        vm.disabledButtonGeografia = [];

        vm.filtro = '';
        vm.seleccionado = [];//filterList.select;

        $timeout(function () {
            cargarSeleccionados();
        }, 5);

        function regex(datos) {
            var myRe = new RegExp('##[0-9]*##','g');
            return datos.replace(myRe,'');
        }

        function aplicarFiltro(indice) {
            var indiceSiguiente = indice + 1;

            //obtener seleccionado y guardar en memoria
            vm.arregloFiltros = [];
            angular.forEach(vm.filtroElegido, function (item) {
                vm.arregloFiltros.push({
                    valorFiltroGeografia: item.valor.toString(),
                    columnaFiltroGeografia: item.columna.toString(),
                    valorFiltroDistribucion: "null",
                    columnaFiltroDistribucion: "null",
                    table: item.table.toString()
                })
            });

            if(indice == vm.mostrarLista.length - 1)
            {
                aceptarGeografia();
            }
            else{
                mostrarListaClic(indiceSiguiente);
            }
            

        }

        function cargarSeleccionados() {
            angular.forEach(vm.seleccionado, function (seleccionado) {
                vm.filtroElegido.push(seleccionado);
                angular.forEach(vm.datosTabla.Group, function (filtros, pindex) {
                    if(seleccionado.columna === filtros.GroupFilter){
                        vm.disabledButtonGeografia[pindex] = false;
                        angular.forEach(filtros.ValueFilter, function (filtro, index){
                            if(seleccionado.valor === filtro.OptionText){
                                document.getElementById('radioG' + pindex + index +'').checked = true;
                            }
                        });
                    }
                });
            })
        }

        angular.forEach(vm.filterList.Group, function (elem) {
            vm.mostrarLista.push(false);
            vm.disabledButtonGeografia.push(true);
        });

        function mostrarListaClic(indice) {
            vm.seleccionoTodos = true;
            vm.seleccionarTodos(indice);

            var descripcionFiltro = vm.datosTabla.Group[indice].GroupFilter;
            var dataFiltros = {
                "idProyecto": "" + vm.proyectoId,
                "idUsuario": "" + vm.usuarioId,
                "nombrecanal": "" + vm.canalElegido,
                "idIndicador": "" + vm.idIndicador,
                "fil": [],
                "fechadesde": "" + vm.fechasElegidas.FechaInicio,
                "fechahasta": "" + vm.fechasElegidas.FechaFin,
                "vision": [],
                "nombreMenuPadre": ""
            };

            vm.filterList.Group[indice].ValueFilter = [];
            vm.datosTabla.Group[indice].ValueFilter = [];

            //obtener datos
            vm.cargando = true;
            if (descripcionFiltro === "NombrePais") {
                dataFiltros.nombreMenuPadre = "Paises";
                angular.forEach(vm.arregloFiltros, function (obj) {
                    dataFiltros.fil.push(obj);
                });
            }

            if (descripcionFiltro === "AreaNielsen") {
                dataFiltros.nombreMenuPadre = "AreasNielsen";
                angular.forEach(vm.arregloFiltros, function (obj) {
                    // if(obj.columnaFiltroGeografia === 'NombrePais' ){
                    //     dataFiltros.fil.push(obj);
                    // }
                    dataFiltros.fil.push(obj);
                });
            }

            if (descripcionFiltro === "NombreEstado") {
                dataFiltros.nombreMenuPadre = "Estados";
                angular.forEach(vm.arregloFiltros, function (obj) {
                    dataFiltros.fil.push(obj);
                });
            }

            if (descripcionFiltro === "NombreUbicacion") {
                dataFiltros.nombreMenuPadre = "Municipios";
                angular.forEach(vm.arregloFiltros, function (obj) {
                    dataFiltros.fil.push(obj);
                });
            }

            if (vm.filterList.SelectFilter == "Geografía") {
                $http.post('api/filtros-visiones/cadenas', dataFiltros).then(function (response) {
                    vm.filterList.Group[indice].ValueFilter = response.data;
                    vm.datosTabla.Group[indice].ValueFilter = response.data;
                    vm.cargando = false;
                });

                
            } 

            //agrego el campo selected para poder vincularlo al checkbox y luego utilizar seleccionar/deselecccionar todos
            vm.filterList.Group[indice].ValueFilter = vm.filterList.Group[indice].ValueFilter.map(function(item) {
                item.selected = false;
                return item;
            });


            angular.forEach(vm.filterList.Group,function(item, index){
                if(index === indice){
                    vm.mostrarLista[index] = !(vm.mostrarLista[index]);
                }
                else{
                    vm.mostrarLista[index] = false;
                }
            });
        }

        function seleccionoValor(indicePadre, indiceHijo) {
            var row = vm.datosTabla.Group[indicePadre].ValueFilter[indiceHijo];
            var valor = vm.datosTabla.Group[indicePadre].ValueFilter[indiceHijo].OptionText;
            var columna = vm.datosTabla.Group[indicePadre].GroupFilter;
            var table = vm.datosTabla.Group[indicePadre].Table;
            var valorMostrar = vm.datosTabla.Group[indicePadre].valorMostar;
            var filtro = {valor: valor, columna: columna, table: table, valorA: regex(valor)};
            var elimino = false;

            vm.filtroElegido.find(function(value, index) {
                if (value && value.valor === filtro.valor && filtro && value.columna === filtro.columna) {
                    vm.filtroElegido.splice(index,1);
                    elimino = true;
                    row.selected = false;
                }
            });
            if(!elimino){
                row.selected = true;
                filtro.valorMostar = valorMostrar;
                vm.filtroElegido.push(filtro);
                angular.forEach(vm.filterList.Group,function(item, index){
                    if(index === indicePadre){
                        vm.disabledButtonGeografia[index] = false;
                    }
                    else{
                        vm.disabledButtonGeografia[index] = true;
                    }
                });
            }

        }


        // function aceptarGeografia(indicePadre){
        //     var filtrosElegidos = [];
        //     angular.forEach(vm.filtroElegido,function(item,index){
        //         if(item.columna === vm.datosTabla.Group[indicePadre].GroupFilter){
        //             item.valorMostar = item.columna.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
        //             filtrosElegidos.push(item);
        //         }
        //     });
        //     $uibModalInstance.close(filtrosElegidos);
        // }

        function aceptarGeografia(){
            var filtrosElegidos = [];

            //setea variables para despues dejar la ultima seleccion
            CargarListaMunicipios();
            CargarListaEstados();
            CargarListaAreasNielsen();
            CargarListaPaises();
            angular.forEach(vm.filtroElegido,function(item,index){
                
                if(vm.ultimoFiltroElegidoMunicipios.length > 0){
                    if(item.valorMostar == 'Municipios'){
                        filtrosElegidos.push(item);
                    }
                }
                else if(vm.ultimoFiltroElegidoEstados.length > 0){
                    if(item.valorMostar == 'Estados'){
                        filtrosElegidos.push(item);
                    }
                }
                else if(vm.ultimoFiltroElegidoAreasNielsen.length > 0){
                    if(item.valorMostar == 'Áreas Nielsen'){
                        filtrosElegidos.push(item);
                    }
                }
                else if(vm.ultimoFiltroElegidoPaises.length > 0){
                    if(item.valorMostar == 'Países'){
                        filtrosElegidos.push(item);
                    }
                }
                
            });
            $uibModalInstance.close(filtrosElegidos);
        }

        function busqueda(i) {
            vm.datosTabla.Group[i].ValueFilter = [];
            angular.forEach(vm.filterList.Group[i].ValueFilter, function (elem) {
                if(elem.OptionText.toLowerCase().indexOf(vm.filtro.toLowerCase().trim()) > -1){
                    vm.datosTabla.Group[i].ValueFilter.push(elem);
                }
            });
        }

        function CargarListaMunicipios() {
            angular.forEach(vm.filtroElegido,function(item,index){
                if(item.valorMostar == 'Municipios'){
                    vm.ultimoFiltroElegidoMunicipios.push(item);
                }
            });
        }

        function CargarListaEstados() {
            angular.forEach(vm.filtroElegido,function(item,index){
                if(item.valorMostar == 'Estados'){
                    vm.ultimoFiltroElegidoEstados.push(item);
                }
                
            });
        }

        function CargarListaAreasNielsen() {
            angular.forEach(vm.filtroElegido,function(item,index){
                if(item.valorMostar == 'Áreas Nielsen'){
                    vm.ultimoFiltroElegidoAreasNielsen.push(item);
                }
                
            });
        }

        function CargarListaPaises() {
            angular.forEach(vm.filtroElegido,function(item,index){
                if(item.valorMostar === 'Países'){
                    vm.ultimoFiltroElegidoPaises.push(item);
                }
                
            });
        }

        function seleccionarTodos(indice){
            vm.seleccionoTodos = !vm.seleccionoTodos;
            if(vm.seleccionoTodos){
                vm.SeleccionarTodosText = "Deseleccionar Todos";
                vm.disabledButtonGeografia[indice] = false;
                angular.forEach(vm.datosTabla.Group[indice].ValueFilter, function(item) {
                    item.selected = true;
                    var valor = item.OptionText;
                    var columna = vm.datosTabla.Group[indice].GroupFilter;
                    var table = vm.datosTabla.Group[indice].Table;
                    var valorMostrar = vm.datosTabla.Group[indice].valorMostar;
                    var filtro = {valor: valor, columna: columna, table: table, valorA: regex(valor)};
                    filtro.valorMostar = valorMostrar;
                    vm.filtroElegido.push(filtro);
                });

            }
            else{
                vm.SeleccionarTodosText = "Seleccionar Todos";
                vm.disabledButtonGeografia[indice] = true;
                angular.forEach(vm.datosTabla.Group[indice].ValueFilter, function(item) {
                    item.selected = false;

                    vm.filtroElegido.find(function(value, index) {
                        if (value && value.valor === item.OptionText && value.columna === vm.datosTabla.Group[indice].GroupFilter) {
                            vm.filtroElegido.splice(index,1);
                        }
                    });
                });

                

            }    
        }
    }
})();
