(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('MedidaSecundariaController', MedidaSecundariaController);

    MedidaSecundariaController.$inject = ['$http', '$uibModalInstance', 'DASHBOARD_API_URL', 'filterList', '$timeout'];

    function MedidaSecundariaController ($http, $uibModalInstance, DASHBOARD_API_URL, filterList, $timeout) {
        var vm = this;

        vm.cadena = {};
        vm.cadenaSeleccionada = '';
        vm.distribucionSeleccionada = {valor: '', columna: ''};
        vm.disabledButtonCadena = true;
        vm.filtro = '';
        vm.medidaSecundariaElegida = [];
        vm.filterList = filterList.filtros;
        vm.seleccionado = filterList.select;
        vm.datosTabla = angular.copy(filterList.filtros);

        vm.seleccionoValor = seleccionoValor;
        vm.aceptarCadena = aceptarCadena;
        vm.busqueda = busqueda;
        vm.cargarSeleccionados = cargarSeleccionados;

        $timeout(function () {
            cargarSeleccionados();
        }, 5);

        function aceptarCadena() {
            $uibModalInstance.close(vm.medidaSecundariaElegida);
        }

        function cargarSeleccionados() {
            vm.medidaSecundariaElegida = [];

            angular.forEach(vm.seleccionado, function (seleccionado) {
                vm.medidaSecundariaElegida.push(seleccionado);
                angular.forEach(vm.datosTabla, function (filtro,index) {
                     if(seleccionado.IdMedidaSecundaria === filtro.IdMedidaSecundaria){
                         document.getElementById('radioB'+ index +'').checked = true;
                    }
                 });
             });
        }

        function seleccionoValor(indiceHijo) {
            var valor = vm.datosTabla[indiceHijo];
            var elimino = false;

            vm.medidaSecundariaElegida.find(function (value, index) {
                if (value && value.IdMedidaSecundaria && valor && valor.IdMedidaSecundaria) {
                    if (value.IdMedidaSecundaria === valor.IdMedidaSecundaria) {
                        vm.medidaSecundariaElegida.splice(index, 1);
                        elimino = true;
                    }
                }
            });
            if (!elimino) {
                vm.medidaSecundariaElegida.push(valor);
            }

            if(vm.medidaSecundariaSeleccionada === []) {
                vm.disabledButtonCadena = true;
            }
            else {
                vm.disabledButtonCadena = false;
            }
        }

        function busqueda() {
            vm.datosTabla = [];
            angular.forEach(filterList.filtros, function (elem) {
                if(elem.Nombre.toLowerCase().indexOf(vm.filtro.toLowerCase().trim()) > -1){
                    vm.datosTabla.push(elem);
                }
            });
        }
    }
})();
