(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('eviFiltros', {
            templateUrl: 'app/components/eviFiltros/eviFiltros.html',
            controller: 'EviFiltrosController',
            controllerAs: 'vm',
            bindings: {
                filtros: '=',
                filtrosSelected: '=',
                filtrosHidden: '=',
                filtrosPlain: '=',
                loaded: '='
            }
        });
})();
