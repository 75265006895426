(function () {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('TableroController', TableroController);

    TableroController.$inject = ['$rootScope','$scope', '$http', '$timeout',  'Principal', 'showResult', '$uibModal'];

    function TableroController($rootScope, $scope, $http, $timeout, Principal, showResult, $uibModal) {
        var vm = this;
        vm.filtros = { Geografia: {}, Distribucion: {}, Cadena: {} };
        vm.indicadores = {};
        var date = new Date;
        var year = date.getFullYear();
        var trimesterMonth = date.getMonth()-2;
        var day = date.getDate();
        if (trimesterMonth === 0){
            trimesterMonth = 12;
            year = date.getFullYear()-1;
        } else if (trimesterMonth === -1) {
            trimesterMonth = 11;
            year = date.getFullYear()-1;
        } else if (trimesterMonth === -2){
            trimesterMonth = 10;
            year = date.getFullYear()-1;
        }
        var lastMonth = (new Date(new Date().setDate(new Date().getDate()-30))).toLocaleString("sv-SE")
        var lastMonthString = lastMonth.substring(0,10)
        console.log(lastMonth);
        console.log(lastMonthString);
        vm.fechas = { fechadesde: 'null' , fechahasta: 'null' };
        vm.loaded = {};
        vm.menu = {};
        vm.menuElegido = {};
        vm.menuTablero = {};
        vm.crumbMenu = {};
        vm.hideMenu = false;
        vm.usuarioId = Principal.identity().$$state.value.Id;
        vm.returnMenu = returnMenu;
        vm.setOpcion = setOpcion;
        vm.mostrarFiltros = mostrarFiltros;
        vm.proyectColombia = proyectColombia;

        //load data only after menu is loaded
        var watch = $rootScope.$watch('isMenuLoaded', function (ìsMenuLoaded) {
            if(ìsMenuLoaded){
                loadCanales();
            }
        }, true);

        function loadCanales() {
            vm.indicadores = {};
            if(vm.proyectoId) {
                    $http.get('api/canales/' + vm.proyectoId).then(function (response) {
                    vm.canales = response.data;
                    loadMenuTablero();
                });
            }
        }

        function getIndicadores() {
            var promises = []
            angular.forEach(vm.canales, function (canal) {
                var promise = loadCanal(canal);
                promise.then(function () {
                    vm.loaded[canal.Nombre] = true;
                })
                promises.push(promise);
            });
            return Promise.all(promises);
        }

        function loadMenuTablero(){
            vm.menuTablero = {};
            $http.get('api/menuTablero/proyecto/' + vm.proyectoId).then(function (response) {
                const menuRes = response.data;
                angular.forEach(menuRes, function(res){
                    vm.menuTablero[res.IdMenu] = res.Titulo;
                })
                getIndicadores();
            });
        }

        function loadCanal(canal) {
            vm.loaded[canal.Nombre] = false;
            vm.menu = {};
            var resolvePromise,
                promise = new Promise(function (resolve) {
                    resolvePromise = resolve;
                }),
                promises = [],
                menuCanal = {};
            $http.get('api/indicadorTablero/' + vm.cliente.IdCliente + '/' + vm.proyectoId + '/' + canal.IdCanal).then(function (response) {
                const resTablero = response.data;
                //Armo el menu del canal
                angular.forEach(resTablero, function (indi){
                    if (indi.IdMenu !== null){
                        angular.forEach(vm.menuTablero, function (titulo, key){
                            if (indi.IdMenu == key){
                                indi['NombreMenu'] = titulo;
                            }
                        })
                        // promises.push(addNameMenu(indi));
                    }
                    if (indi.IdMenuPadre !== null){
                        if (!menuCanal[indi.IdMenuPadre]) menuCanal[indi.IdMenuPadre] = [];
                        menuCanal[indi.IdMenuPadre][indi.Orden] = indi;
                    }
                    else {
                        if (!menuCanal[0]) menuCanal[0] = [];
                        menuCanal[0][indi.Orden] = indi;
                    }
                });
                //Elimino los null
                angular.forEach(menuCanal, function(m, index){
                    menuCanal[index] = m.filter(function(){
                        return true
                    });
                })
                //Cargo indicador
                angular.forEach(resTablero, function (indicador) {
                    if (indicador.IdIndicador !== null){
                        promises.push(loadIndicador(canal, indicador));
                    }
                })
                Promise.all(promises).then(function () {
                    resolvePromise();
                    if (!angular.equals(menuCanal,{})){
                        vm.menu[canal.Nombre] = angular.copy(menuCanal);
                        //Si tiene indicadores le cargo la preview de resultados
                        angular.forEach(vm.menu[canal.Nombre][0], function(m, index){
                            if (m.IdIndicador !== null){
                                angular.forEach(vm.indicadores[canal.Nombre][0], function(indi){
                                    if (indi.info.IdIndicador === m.IdIndicador){
                                        vm.menu[canal.Nombre][0][index] = angular.copy(indi);
                                    }
                                });
                            }
                        });
                        vm.menuPrincipal = angular.copy(vm.menu);
                        //Agrego el nombre del indicador al menuPrincipal
                        angular.forEach(vm.menuPrincipal[canal.Nombre], function(menuAgrupado,index1){
                            angular.forEach(menuAgrupado, function(menu, index2){
                                if (menu.IdIndicador !== null){
                                    angular.forEach(vm.indicadores[canal.Nombre], function(indiAgrupado){
                                        angular.forEach(indiAgrupado, function(indi){
                                            if (menu.IdIndicador === indi.info.IdIndicador){
                                                vm.menuPrincipal[canal.Nombre][index1][index2]["NombreMenu"] = indi.info.NombreIndicador;
                                            }
                                        });
                                    });
                                }
                            })
                        });

                        // console.log('vm.menuPrincipal',vm.menuPrincipal);
                        // console.log('vm.menu',vm.menu);
                    }
                });
            });
            return promise;
        }

        function returnMenu(menuActual, canalActual){
            var idMenuPadre,
                idMenu,
                menuCanal = {};
            idMenuPadre = menuActual.IdMenuPadre;
            menuCanal = vm.menu[canalActual];
            //Busco el menu padre
            angular.forEach(menuCanal, function(menuInterno){
                angular.forEach(menuInterno, function(indi){
                    if (indi.IdMenu !== undefined && indi.IdMenu !== null){
                        if(indi.IdMenu === idMenuPadre){
                            idMenu = indi.IdMenuPadre === null ? 0 : indi.IdMenuPadre;
                        }
                    }
                });
            });
            //Si tiene indicadores les cargo la preview de resultados
            angular.forEach(menuCanal[idMenu], function(m, index){
                if (m.IdIndicador !== null){
                    angular.forEach(vm.indicadores[canalActual][idMenu], function(indi){
                        if (indi.info.IdIndicador === m.IdIndicador){
                            menuCanal[idMenu][index] = angular.copy(indi);
                        }
                    });
                }
            });
            vm.menuElegido[canalActual] = angular.copy(menuCanal[idMenu]);
            //Elimino del crumbMenu el menu y submenus elegido
            angular.forEach(vm.crumbMenu[canalActual], function(crumb, index){
                if (crumb.IdMenu === idMenuPadre){
                    vm.crumbMenu[canalActual].splice(index);
                }
            });
        }

        // function addNameMenu(indi){
        //     var resolvePromise,
        //     promise = new Promise(function (resolve) {
        //         resolvePromise = resolve;
        //     });
        //     $http.get('api/menuTablero/' + indi.IdMenu).then(function (response) {
        //         const menuData = response.data;
        //         indi['NombreMenu'] = menuData.Titulo;
        //         indi['Filtros'] = menuData.Filtro;
        //         resolvePromise();
        //     });
        //     return promise;
        // }

        function loadIndicador(canal, indicadorEntry) {
            var indicador = { canal: canal, span: indicadorEntry.Span},
                resolvePromise,
                promise = new Promise(function (resolve) {
                    resolvePromise = resolve;
                });
            const dataIndicador = {
                "idProyecto": "" + vm.proyectoId,
                "idUsuario": "" + vm.usuarioId,
                "nombrecanal": "" + canal.Nombre,
                "idIndicador": "" + indicadorEntry.IdIndicador,
                "idMenuPadre": "" + indicadorEntry.IdMenuPadre,
                "fil": cargarFiltros(),
                "fechadesde": ( vm.fechas.fechadesde ? vm.fechas.fechadesde : lastMonthString ),
                "fechahasta": vm.fechas.fechahasta,
                "vision": []
            };
            console.log(dataIndicador)
            $http.get('api/indicadores/indicador/' + indicadorEntry.IdIndicador).then(function (response) {
                indicador.info = response.data;
                indicador.IdMenuPadre = indicadorEntry.IdMenuPadre === null ? 0 : indicadorEntry.IdMenuPadre;
                if (indicador.info.IdPerfectStore) {
                    var dataPerfectStore = angular.copy(dataIndicador);
                    dataPerfectStore.idIndicador = indicador.info.IdPerfectStore;
                    $http.post('api/resultado/perfectStore/multipleKpi', dataPerfectStore).then(function (response) {
                       console.log('')
                        indicador.isPerfectStore = true;
                        indicador.resultado = response.data[0];
                        indicador.secundarias = response.data.slice(1);
                        if (!vm.indicadores[canal.Nombre]) vm.indicadores[canal.Nombre] = {};
                        if (!vm.indicadores[canal.Nombre][indicador.IdMenuPadre]) vm.indicadores[canal.Nombre][indicador.IdMenuPadre] = {};
                        vm.indicadores[canal.Nombre][indicador.IdMenuPadre][indicadorEntry.Orden] = indicador;
                        resolvePromise();
                    });
                }
                else if (indicador.info.IdMedidaIndicador === 1068){
                    indicador.resultado = {};
                    indicador.secundarias = {};
                    if (indicadorEntry.IdMenuPadre != null){
                        var MenuPadre = [];
                        $http.get('api/indicadorTablero/' + indicadorEntry.IdIndicador + '/' + indicadorEntry.IdMenuPadre).then(function (response) {
                            const resTablero = response.data;
                            angular.forEach(resTablero, function(tablero) {
                                angular.forEach(vm.menuTablero, function (titulo, key){
                                    if (tablero.IdMenu == key){
                                        MenuPadre.unshift(titulo);
                                        indicador.info["MenuPadre"] = MenuPadre;
                                    }
                                });
                            });
                            if (!vm.indicadores[canal.Nombre]) vm.indicadores[canal.Nombre] = {};
                            if (!vm.indicadores[canal.Nombre][indicador.IdMenuPadre]) vm.indicadores[canal.Nombre][indicador.IdMenuPadre] = {};
                            vm.indicadores[canal.Nombre][indicador.IdMenuPadre][indicadorEntry.Orden] = indicador;
                            resolvePromise();
                        });
                    }
                    else {
                        if (!vm.indicadores[canal.Nombre]) vm.indicadores[canal.Nombre] = {};
                        if (!vm.indicadores[canal.Nombre][indicador.IdMenuPadre]) vm.indicadores[canal.Nombre][indicador.IdMenuPadre] = {};
                        vm.indicadores[canal.Nombre][indicador.IdMenuPadre][indicadorEntry.Orden] = indicador;
                        resolvePromise();
                    }
                }
                else {
                    $http.post('api/resultado/indicadores', dataIndicador).then(function (response) {
                        indicador.resultado = response.data[0];
                        indicador.secundarias = [];
                        $http.get('api/medidas/secundarias/' + indicadorEntry.IdIndicador).then(function (response) {
                            angular.forEach(response.data, function (secundaria) {
                                const dataSecu = {
                                    "medidaSecundariaId": secundaria.IdMedidaSecundaria,
                                    "idProyecto": "" + vm.proyectoId,
                                    "idUsuario": "" + vm.usuarioId,
                                    "nombrecanal": "" + canal.Nombre,
                                    "idIndicador": "" + indicadorEntry.IdIndicador,
                                    "idMenuPadre": "" + indicadorEntry.IdMenuPadre,
                                    "fil": [],
                                    "fechadesde": ( vm.fechas.fechadesde ? vm.fechas.fechadesde : lastMonthString ),
                                    "fechahasta": vm.fechas.fechahasta,
                                    "vision": [],
                                };
                                $http.post('api/resultado/secundario', dataSecu).then(function (response) {
                                    angular.forEach(response.data, function (obj, index) {
                                        obj.Medida = angular.copy(secundaria);
                                        indicador.secundarias.push(obj);
                                    });
                                    if (indicadorEntry.IdMenuPadre != null){
                                        var MenuPadre = [];
                                        $http.get('api/indicadorTablero/' + indicadorEntry.IdIndicador + '/' + indicadorEntry.IdMenuPadre).then(function (response) {
                                            const resTablero = response.data;
                                            angular.forEach(resTablero, function(tablero) {
                                                angular.forEach(vm.menuTablero, function (titulo, key){
                                                    if (tablero.IdMenu == key){
                                                        MenuPadre.unshift(titulo);
                                                        indicador.info["MenuPadre"] = MenuPadre;
                                                    }
                                                });
                                                // $http.get('api/menuTablero/' + tablero.IdMenu).then(function (response) {
                                                //     const resMenu = response.data;
                                                //     MenuPadre.unshift(resMenu.Titulo);
                                                //     indicador.info["MenuPadre"] = MenuPadre;
                                                // });
                                            });
                                            if (!vm.indicadores[canal.Nombre]) vm.indicadores[canal.Nombre] = {};
                                            if (!vm.indicadores[canal.Nombre][indicador.IdMenuPadre]) vm.indicadores[canal.Nombre][indicador.IdMenuPadre] = {};
                                            vm.indicadores[canal.Nombre][indicador.IdMenuPadre][indicadorEntry.Orden] = indicador;
                                            resolvePromise();
                                        });
                                    }
                                    else {
                                        if (!vm.indicadores[canal.Nombre]) vm.indicadores[canal.Nombre] = {};
                                        if (!vm.indicadores[canal.Nombre][indicador.IdMenuPadre]) vm.indicadores[canal.Nombre][indicador.IdMenuPadre] = {};
                                        vm.indicadores[canal.Nombre][indicador.IdMenuPadre][indicadorEntry.Orden] = indicador;
                                        resolvePromise();
                                    }
                                });
                            });
                        });
                    });
                }
            });
            return promise;
        }

        function setOpcion(opcion,canal) {
            if (opcion !== undefined && canal !== undefined){
                vm.menuElegido[canal.Nombre] = {};
                if (opcion.IdIndicador === null && opcion.IdMenu !== null){
                    vm.menuElegido[canal.Nombre] = angular.copy(vm.menu[canal.Nombre][opcion.IdMenu]);
                    angular.forEach(vm.menu[canal.Nombre][opcion.IdMenu], function(op, index){
                        if (op.IdIndicador !== null){
                            angular.forEach(vm.indicadores[canal.Nombre][opcion.IdMenu], function(indi){
                                if (indi.info.IdIndicador === op.IdIndicador){
                                    vm.menuElegido[canal.Nombre][index] = angular.copy(indi);
                                }
                            });
                        }
                    });
                    vm.hideMenu = true;
                    if (!vm.crumbMenu[canal.Nombre]) vm.crumbMenu[canal.Nombre] = [];
                    vm.crumbMenu[canal.Nombre].push(opcion);
                }
                else{
                    vm.hideMenu = false;
                    vm.crumbMenu[canal.Nombre] = [];
                    opcion.info["IdMenuPadre"] = opcion.IdMenuPadre;
                    vm.indicadorCanal = { canal: opcion.canal, indicador: opcion.info }
                }
            }
        }

        function mostrarFiltros(canalNombre) {
            var canal = vm.canales.filter(function (canal) { return canal.Nombre == canalNombre })[0];
            $uibModal.open({
                templateUrl: 'app/components/tablero/filtros.html',
                controller: 'FiltrosTableroController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    filtros: function () { return vm.filtros },
                    proyectoId: function () { return vm.proyectoId },
                    canalElegido: function () { return canalNombre },
                    fechas: function () { return vm.fechas },
                    indicadores: function () { return vm.indicadores[canalNombre] }
                }
            }).result.then(function (result) {
                vm.filtros = result.filtros;
                vm.fechas = result.fechas;
                loadCanal(canal).then(function () {
                    vm.loaded[canal.Nombre] = true;
                });
            });
        }

        function cargarFiltros() {
            vm.arregloFiltros = [];
            var valueFilterGeo = vm.filtros.Geografia;
            var colFilterGeo = vm.filtros.ColumnaGeografia;
            var valueFilterDis = vm.filtros.Distribucion;
            var colFilterDis = vm.filtros.ColumnaDistribucion;
            var valueAdicional = vm.filtros.Adicionales;

            if (!vm.filtros.Geografia) {
                valueFilterGeo = "null";
                colFilterGeo = "null";
            }
            if (!vm.filtros.Distribucion) {
                valueFilterDis = "null";
                colFilterDis = "null";
            }
            if (!vm.filtros.Adicionales) {
                valueAdicional = "null";
            }

            if (valueFilterGeo !== "null") {
                angular.forEach(valueFilterGeo, function (item) {
                    vm.arregloFiltros.push({
                        valorFiltroGeografia: item.valor.toString(),
                        columnaFiltroGeografia: item.columna.toString(),
                        valorFiltroDistribucion: "null",
                        columnaFiltroDistribucion: "null",
                        table: item.table.toString()
                    })
                });
            }
            if (valueFilterDis !== "null") {
                angular.forEach(valueFilterDis, function (item) {
                    vm.arregloFiltros.push({
                        valorFiltroDistribucion: item.valor.toString(),
                        columnaFiltroDistribucion: item.columna.toString(),
                        valorFiltroGeografia: "null",
                        columnaFiltroGeografia: "null",
                        table: item.table.toString()
                    })
                });
            }
            if (valueAdicional !== "null") {
                angular.forEach(valueAdicional, function (item) {
                    var geografia = false;
                    angular.forEach(vm.filtrosvisiones.m_Item2[0].VisionValues, function (filtro) {
                        if (filtro.OptionValue === item.columna) {
                            geografia = true;
                        }
                    });
                    if (geografia) {
                        vm.arregloFiltros.push({
                            valorFiltroGeografia: item.valor.toString(),
                            columnaFiltroGeografia: item.columna.toString(),
                            valorFiltroDistribucion: "null",
                            columnaFiltroDistribucion: "null",
                            table: item.table.toString()
                        })
                    }
                    else {
                        vm.arregloFiltros.push({
                            valorFiltroGeografia: "null",
                            columnaFiltroGeografia: "null",
                            valorFiltroDistribucion: item.valor.toString(),
                            columnaFiltroDistribucion: item.columna.toString(),
                            table: item.table.toString()
                        })
                    }
                });
            }
            if(vm.arregloFiltros.length == 0) return [{"valorFiltroGeografia":"null","columnaFiltroGeografia":"null","valorFiltroDistribucion":"null","columnaFiltroDistribucion":"null"}];
            return vm.arregloFiltros;
        }

        //Formato pesos para Colombia Precios
        function proyectColombia(medidaIndicador, secu) {
            if (vm.proyectoId == 25 || vm.proyectoId == 60){
                if (medidaIndicador.info.IdMedidaIndicador == 1033 && secu.Medida.IdMedidaSecundaria == 36){
                    vm.valor = parseFloat(secu.resultado).format(2, 3, '.', ',');
                    return true;
                }
            }
            return false;
        }
        Number.prototype.format = function(n, x, s, c) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = this.toFixed(Math.max(0, ~~n));
            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        };
    }
})();
