(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('evidencias', {
            templateUrl: 'app/components/evidencias/evidencias.html',
            controller: 'EvidenciasController',
            controllerAs: 'vm',
            bindings: {
                resultadoEvidenciasIndicador: '=',
                proyectoId: '=',
                mostrarEvidencias: '=',
                cargandoEvidencias: '=',
                canalElegido: '=',
                idIndicadorElegido: '=',
                filtrosElegidos: '=',
                fechasElegidas: '=',
                filtrosvisiones: '=',
                nombreMenuPadre: '='
            }
        });
})();
