(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('EvidenciasController', EvidenciasController);

    EvidenciasController.$inject = ['$http', '$timeout', 'showResult', 'Principal'];

    function EvidenciasController($http, $timeout, showResult, Principal) {
        var vm = this;
        vm.pagina = 1;
        vm.arrayPrev = [];
        vm.arrayRes = [];
        vm.descargando = false;
        vm.sumarPagina = sumarPagina;
        vm.changeValue = changeValue;
        vm.onCarouselAfterChange = onCarouselAfterChange;
        vm.movementCarousel = movementCarousel;
        vm.cargarEvidencias = cargarEvidencias;
        vm.descargarImagenes = descargarImagenes;
        vm.currentSlide = 1;
        vm.moveDisabled = true;
        vm.finEvidencias = false;
        vm.usuarioId = Principal.identity().$$state.value.Id; 

        (function() {
            var listEl = document.querySelector('.home-grid.products-grid.products-grid--max-4');
            var btnLeftEl = document.querySelector('#left-btn');
            var btnRightEl = document.querySelector('#right-btn');
            vm.count = 0;
        
            function slideImages(dir) {
                var totalChildren = listEl.querySelectorAll(".item").length;
                var widthClassItem = document.getElementsByClassName('item-container')[0].offsetWidth;
                dir === "left" ? ++vm.count : --vm.count;
                listEl.style.left = vm.count * widthClassItem + 'px';
                btnLeftEl.style.display = vm.count < 0 ? "block" : "none";
                // Here, 4 is the number displayed at any given time
                btnRightEl.style.display = vm.count > 4-totalChildren ? "block" : "none";
            }

            btnLeftEl.addEventListener("click", function(e) {
                slideImages("left");
            });
            btnRightEl.addEventListener("click", function(e) {
                slideImages("right");
            });
        })();

        function movementCarousel(mov) {
            document.getElementById(vm.currentSlide).classList.remove('active');
            if(mov == true) {
                vm.currentSlide = vm.currentSlide + 1;
                document.getElementById('right-btn').disabled = true;
                $timeout(function () {
                    document.getElementById('right-btn').disabled = false;
                    onCarouselAfterChange(vm.currentSlide);
                }, 650);              
            }
            else {
                vm.currentSlide = vm.currentSlide - 1;
                document.getElementById('left-btn').disabled = true;
                $timeout(function () {
                    document.getElementById('left-btn').disabled = false;
                }, 650);
            }
            document.getElementById(vm.currentSlide).classList.add('active');
            return vm.currentSlide;          
        }

        showResult.subscribe(function(x) {
            if (x == "cargaEvidencias") {
                changeValue();
            }
        });
        
        function changeValue() {
            vm.currentSlide = 1;
            vm.pagina = 1;
            vm.arrayPrev = [];
            vm.arrayRes = [];
            vm.finEvidencias = false;
            disableRightBtn();
            $timeout(function () {
                document.getElementById(vm.currentSlide).classList.remove('active');
            }, 600);
            primeraCarga();
            sumarPagina();
            $timeout(function () {
                document.getElementById(1).classList.add('active');
            }, 600);
            recargaEvidenciasElementosHtml();
        }

        function descargarImagenes(){
            vm.descargando = true;
            var dataEvidencias = {
                "idProyecto": "" + vm.proyectoId,
                "idUsuario": "" + vm.usuarioId,
                "nombrecanal": "" + vm.canalElegido,
                "idIndicador": "" + vm.idIndicadorElegido,
                "fil": vm.arregloFiltros,
                "fechadesde": "" + vm.fechasElegidas.FechaInicio,
                "fechahasta": "" + vm.fechasElegidas.FechaFin,
                "vision": [],
                "nombreMenuPadre": "" + vm.nombreMenuPadre
            };
            $http.post('api/evidencias/descarga', dataEvidencias, { responseType: 'arraybuffer' }).then(function (response) {
                var newDate = new Date();
                var date = "" + newDate.getDate()+ "-" + (newDate.getMonth() + 1) + "-" + newDate.getFullYear() + "-" + newDate.getHours() + "-" + newDate.getMinutes();
                var file = new Blob([response.data], { type: 'application/zip' });
                saveAs(file, 'imagenes-dashboard-'+date+'.zip');
                vm.descargando = false;
            });
        }

        function recargaEvidenciasElementosHtml() {
            vm.count = 0;
            var btnLeftEl = document.querySelector('#left-btn');
            var btnRightEl = document.querySelector('#right-btn');
            var listEl = document.querySelector('.home-grid.products-grid.products-grid--max-4');
            listEl.style.left = vm.count + 'px';
            btnLeftEl.style.display = vm.count < 0 ? "block" : "none";
        }

        function onCarouselAfterChange(indexAfter) {
            if (vm.arrayPrev.length === 0 && vm.finEvidencias == false){
                disableRightBtn();       
                sumarPagina();    
                vm.arrayPrev = vm.arrayRes;                   
            }
            if (vm.arrayPrev[0] != undefined){             
                vm.resultadoEvidenciasIndicador.Content.push(vm.arrayPrev[0]);
                vm.arrayPrev.splice(0, 1);
            }
            else{
                vm.finEvidencias = true;
            }           
        }

        function cargarEvidencias(){
            var msg = "cargarEvidencias";
            showResult.sendMessage(msg);
        }

        function primeraCarga() {
            vm.pagina++;
            vm.arregloFiltros = [];
            cargarFiltros();
            var dataEvidencias = {
                "page": vm.pagina,
                "idProyecto": "" + vm.proyectoId,
                "idUsuario": "" + vm.usuarioId,
                "nombrecanal": "" + vm.canalElegido,
                "idIndicador": "" + vm.idIndicadorElegido,
                "fil": vm.arregloFiltros,
                "fechadesde": "" + vm.fechasElegidas.FechaInicio,
                "fechahasta": "" + vm.fechasElegidas.FechaFin,
                "vision": [],
                "idMenuPadre": "" + 0,
                "nombreMenuPadre": "" + vm.nombreMenuPadre
            };
            $http.post('api/evidencias', dataEvidencias).then(function (response) {
                vm.arrayPrev = response.data.Content;
            });
        }

        function sumarPagina(){
            vm.pagina++;
            var dataEvidencias = {
                    "page": vm.pagina,
                    "idProyecto": "" + vm.proyectoId,
                    "idUsuario": "" + vm.usuarioId,
                    "nombrecanal": "" + vm.canalElegido,
                    "idIndicador": "" + vm.idIndicadorElegido,
                    "fil": vm.arregloFiltros,
                    "fechadesde": "" + vm.fechasElegidas.FechaInicio,
                    "fechahasta": "" + vm.fechasElegidas.FechaFin,
                    "vision": [],
                    "idMenuPadre": "" + 0,
                    "nombreMenuPadre": "" + vm.nombreMenuPadre
            };
            $http.post('api/evidencias', dataEvidencias).then(function (response) {
                vm.arrayRes = response.data.Content;
                enableRightBtn();            
            });
        }

        function enableRightBtn(){
            document.getElementById('right-btn').disabled = false;
            document.getElementById('right-btn').style.color= "#000000";
        }

        function disableRightBtn(){
            document.getElementById('right-btn').disabled = true;
            document.getElementById('right-btn').style.color= "#c5c2c2";  
        }

        function cargarFiltros() {
            var valueFilterGeo = vm.filtrosElegidos.Geografia;
            var colFilterGeo = vm.filtrosElegidos.ColumnaGeografia;
            var valueFilterDis = vm.filtrosElegidos.Distribucion;
            var colFilterDis = vm.filtrosElegidos.ColumnaDistribucion;
            var valueAdicional = vm.filtrosElegidos.Adicionales;

            if (vm.filtrosElegidos.Geografia === '') {
                valueFilterGeo = "null";
                colFilterGeo = "null";
            }
            if (vm.filtrosElegidos.Distribucion === '') {
                valueFilterDis = "null";
                colFilterDis = "null";
            }
            if (vm.filtrosElegidos.Adicionales === '') {
                valueAdicional = "null";
            }

            if (valueFilterGeo !== "null") {
                angular.forEach(valueFilterGeo, function (item) {
                    vm.arregloFiltros.push({
                        valorFiltroGeografia: item.valor.toString(),
                        columnaFiltroGeografia: item.columna.toString(),
                        valorFiltroDistribucion: "null",
                        columnaFiltroDistribucion: "null",
                        table: item.table.toString()
                    })
                });
            }
            if (valueFilterDis !== "null") {
                angular.forEach(valueFilterDis, function (item) {
                    vm.arregloFiltros.push({
                        valorFiltroDistribucion: item.valor.toString(),
                        columnaFiltroDistribucion: item.columna.toString(),
                        valorFiltroGeografia: "null",
                        columnaFiltroGeografia: "null",
                        table: item.table.toString()
                    })
                });
            }
            if (valueAdicional !== "null") {     
                var listaGeo = ["NombrePais","AreaNielsen","NombreEstado","NombreUbicacion"]; 
                var listaFecha = ["Año","Mes","Quarter","Dia"];    
                angular.forEach(valueAdicional, function (item) {
                    if (listaFecha.indexOf(item.columna.toString()) > -1 ){
                        vm.arregloFiltros.push({
                            valorFiltroGeografia: "null",
                            columnaFiltroGeografia: "null",
                            valorFiltroDistribucion: item.valor.toString(),
                            columnaFiltroDistribucion: item.columna.toString(),
                            table: "RespuestaEncuesta"
                        })
                    }
                    else if (listaGeo.indexOf(item.columna.toString()) > -1 ){
                        vm.arregloFiltros.push({
                            valorFiltroGeografia: item.valor.toString(),
                            columnaFiltroGeografia: item.columna.toString(),
                            valorFiltroDistribucion: "null",
                            columnaFiltroDistribucion: "null",
                            table: item.table.toString()
                        })
                    }
                    else{
                        vm.arregloFiltros.push({
                            valorFiltroGeografia: "null",
                            columnaFiltroGeografia: "null",
                            valorFiltroDistribucion: item.valor.toString(),
                            columnaFiltroDistribucion: item.columna.toString(),
                            table: item.table.toString()
                        })
                    }
                });
            }
        }
    }
})();
