(function () {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('EviCarouselController', EviCarouselController);

    EviCarouselController.$inject = ['$scope', '$http', '$timeout', 'showResult', '$element'];

    function EviCarouselController($scope, $http, $timeout, showResult,$element) {
        var vm = this;
        vm.slide = 0;
        vm.nextSlide = function(){
            vm.slide++;
            if(vm.slide == vm.items.length) vm.slide = 0;
        }
        vm.prevSlide = function(){
            vm.slide--;
            if(vm.slide == -1) vm.slide = vm.items.length-1;
        }
        vm.close = function(){
            vm.info = false;
            vm.carHidden = true;
        }
        vm.timeout;
        vm.hoverTimeout = 2000;
        vm.hideHideables = false;
        vm.mouseOver = true;
        vm.showHidables = function(){
            vm.hideHideables = false;
            $timeout.cancel(vm.timeout);
            vm.timeout = $timeout(function(){
                if(vm.mouseOver) vm.hideHideables = true;
            },vm.hoverTimeout);
        }
        $timeout(function(){
            if(vm.mouseOver) vm.hideHideables = true;
        },vm.hoverTimeout)
        vm.enableMouseOver = function(){
            vm.mouseOver = true;
        }        
        vm.disableMouseOver = function(){
            vm.mouseOver = false;
        }
        vm.keypress = function(event){
            if(event.key == "ArrowLeft") vm.prevSlide();
            if(event.key == "ArrowRight") vm.nextSlide();
        }
    }
})();
