(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .factory('PasswordResetInit', PasswordResetInit);

    PasswordResetInit.$inject = ['$http'];

    function PasswordResetInit($http) {
        return {
            save:save,
            getResetToken : getResetToken
        };

        function save(mail) {
            var user = { Email: mail};
            return $http.post('api/account/reset-password/token', user);
        }

        function getResetToken(user) {
            return $http.post('api/account/reset-password/token', user);
        }
    }
})();
