(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('tablero', {
            templateUrl: 'app/components/tablero/tablero.html',
            controller: 'TableroController',
            controllerAs: 'vm',
            bindings: {
                proyectoId: '=',
                cliente : '=',
                indicadorCanal: '=',
                menuPrincipal: '='
            }
        });
})();
