(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('resultado', {
            templateUrl: 'app/components/resultado/resultado.html',
            controller: 'ResultadoController',
            controllerAs: 'vm',
            bindings: {
                resultadoIndicador: '=',
                showdashboardResultado: '=',
                filtrosResultado: '=',
                resultadoTotal: '=',
                mostrar: '='
            }
        });
})();
