(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('CadenaController', CadenaController);

    CadenaController.$inject = ['$http', '$scope','$filter','$uibModalInstance', 'filterList', '$timeout'];

    function CadenaController ($http, $scope, $filter,$uibModalInstance, filterList, $timeout) {
        var vm = this;
        vm.proyectoId = filterList.proyectoId;
        vm.usuarioId = filterList.idUsuario;
        vm.canalElegido = filterList.nombreCanal;
        vm.idIndicador = filterList.idIndicador;
        vm.arregloFiltros = [];//filterList.arregloFiltros;
        vm.fechasElegidas = {FechaInicio: 'null', FechaFin: 'null'};
        vm.cadena = {};
        vm.cadenaSeleccionada = '';
        vm.distribucionSeleccionada = {valor: '', columna: ''};
        vm.disabledButtonCadena = true;
        vm.mostrarAdvertencia = false;
        vm.cargando = false;
        vm.mostrarLista = [];
        vm.disabledButtonGeografia = [];

        vm.filtro = '';
        vm.filtroElegido = [];
        vm.filterList = filterList.filtros;
        vm.seleccionado = [];//filterList.select;
        vm.datosTabla = angular.copy(filterList.filtros);
        vm.seleccionoValor = seleccionoValor;
        vm.mostrarListaClic = mostrarListaClic;
        vm.aceptarCadena = aceptarCadena;
        vm.aplicarFiltro = aplicarFiltro;
        vm.busqueda = busqueda;
        vm.cargarSeleccionados = cargarSeleccionados;

        vm.seleccionarTodos = seleccionarTodos;
        vm.seleccionoTodos = false;
        vm.SeleccionarTodosText = "Seleccionar Todos";

        function regex(datos) {
            var myRe = new RegExp('##[0-9]*##','g');
            return datos.replace(myRe,'');
        }

        $timeout(function () {
            cargarSeleccionados();
            validarArreglo();
        }, 5);

        function aceptarCadena() {
            $uibModalInstance.close(vm.filtroElegido);
        }

        function aplicarFiltro(indice) {
            var indiceSiguiente = indice + 1;

            //obtener seleccionado y guardar en memoria
            vm.arregloFiltros = [];
            angular.forEach(vm.filtroElegido, function (item) {
                vm.arregloFiltros.push({
                    valorFiltroDistribucion: item.valor.toString(),
                    columnaFiltroDistribucion: item.columna.toString(),
                    valorFiltroGeografia: "null",
                    columnaFiltroGeografia: "null",
                    table: item.table.toString()
                })
            });

            if(indice == vm.mostrarLista.length - 1)
            {
                aceptarCadena();
            }
            else{
                mostrarListaClic(indiceSiguiente);
            }
            

        }
        

        function validarArreglo() {
            var vacio = true;
            angular.forEach(vm.filterList.Group, function (obj) {
                if(vacio){
                    if(obj.ValueFilter.length > 0){
                        vacio = false;
                    }
                }
            });
            vm.mostrarAdvertencia = vacio;
        }

        function cargarSeleccionados() {
            angular.forEach(vm.seleccionado, function (seleccionado) {
                if(seleccionado.tipo === vm.filterList.SelectFilter){
                    vm.filtroElegido.push(seleccionado);
                    angular.forEach(vm.datosTabla.Group, function (filtros,pindex) {
                        if(seleccionado.columna === filtros.GroupFilter){
                            angular.forEach(filtros.ValueFilter, function (filtro,index){
                                if(seleccionado.valor === filtro.OptionText){
                                    document.getElementById('radioB'+ pindex + '-' +  index ).checked = true;
                                }
                            });
                        }
                    });
                }
            })
        }

        angular.forEach(vm.filterList.Group, function () {
            vm.mostrarLista.push(false);
            vm.disabledButtonGeografia.push(true);
        });

        function mostrarListaClic(indice) {
            vm.seleccionoTodos = true;
            vm.seleccionarTodos(indice);

            var descripcionFiltro = vm.datosTabla.Group[indice].GroupFilter;
            //variable que se envia a la api
            var dataFiltros = {
                "idProyecto": "" + vm.proyectoId,
                "idUsuario": "" + vm.usuarioId,
                "nombrecanal": "" + vm.canalElegido,
                "idIndicador": "" + vm.idIndicador,
                "fil": [],
                "fechadesde": "" + vm.fechasElegidas.FechaInicio,
                "fechahasta": "" + vm.fechasElegidas.FechaFin,
                "vision": [],
                "nombreMenuPadre": ""
            };

            
            
            if (vm.filterList.SelectFilter == "Cadena" || vm.filterList.SelectFilter == "Productos" ||
                vm.filterList.SelectFilter == "Distribución" ||  
                vm.filterList.SelectFilter == "Marcas" ){
                vm.cargando =  true;

                //limpia filtros: en algunos casos no se deben limpiar para mantener los que ya vienen
                if(vm.filterList.SelectFilter == "Distribución"){

                    if(descripcionFiltro == "RutaOperativa"){

                    }
                    else{
                        vm.filterList.Group[indice].ValueFilter = [];
                        vm.datosTabla.Group[indice].ValueFilter = [];
                    }
                    
                    
                }
                else{
                    vm.filterList.Group[indice].ValueFilter = [];
                    vm.datosTabla.Group[indice].ValueFilter = [];
                }
                

                //obtener Cadenas
                if (vm.filterList.SelectFilter == "Cadena") {
                    if (descripcionFiltro === "NombreCadena") {
                        dataFiltros.nombreMenuPadre = "Cadena";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'NombreCadena');

                        angular.forEach(vm.arregloFiltros, function (obj) {
                            dataFiltros.fil.push(obj);
                        });
                    }

                    if (descripcionFiltro === "FormatoNegocio") {
                        dataFiltros.nombreMenuPadre = "FormatoCadena";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'NombreCadena');

                        angular.forEach(vm.arregloFiltros, function (obj) {
                            if(obj.columnaFiltroDistribucion === 'NombreCadena' ){
                                dataFiltros.fil.push(obj);
                            }
                        });
                    }

                    if (descripcionFiltro === "NombreNegocio") {
                        dataFiltros.nombreMenuPadre = "Tienda";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'FormatoNegocio');
                        angular.forEach(vm.arregloFiltros, function (obj) {
                            if(obj.columnaFiltroDistribucion === 'NombreCadena' ||  obj.columnaFiltroDistribucion === 'FormatoNegocio'){
                                dataFiltros.fil.push(obj);
                            }
                        });
                    }

                    if (vm.filterList.SelectFilter == "Cadena") {
                        $http.post('api/filtros-visiones/cadenas', dataFiltros).then(function (response) {
                            vm.filterList.Group[indice].ValueFilter = response.data;
                            vm.datosTabla.Group[indice].ValueFilter = response.data;
                            vm.cargando = false;
                        });

                        
                    }
                } 
                
                //obtener Productos
                if (vm.filterList.SelectFilter == "Productos") {
                    //obtener cadenas
                    if (descripcionFiltro == "Empresa") {
                        dataFiltros.nombreMenuPadre = "Empresa";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'NombreCadena');

                        angular.forEach(vm.arregloFiltros, function (obj) {
                            dataFiltros.fil.push(obj);
                        });
                    }

                    if (descripcionFiltro == "Marca") {
                        dataFiltros.nombreMenuPadre = "Marca";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'NombreCadena');

                        angular.forEach(vm.arregloFiltros, function (obj) {
                            dataFiltros.fil.push(obj);
                        });
                    }

                    if (descripcionFiltro == "DescripcionProducto") {
                        dataFiltros.nombreMenuPadre = "Producto";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'FormatoNegocio');
                        angular.forEach(vm.arregloFiltros, function (obj) {
                            dataFiltros.fil.push(obj);
                        });
                    }

                    if (descripcionFiltro === "PresentacionProducto") {
                        dataFiltros.nombreMenuPadre = "PresentacionProducto";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'FormatoNegocio');
                        angular.forEach(vm.arregloFiltros, function (obj) {
                            dataFiltros.fil.push(obj);
                        });
                    }

                    if (descripcionFiltro === "CapacidadProducto") {
                        dataFiltros.nombreMenuPadre = "CapacidadProducto";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'FormatoNegocio');
                        angular.forEach(vm.arregloFiltros, function (obj) {
                            dataFiltros.fil.push(obj);
                        });
                    }


                    $http.post('api/filtros-visiones/cadenas', dataFiltros).then(function (response) {
                        vm.filterList.Group[indice].ValueFilter = response.data;
                        vm.datosTabla.Group[indice].ValueFilter = response.data;
                        vm.cargando = false;
                    });
                }

                //obtener Distibuidores
                if (vm.filterList.SelectFilter == "Distribución"){

                    if (descripcionFiltro === "NombreCadena") {
                        dataFiltros.nombreMenuPadre = "Cadena";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'NombreCadena');

                    }

                    if (descripcionFiltro === "NombreDistribuidor") {
                        dataFiltros.nombreMenuPadre = "Distribuidor";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'NombreCadena');

                    }

                    if (descripcionFiltro === "NombreSupervisor") {
                        dataFiltros.nombreMenuPadre = "Supervisor";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'NombreCadena');

                    }

                    if (descripcionFiltro === "RutaComercial") {
                        dataFiltros.nombreMenuPadre = "RutaComercial";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'NombreCadena');
                    }

                    if (descripcionFiltro === "Region") {
                        dataFiltros.nombreMenuPadre = "Region";
                        //dataFiltros.fil = vm.arregloFiltros.filter((a)=> a.columnaFiltroDistribucion === 'NombreCadena');
                    }
                    

                    angular.forEach(vm.arregloFiltros, function (obj) {
                        dataFiltros.fil.push(obj);
                    });

                    //no recargar ruta operativa
                    if(descripcionFiltro != "RutaOperativa"){
                        $http.post('api/filtros-visiones/cadenas', dataFiltros).then(function (response) {
                            vm.filterList.Group[indice].ValueFilter = response.data;
                            vm.datosTabla.Group[indice].ValueFilter = response.data;
                            vm.cargando = false;
                        });
                    }
                    else{
                        vm.cargando = false;
                    }
                    
                }

                //obtener Tipo
                // if (vm.filterList.SelectFilter == "Tipo"){

                //     if (descripcionFiltro === "ClusterNegocio") {
                //         dataFiltros.nombreMenuPadre = "ClusterTienda";
                //     }

                //     if (descripcionFiltro === "DepositoNegocio") {
                //         dataFiltros.nombreMenuPadre = "DepositoTienda";
                //     }

                //     if (descripcionFiltro === "TipoNegocio") {
                //         dataFiltros.nombreMenuPadre = "TipoTienda";
                //     }

                //     angular.forEach(vm.arregloFiltros, function (obj) {
                //         dataFiltros.fil.push(obj);
                //     });

                //     $http.post('api/filtros-visiones/cadenas', dataFiltros).then(function (response) {
                //         vm.filterList.Group[indice].ValueFilter = response.data;
                //         vm.datosTabla.Group[indice].ValueFilter = response.data;
                //         vm.cargando = false;
                //     });
                    
                // }

                if(vm.filterList.SelectFilter == "Tamaño"){
                    if (descripcionFiltro === "TamanoNegocio") {
                        dataFiltros.nombreMenuPadre = "TamanoNegocio";
                    }

                    $http.post('api/filtros-visiones/cadenas', dataFiltros).then(function (response) {
                        vm.filterList.Group[indice].ValueFilter = response.data;
                        vm.datosTabla.Group[indice].ValueFilter = response.data;
                        vm.cargando = false;
                    });
                }

                if(vm.filterList.SelectFilter == "Categorías de Producto"){
                    if (descripcionFiltro === "CategoriaProducto" || descripcionFiltro === "Descripcion") {
                        dataFiltros.nombreMenuPadre = "CategoriaProducto";
                    }

                    $http.post('api/filtros-visiones/cadenas', dataFiltros).then(function (response) {
                        vm.filterList.Group[indice].ValueFilter = response.data;
                        vm.datosTabla.Group[indice].ValueFilter = response.data;
                        vm.cargando = false;
                    });
                }

                if(vm.filterList.SelectFilter == "Marcas"){
                    if (descripcionFiltro === "Empresa") {
                        dataFiltros.nombreMenuPadre = "Empresa";
                    }

                    if (descripcionFiltro === "Marca" || descripcionFiltro === "Descripcion") {
                        dataFiltros.nombreMenuPadre = "Marca";
                    }

                    angular.forEach(vm.arregloFiltros, function (obj) {
                        dataFiltros.fil.push(obj);
                    });

                    $http.post('api/filtros-visiones/cadenas', dataFiltros).then(function (response) {
                        vm.filterList.Group[indice].ValueFilter = response.data;
                        vm.datosTabla.Group[indice].ValueFilter = response.data;
                        vm.cargando = false;
                    });
                }
            }
            
            //agrego el campo selected para poder vincularlo al checkbox y luego utilizar seleccionar/deselecccionar todos
            vm.filterList.Group[indice].ValueFilter = vm.filterList.Group[indice].ValueFilter.map(function(item) {
                item.selected = false;
                return item;
            });

            angular.forEach(vm.filterList.Group,function(item, index){
                if(index === indice){
                    vm.mostrarLista[index] = !(vm.mostrarLista[index]);
                }
                else{
                    vm.mostrarLista[index] = false;
                }
            });
        }

        function seleccionoValor(indicePadre, indiceHijo) {
            var row = vm.datosTabla.Group[indicePadre].ValueFilter[indiceHijo];
            var valor = vm.datosTabla.Group[indicePadre].ValueFilter[indiceHijo].OptionText;
            var columna = vm.datosTabla.Group[indicePadre].GroupFilter;
            var valorMostrar = vm.datosTabla.Group[indicePadre].valorMostar;
            var table = vm.datosTabla.Group[indicePadre].Table;
            var filtro = {valor: valor, columna: columna, table: table, valorA: regex(valor)};
            var elimino = false;

            if (vm.filtroElegido === []) {
                vm.disabledButtonCadena = true;
                vm.filtroElegido.push(filtro);
            }
            else {
                vm.filtroElegido.find(function (value, index) {
                    if (value && filtro && value.valor === filtro.valor && value.columna === filtro.columna) {
                        vm.filtroElegido.splice(index, 1);
                        elimino = true;
                        row.selected = false;
                    }
                });
                if (!elimino) {
                    row.selected = true;
                    filtro.valorMostar = valorMostrar;
                    vm.filtroElegido.push(filtro);
                    angular.forEach(vm.filterList.Group,function(item, index){
                        if(index === indicePadre){
                            vm.disabledButtonGeografia[index] = false;
                        }
                        else{
                            vm.disabledButtonGeografia[index] = true;
                        }
                    });
                }
            }
        }

        function busqueda(i) {
            vm.datosTabla.Group[i].ValueFilter = [];
            angular.forEach(vm.filterList.Group[i].ValueFilter, function (elem) {
                if(elem.OptionText.toLowerCase().indexOf(vm.filtro.toLowerCase().trim()) > -1){
                    vm.datosTabla.Group[i].ValueFilter.push(elem);
                }
            });
        }

        function seleccionarTodos(indice){
            vm.seleccionoTodos = !vm.seleccionoTodos;
            if(vm.seleccionoTodos){
                vm.SeleccionarTodosText = "Deseleccionar Todos";
                vm.disabledButtonGeografia[indice] = false;
                angular.forEach(vm.datosTabla.Group[indice].ValueFilter, function(item) {
                    item.selected = true;
                    var valor = item.OptionText;
                    var columna = vm.datosTabla.Group[indice].GroupFilter;
                    var table = vm.datosTabla.Group[indice].Table;
                    var valorMostrar = vm.datosTabla.Group[indice].valorMostar;
                    var filtro = {valor: valor, columna: columna, table: table, valorA: regex(valor)};
                    filtro.valorMostar = valorMostrar;
                    vm.filtroElegido.push(filtro);
                });

            }
            else{
                vm.SeleccionarTodosText = "Seleccionar Todos";
                vm.disabledButtonGeografia[indice] = true;
                angular.forEach(vm.datosTabla.Group[indice].ValueFilter, function(item) {
                    item.selected = false;

                    vm.filtroElegido.find(function(value, index) {
                        if (value && value.valor === item.OptionText && value.columna === vm.datosTabla.Group[indice].GroupFilter) {
                            vm.filtroElegido.splice(index,1);
                        }
                    });
                });

                

            }    
        }
    }
})();
