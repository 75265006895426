(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('mapa', {
            templateUrl: 'app/components/mapa/mapa.html',
            controller: 'MapaController',
            controllerAs: 'vm',
            bindings: {
                resultadoMapaIndicador: '=',
                mostrarMapas: '=',
                cargandoMapas: '=',
                filtrosResultado: '=',
                fechasElegidas: '='
            }
        });
})();
