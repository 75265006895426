(function () {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('EviFiltrosController', EviFiltrosController);

    EviFiltrosController.$inject = ['$scope', '$http', '$timeout', 'showResult','$uibModal' ];

    function EviFiltrosController($scope, $http, $timeout, showResult, $uibModal) {
        var vm = this;
        vm.formatFiltro = function(value){
            if(typeof value == 'string'){
            return value.replace(/##.*##/, '');
            }
            else{
                return value.fechaIni+" - "+value.fechaFin;
            }
        }
        vm.close = function(){
            vm.filtrosHidden = true;
        }
        vm.clean = true;
        vm.geoSelected = {table:undefined,value:undefined};
        $scope.$watch("vm.filtrosSelected",function(){
            if(Object.keys(vm.filtrosSelected).length > 0) {
                vm.clean = false;
            }
            else vm.clean = true;

        },true);
        /*$scope.$watch("vm.filtrosPlain",function(){
            console.log({filtrosPlain:vm.filtrosPlain});

        },true);*/
        vm.removeFiltro = function(filtro){
            delete vm.filtrosSelected[filtro];
        }
        vm.selectFiltro = function(type,table,value, catText){
                if((vm.filtrosSelected[type] == undefined) || vm.filtrosSelected[type].table != table || vm.filtrosSelected[type].value != value){
                    vm.filtrosSelected[type] = {table: table, value: value, catText: catText};
                }
                else{
                    delete vm.filtrosSelected[type];
                }
                vm.busqueda = '';
        }
        vm.selectFecha = function(){
            $uibModal.open({
                templateUrl: 'app/components/fecha/fecha.html',
                controller: 'FechaController',
                controllerAs: 'vm',
                size: 'md'
            }).result.then(function (result) {
                vm.filtrosSelected.fecha = {table: "fecha", value: {fechaIni:result.fechaIni, fechaFin:result.fechaFin}, catText: "Fecha"};
            });
        }
        vm.collapse = function($event, collapse){
            $event.stopPropagation();
            $event.preventDefault();
            return !collapse;
        }
        vm.clearSearch = function(){
            vm.busqueda = '';
        }
    }
})();
