(function () {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('MainEvidenciasController', MainEvidenciasController);

    MainEvidenciasController.$inject = ['$q', '$scope', '$http', '$timeout', 'showResult',];

    function MainEvidenciasController($q, $scope, $http, $timeout, showResult) {
        var vm = this;
        vm.loaded = false;
        function loadCanales() {
            $http.get('api/canales/' + vm.proyectoId).then(function (response) {
                vm.canales = response.data;
            });
        }
        function getFiltrosFinal() {
            var filtros = [];
            angular.forEach(vm.filtrosSelected, function (value, key) {
                if(key!="fecha"){
                filtros.push(value);
                }
            });
            return filtros;
        }
        vm.filtrosSelected = {};
        vm.filtrosLoaded = false;
        function loadEvidencias(page) {
            vm.loaded = false;
            var dataEvidencias = {
                "page": page,
                "size": 54,
                "idProyecto": "" + vm.proyectoId,
                "nombrecanal": "" + vm.canalElegido,
                "idUsuario": "" + vm.usuarioId
            }
            if(vm.filtrosSelected.fecha){
                dataEvidencias.fechadesde = ""+vm.filtrosSelected.fecha.value.fechaIni;
                dataEvidencias.fechahasta = ""+vm.filtrosSelected.fecha.value.fechaFin;
            }
            dataEvidencias.fil = getFiltrosFinal();
            if (vm.filtrosSelected.geo) dataEvidencias.fil.push(vm.filtrosSelected.geo);
            if (vm.filtrosSelected.cadena) dataEvidencias.fil.push(vm.filtrosSelected.cadena);
            if (vm.filtrosSelected.distribucion) dataEvidencias.fil.push(vm.filtrosSelected.distribucion);
            if(vm.filtrosSelected.tipoTienda) dataEvidencias.fil.push(vm.filtrosSelected.tipoTienda);
            if(vm.filtrosSelected.pregunta) dataEvidencias.fil.push(vm.filtrosSelected.pregunta);
            if(vm.filtrosSelected.producto) dataEvidencias.fil.push(vm.filtrosSelected.producto);
            if(vm.filtrosSelected.indicador){
                dataEvidencias.nombreIndicador = vm.filtrosSelected.indicador.value;
                $http.post('api/evidencias/mainKpi', dataEvidencias).then(function (response) {
                    vm.paginationPage = page;
                    vm.paginationSize = response.data.TotalPages;
                    vm.evidencias = response.data.Content;
                    vm.loaded = true;
                });
            } else {
                $http.post('api/evidencias/main', dataEvidencias).then(function (response) {
                    vm.paginationPage = page;
                    vm.paginationSize = response.data.TotalPages;
                    vm.evidencias = response.data.Content;
                    vm.loaded = true;
                });
            }
        }
        function loadFiltros() {
            vm.filtrosLoaded = false;
            vm.filtros = {
                geo: { elements: {}, cantEvidencias: { pais: {}, area: {}, estado: {}, municipio: {} } },
                cadena: { elements: {}, cantEvidencias: { cadena: {}, formato: {}, negocio: {} } },
                distribucion: { elements: {}, cantEvidencias: { region: {}, distribuidor: {}, supervisor: {}, rutaComercial: {}, rutaOperativa: {} } },
                tipoTienda: { elements: {}, cantEvidencias: { clusterTienda: {}, tipoTienda: {}, tamTienda: {} } },
                pregunta: { elements: {}, cantEvidencias: { pregunta: {} } },
                producto: { elements: {}, cantEvidencias: { marca: {} } },
                indicador: { elements: {}, cantEvidencias: { nombreIndicador: {} } }
            };
            var body = {
                "idProyecto": "" + vm.proyectoId,
                "nombrecanal": "" + vm.canalElegido,
            }
            if(vm.filtrosSelected.fecha){
                body.fechadesde = ""+vm.filtrosSelected.fecha.value.fechaIni;
                body.fechahasta = ""+vm.filtrosSelected.fecha.value.fechaFin;
            }
            body.fil = getFiltrosFinal();
            vm.filtrosPlain = {
                pais:{},
                area:{},
                estado:{},
                municipio:{},
                cadena:{},
                formato:{},
                negocio:{},
                region: {},
                distribuidor: {},
                supervisor: {},
                rutaComerciall: {},
                rutaOperativaa: {},
                clusterTienda: {},
                tipoTienda: {},
                tamTienda: {},
                pregunta:{},
                marca: {},
                nombreIndicador: {}
            };
            $http.post('api/evidencias/filtros', body).then(function (response) {
                angular.forEach(response.data.geo, function (value) {

                    if(!vm.filtrosPlain.pais[value.pais]) vm.filtrosPlain.pais[value.pais] = {};
                    if(!vm.filtrosPlain.area[value.area]) vm.filtrosPlain.area[value.area] = {pais:value.pais};
                    if(!vm.filtrosPlain.estado[value.estado]) vm.filtrosPlain.estado[value.estado] = {pais:value.pais, area: value.area};
                    if(!vm.filtrosPlain.municipio[value.municipio]) vm.filtrosPlain.municipio[value.municipio] = {pais:value.pais, area: value.area, estado: value.estado};

                    // Acumular cantidad de evidencias
                    if (vm.filtros.geo.cantEvidencias.pais[value.pais] == undefined) {
                        vm.filtros.geo.cantEvidencias.pais[value.pais] = value.evidencias;
                    } else vm.filtros.geo.cantEvidencias.pais[value.pais] += value.evidencias;
                    if (vm.filtros.geo.cantEvidencias.area[value.area] == undefined) {
                        vm.filtros.geo.cantEvidencias.area[value.area] = value.evidencias;
                    } else vm.filtros.geo.cantEvidencias.area[value.area] += value.evidencias;
                    if (vm.filtros.geo.cantEvidencias.estado[value.estado] == undefined) {
                        vm.filtros.geo.cantEvidencias.estado[value.estado] = value.evidencias;
                    } else vm.filtros.geo.cantEvidencias.estado[value.estado] += value.evidencias;
                    if (vm.filtros.geo.cantEvidencias.municipio[value.municipio] == undefined) {
                        vm.filtros.geo.cantEvidencias.municipio[value.municipio] = value.evidencias;
                    } else vm.filtros.geo.cantEvidencias.municipio[value.municipio] += value.evidencias;

                    // Crear jerarquía
                    if (vm.filtros.geo.elements[value.pais] == undefined) vm.filtros.geo.elements[value.pais] = {};
                    if (vm.filtros.geo.elements[value.pais][value.area] == undefined) vm.filtros.geo.elements[value.pais][value.area] = {};
                    if (vm.filtros.geo.elements[value.pais][value.area][value.estado] == undefined) vm.filtros.geo.elements[value.pais][value.area][value.estado] = {};
                    if (vm.filtros.geo.elements[value.pais][value.area][value.estado][value.municipio] == undefined) vm.filtros.geo.elements[value.pais][value.area][value.estado][value.municipio] = {};

                });
                angular.forEach(response.data.cadena, function (value) {
                    if(!vm.filtrosPlain.cadena[value.cadena]) vm.filtrosPlain.cadena[value.cadena] = {};
                    if(!vm.filtrosPlain.formato[value.formato]) vm.filtrosPlain.formato[value.formato] = {cadena:value.cadena};
                    if(!vm.filtrosPlain.negocio[value.negocio]) vm.filtrosPlain.negocio[value.negocio] = {cadena:value.cadena, formato: value.formato};

                    // Acumular cantidad de evidencias
                    if (vm.filtros.cadena.cantEvidencias.cadena[value.cadena] == undefined) {
                        vm.filtros.cadena.cantEvidencias.cadena[value.cadena] = value.evidencias;
                    } else vm.filtros.cadena.cantEvidencias.cadena[value.cadena] += value.evidencias;
                    if (vm.filtros.cadena.cantEvidencias.formato[value.formato] == undefined) {
                        vm.filtros.cadena.cantEvidencias.formato[value.formato] = value.evidencias;
                    } else vm.filtros.cadena.cantEvidencias.formato[value.formato] += value.evidencias;
                    if (vm.filtros.cadena.cantEvidencias.negocio[value.negocio] == undefined) {
                        vm.filtros.cadena.cantEvidencias.negocio[value.negocio] = value.evidencias;
                    } else vm.filtros.cadena.cantEvidencias.negocio[value.negocio] += value.evidencias;

                    if (vm.filtros.cadena.elements[value.cadena] == undefined) vm.filtros.cadena.elements[value.cadena] = {};
                    if (vm.filtros.cadena.elements[value.cadena][value.formato] == undefined) vm.filtros.cadena.elements[value.cadena][value.formato] = {};
                    if (vm.filtros.cadena.elements[value.cadena][value.formato][value.negocio] == undefined) vm.filtros.cadena.elements[value.cadena][value.formato][value.negocio] = {};
                });
                 var count = 0;
                var distribucion = $q.resolve(
                    angular.forEach(response.data.distribucion, function (value) {
                        if(!vm.filtrosPlain.region[value.region]) vm.filtrosPlain.region[value.region] = {};
                        if(!vm.filtrosPlain.distribuidor[value.distribuidor]) vm.filtrosPlain.distribuidor[value.distribuidor] = {region:value.region};
                        if(!vm.filtrosPlain.supervisor[value.supervisor]) vm.filtrosPlain.supervisor[value.supervisor] = {region:value.region, distribuidor: value.distribuidor};
                        if(!vm.filtrosPlain.rutaComerciall[value.rutaComercial]) vm.filtrosPlain.rutaComerciall[value.rutaComercial] = {region:value.region, distribuidor: value.distribuidor, supervisor: value.supervisor};
                        if(!vm.filtrosPlain.rutaOperativaa[value.rutaOperativa]) vm.filtrosPlain.rutaOperativaa[value.rutaOperativa] = {region:value.region, distribuidor: value.distribuidor, supervisor: value.supervisor, rutaComercial:value.rutaComercial};

                        count++;
                        // Acumular cantidad de evidencias
                        if (vm.filtros.distribucion.cantEvidencias.region[value.region] == undefined) {
                            vm.filtros.distribucion.cantEvidencias.region[value.region] = value.evidencias;
                        } else vm.filtros.distribucion.cantEvidencias.region[value.region] += value.evidencias;
                        if (vm.filtros.distribucion.cantEvidencias.distribuidor[value.distribuidor] == undefined) {
                            vm.filtros.distribucion.cantEvidencias.distribuidor[value.distribuidor] = value.evidencias;
                        } else vm.filtros.distribucion.cantEvidencias.distribuidor[value.distribuidor] += value.evidencias
                        if (vm.filtros.distribucion.cantEvidencias.supervisor[value.supervisor] == undefined) {
                            vm.filtros.distribucion.cantEvidencias.supervisor[value.supervisor] = value.evidencias;
                        } else vm.filtros.distribucion.cantEvidencias.supervisor[value.supervisor] += value.evidencias;
                        if (vm.filtros.distribucion.cantEvidencias.rutaComercial[value.rutaComercial] == undefined) {
                            vm.filtros.distribucion.cantEvidencias.rutaComercial[value.rutaComercial] = value.evidencias;
                        } else vm.filtros.distribucion.cantEvidencias.rutaOperativa[value.rutaComercial] += value.evidencias;
                        if (vm.filtros.distribucion.cantEvidencias.rutaOperativa[value.rutaOperativa] == undefined) {
                            vm.filtros.distribucion.cantEvidencias.rutaOperativa[value.rutaOperativa] = value.evidencias;
                        } else vm.filtros.distribucion.cantEvidencias.rutaOperativa[value.rutaOperativa] += value.evidencias;

                        if (vm.filtros.distribucion.elements[value.region] == undefined) vm.filtros.distribucion.elements[value.region] = {};
                        if (vm.filtros.distribucion.elements[value.region][value.distribuidor] == undefined) vm.filtros.distribucion.elements[value.region][value.distribuidor] = {};
                        if (vm.filtros.distribucion.elements[value.region][value.distribuidor][value.supervisor] == undefined) vm.filtros.distribucion.elements[value.region][value.distribuidor][value.supervisor] = {};
                        if (vm.filtros.distribucion.elements[value.region][value.distribuidor][value.supervisor][value.rutaComercial] == undefined) vm.filtros.distribucion.elements[value.region][value.distribuidor][value.supervisor][value.rutaComercial] = {};
                        if (vm.filtros.distribucion.elements[value.region][value.distribuidor][value.supervisor][value.rutaComercial][value.rutaOperativa] == undefined) vm.filtros.distribucion.elements[value.region][value.distribuidor][value.supervisor][value.rutaComercial][value.rutaOperativa] = {};

                    })

                ).then(
                    //for debugging
                //    console.log('vm.filtrosPlain', vm.filtrosPlain, 'vm.filtros.distribucion', vm.filtros.distribucion)

                  );

                angular.forEach(response.data.tipoTienda, function (value) {
                    if(!vm.filtrosPlain.clusterTienda[value.clusterTienda]) vm.filtrosPlain.clusterTienda[value.clusterTienda] = {};
                    if(!vm.filtrosPlain.tipoTienda[value.tipoTienda]) vm.filtrosPlain.tipoTienda[value.tipoTienda] = {clusterTienda:value.clusterTienda};
                    if(!vm.filtrosPlain.tamTienda[value.tamTienda]) vm.filtrosPlain.tamTienda[value.tamTienda] = {clusterTienda:value.clusterTienda, tipoTienda: value.tipoTienda};

                    // Acumular cantidad de evidencias
                    if (vm.filtros.tipoTienda.cantEvidencias.clusterTienda[value.clusterTienda] == undefined) {
                        vm.filtros.tipoTienda.cantEvidencias.clusterTienda[value.clusterTienda] = value.evidencias;
                    } else vm.filtros.tipoTienda.cantEvidencias.clusterTienda[value.clusterTienda] += value.evidencias;
                    if (vm.filtros.tipoTienda.cantEvidencias.tipoTienda[value.tipoTienda] == undefined) {
                        vm.filtros.tipoTienda.cantEvidencias.tipoTienda[value.tipoTienda] = value.evidencias;
                    } else vm.filtros.tipoTienda.cantEvidencias.tipoTienda[value.tipoTienda] += value.evidencias;
                    if (vm.filtros.tipoTienda.cantEvidencias.tamTienda[value.tamTienda] == undefined) {
                        vm.filtros.tipoTienda.cantEvidencias.tamTienda[value.tamTienda] = value.evidencias;
                    } else vm.filtros.tipoTienda.cantEvidencias.tamTienda[value.tamTienda] += value.evidencias;

                    if (vm.filtros.tipoTienda.elements[value.clusterTienda] == undefined) vm.filtros.tipoTienda.elements[value.clusterTienda] = {};
                    if (vm.filtros.tipoTienda.elements[value.clusterTienda][value.tipoTienda] == undefined) vm.filtros.tipoTienda.elements[value.clusterTienda][value.tipoTienda] = {};
                    if (vm.filtros.tipoTienda.elements[value.clusterTienda][value.tipoTienda][value.tamTienda] == undefined) vm.filtros.tipoTienda.elements[value.clusterTienda][value.tipoTienda][value.tamTienda] = {};
                });
                angular.forEach(response.data.pregunta, function (value) {
                    if(!vm.filtrosPlain.pregunta[value.pregunta]) vm.filtrosPlain.pregunta[value.pregunta] = {};
                    // Acumular cantidad de evidencias
                    if (vm.filtros.pregunta.cantEvidencias.pregunta[value.pregunta] == undefined) {
                        vm.filtros.pregunta.cantEvidencias.pregunta[value.pregunta] = value.evidencias;
                    } else vm.filtros.pregunta.cantEvidencias.pregunta[value.pregunta] += value.evidencias;
                    if (vm.filtros.pregunta.elements[value.pregunta] == undefined) vm.filtros.pregunta.elements[value.pregunta] = {};
                });
                angular.forEach(response.data.producto, function (value) {
                    if(!vm.filtrosPlain.marca[value.marca]) vm.filtrosPlain.marca[value.marca] = {};
                    // Acumular cantidad de evidencias
                    if (vm.filtros.producto.cantEvidencias.marca[value.marca] == undefined) {
                        vm.filtros.producto.cantEvidencias.marca[value.marca] = value.evidencias;
                    } else vm.filtros.producto.cantEvidencias.marca[value.marca] += value.evidencias;
                    if (vm.filtros.producto.elements[value.marca] == undefined) vm.filtros.producto.elements[value.marca] = {};
                });
                angular.forEach(response.data.indicador, function (value) {
                    if(!vm.filtrosPlain.nombreIndicador[value.nombreIndicador]) vm.filtrosPlain.nombreIndicador[value.nombreIndicador] = {};
                    if (vm.filtros.indicador.cantEvidencias.nombreIndicador[value.nombreIndicador] == undefined){
                        vm.filtros.indicador.cantEvidencias.nombreIndicador[value.nombreIndicador] = value.evidencias;
                    } else vm.filtros.indicador.cantEvidencias.nombreIndicador[value.nombreIndicador] += value.evidencias;
                    if (vm.filtros.indicador.elements[value.nombreIndicador] == undefined) vm.filtros.indicador.elements[value.nombreIndicador] = {};
                });
                angular.forEach(response.data.genericos, function (values, key) {
                    vm.filtrosPlain[key] = {};

                    // Acumular cantidad de evidencias
                    if (vm.filtros[key] == undefined) {
                        vm.filtros[key] = { elements: {}, cantEvidencias: {} };
                    }

                    angular.forEach(values, function (value) {
                        vm.filtrosPlain[key][value.valor] = {};
                        if (vm.filtros[key].cantEvidencias[value.valor] == undefined) {
                            vm.filtros[key].cantEvidencias[value.valor] = value.evidencias;
                        } else vm.filtros[key].cantEvidencias[value.valor] += value.evidencias;
                        if (vm.filtros[key].elements[value.valor] == undefined) vm.filtros[key].elements[value.valor] = {};
                    })
                });
                vm.filtrosLoaded = true;
            });
        }
        vm.filtrosHidden = true;
        vm.mostrarFiltros = function () {
            vm.filtrosHidden = !vm.filtrosHidden;
        }
        vm.carHidden = true;
        vm.evidenciasSet = false;
        vm.evidencias = [];
        vm.activeSlide = 0;
        vm.paginationPage = 0;
        vm.paginationSize = 0;
        vm.lastProyecto = null;
        vm.lastCanal = "";
        vm.showCanales = false;
        vm.mostrarCanal = function (value) {
            if (vm.canalElegido == value){
                vm.canalElegido = '';
            }
            else{
                vm.canalElegido = value;
            }
            vm.showCanales = !vm.showCanales;
        }
        vm.mostrarCanales = function () {
            vm.showCanales = !vm.showCanales;
        }
        $scope.$watch("vm.eviShown", function (shown) {
            initialize();
        }, true);
        $scope.$watch("vm.proyectoId", function (shown) {
            initialize();
        }, true);
        $scope.$watch("vm.canalElegido", function (shown) {
            initialize();
        }, true);
        function initialize() {
            if (vm.eviShown &&
                (vm.lastProyecto != vm.proyectoId ||
                vm.lastCanal != vm.canalElegido) &&
                vm.proyectoId != null) {
                loadCanales();
                if(vm.paginationPage!=0) {
                    vm.paginationPage = 0;
                }
                else loadEvidencias(0);
                loadFiltros();

                vm.lastProyecto = vm.proyectoId;
                vm.lastCanal = vm.canalElegido;
            }
        }
        $scope.$watch("vm.paginationPage", function (value) {
            if (vm.eviShown) {
            loadEvidencias(value);
            }
        }, true);
        $scope.$watch("vm.filtrosSelected", function (value) {
            if (vm.eviShown) {
                loadEvidencias(0);
                loadFiltros();
            }
        }, true);
        vm.download = function(){
            vm.loaded = false;
            var dataEvidencias = {
                "idProyecto": "" + vm.proyectoId,
                "nombrecanal": "" + vm.canalElegido,
                "idUsuario": "" + vm.usuarioId
            };
            if(vm.filtrosSelected.fecha){
                dataEvidencias.fechadesde = ""+vm.filtrosSelected.fecha.value.fechaIni;
                dataEvidencias.fechahasta = ""+vm.filtrosSelected.fecha.value.fechaFin;
            }
            dataEvidencias.fil = getFiltrosFinal();
            if (vm.filtrosSelected.geo) dataEvidencias.fil.push(vm.filtrosSelected.geo);
            if (vm.filtrosSelected.cadena) dataEvidencias.fil.push(vm.filtrosSelected.cadena);
            $http.post('api/evidencias/descargaMain', dataEvidencias, { responseType: 'arraybuffer' }).then(function (response) {
                var newDate = new Date();
                var date = "" + newDate.getDate()+ "-" + (newDate.getMonth() + 1) + "-" + newDate.getFullYear() + "-" + newDate.getHours() + "-" + newDate.getMinutes();
                var file = new Blob([response.data], { type: 'application/zip' });
                saveAs(file, 'evidencias-dashboard-'+date+'.zip');
                vm.loaded = true;
            });
        }
    }
})();
