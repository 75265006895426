(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('dropdown', {
            templateUrl: 'app/components/dropdown/dropdown.html',
            controller: 'DropdownController',
            controllerAs: 'vm'
        });
})();
