(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('sidebar', {
            templateUrl: 'app/components/sidebar/sidebar.html',
            controller: 'SidebarController',
            controllerAs: 'vm',
            bindings: {
                mostrarSidebar: '=',
                canalElegido: '=',
                indicadorElegido: '=',
                idIndicadorElegido: '=',
                resultado: '=',
                resultadoTotales: '=',
                resultadoTendencias: '=',
                mostrarTendencia: '=',
                mostrarMapa: '=',
                mostrarTabla: '=',
                mostrarEvidencia: '=',
                mostrar: '=',
                cargandoTendencia: '=',
                cargandoMapa: '=',
                cargandoEvidencia: '=',
                resultadoMapas: '=',
                resultadoEvidencia: '=',
                filtros: '=',
                fechas: '=',
                proyectoId: '=',
                openModalProjects: '&',
                visionSeleccionada: '=',
                cargando: '=',
                showdashboard: '=',
                showmainevidencias: '=',
                imagenbase64: '=',
                nombreProyecto: '=',
                showMessageError: '=',
                soloEvidencias: '=',
                proyectoCanalIndicadores: '=',
                unicoProyecto: '=',
                indicadorCanalEntrante: '=',
                menuPadreIndicador: '=',
                menuEntrante : '='
            }
        });
})();
