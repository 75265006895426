(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$stateParams', '$state', '$timeout', 'Auth', 'LoginService'];

    function ResetFinishController ($stateParams, $state, $timeout, Auth, LoginService) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
        vm.login = LoginService.open;
        vm.resetAccount = {};
        vm.success = null;
        vm.key = $stateParams.key;
        vm.user = $state.params.mail;

        $timeout(function (){angular.element('#password').focus();});

        function finishReset() {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({Username: vm.user, Token: vm.key, NewPassword: vm.resetAccount.password}).then(function () {
                    vm.success = 'OK';
                    $timeout(function () {
                        $state.go('home');
                    }, 7000);
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();
