(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('eviGallery', {
            templateUrl: 'app/components/eviGallery/eviGallery.html',
            controller: 'EviGalleryController',
            controllerAs: 'vm',
            bindings: {
                items: '=',
                selection:'=',
                carHidden:'='
            }
        });
})();
