(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .factory('apiInterceptor', apiInterceptor);

    apiInterceptor.$inject = ['DASHBOARD_API_URL'];

    function apiInterceptor (DASHBOARD_API_URL) {
        var service = {
            request: request
        };
        return service;

        function request (config) {
            if (config && config.url && (/^api/.test(config.url) || /^token/.test(config.url))) {
                config.url = DASHBOARD_API_URL + config.url;
            }
            return config;
        }
    }
})();
