(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('graficaPrincipal', {
            templateUrl: 'app/components/graficaPrincipal/graficaPrincipal.html',
            controller: 'GraficaPrincipalController',
            controllerAs: 'vm',
            bindings: {
                labelsDatos: '=',
                ultDatos: '=',
                listaCantidad: '=',
                labels: '=',
                cont: '='
            }
        });
})();
