(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = [];

    function NavbarController () {
        var vm = this;

    }
})();
