(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('row', {
            templateUrl: 'app/components/resultado/row.html',
            controller: 'RowController',
            controllerAs: 'vm',
            bindings: {
                rows: '=',
                filtroSelected: '='
            }
        });
})();
