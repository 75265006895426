(function() {
   'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('tendencias', {
            templateUrl: 'app/components/tendencias/tendencias.html',
            controller: 'TendenciasController',
            controllerAs: 'vm',
            bindings: {
                resultadoTendenciasIndicador: '=',
                mostrarTendencias: '=',
                cargandoTendencias: '='
            }
        });
})();
