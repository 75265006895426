(function () {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('EviGalleryController', EviGalleryController);

    EviGalleryController.$inject = ['$scope', '$http', '$timeout', 'showResult', '$element'];

    function EviGalleryController($scope, $http, $timeout, showResult,$element) {
        var vm = this;
        vm.selection = 0;
        vm.select = function(index){
            vm.selection = index;
            vm.carHidden = !vm.carHidden;
        }
    }
})();
