(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('perfectStoreDashboardApp')
        .constant('VERSION', 1)
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_TIMESTAMP', 1725546318840)
        .constant('DASHBOARD_API_URL', "")
        .constant('IS_DEV', false)
;
})();
