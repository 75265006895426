(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('TendenciasController', TendenciasController);

    TendenciasController.$inject = ['showResult'];

    function TendenciasController (showResult) {
        var vm = this;
        var meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio',
            'Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
        var trimestres = ["Enero a Marzo", "Abril a Junio",
            "Julio a Septiembre","Octubre a Diciembre"];
        var semestres = ["Enero a Junio",
            "Julio a Diciembre"];
        vm.mostrar = false;
        vm.disableMes = true;
        vm.disableTri = false;
        vm.datosMes = [];
        vm.datosTri = [];
        vm.datosSe = [];
        vm.datosAnio = [];
        var ultDatos = [];
        var ultDatosTri = [];
        var ultDatosSe = [];
        var ultDatosAnio = [];
        var labelsDatos = [];
        var labelsDatosTri = [];
        var labelsDatosSe = [];
        var labelsDatosAnio = [];
        var dt = new Date();
        dt.setMonth(dt.getMonth()-1);
        var myChart;

        vm.cargarTendencias = cargarTendencias;
        vm.cambioTri = cambioTri;
        vm.cambioMes = cambioMes;
        vm.cambioSe = cambioSe;
        vm.cambioAnio = cambioAnio;
        vm.change = change;
        vm.cargaChart = cargaChart;

        var showResultSubscribe = showResult.subscribe(function (x) {
            if(x === "cargaChartTendencia"){
                vm.change();
                vm.mostrarTendencias = true;
            }
        });

        function cargarTendencias(){
            var msg = "cargarTendencias";
            showResult.sendMessage(msg);
        }

        function cargaChart() {
            var ctx = document.getElementById("myChart").getContext('2d');

            var bgGrd = ctx.createLinearGradient(150.000, 0.000, 150.000, 300.000);
            // Add colors
            bgGrd.addColorStop(0.000, 'rgba(0, 0, 0, 0.2)');
            bgGrd.addColorStop(1.000, 'rgba(255, 255, 255, 0.000)');
            var grd = ctx.createLinearGradient(0.000, 150.000, 300.000, 150.000);
            // Add colors
            grd.addColorStop(0.500, 'rgba(72, 189, 231, 1)');
            grd.addColorStop(1.000, 'rgba(57, 210, 163, 1)');

            vm.datosAnio = [
                {
                    data: ultDatosAnio,
                    backgroundColor: bgGrd,
                    borderColor: grd,
                    borderWidth: 10
                }
            ];

            vm.dataAnio = {
                labels: labelsDatosAnio,
                datasets: vm.datosAnio
            };

            vm.datosSe = [
                {
                    data: ultDatosSe,
                    backgroundColor: bgGrd,
                    borderColor: grd,
                    borderWidth: 10
                }
            ];

            vm.dataSe = {
                labels: labelsDatosSe,
                datasets: vm.datosSe
            };

            vm.datosTri = [
                {
                    data: ultDatosTri,
                    backgroundColor: bgGrd,
                    borderColor: grd,
                    borderWidth: 10
                }
            ];

            vm.dataTri = {
                labels: labelsDatosTri,
                datasets: vm.datosTri
            };


            vm.datosMes = [
                {
                    data: ultDatos,
                    backgroundColor: bgGrd,
                    borderColor: grd,
                    borderWidth: 10
                }
            ];

            vm.dataMes = {
                labels: labelsDatos,
                datasets: vm.datosMes
            };

            var orangeGradient = ctx.createLinearGradient(0, 0, 0, 400);
            orangeGradient.addColorStop(0, '#fdbba9');
            orangeGradient.addColorStop(1, 'white');

            vm.myChart = new Chart(ctx,
                {
                    type: 'line',
                    data: {
                        labels: labelsDatos,
                        datasets: [{
                            data: ultDatos,
                            fill: true,
                            // fillColor : gradient,
                            backgroundColor: orangeGradient,
                            hoverBackgroundColor: orangeGradient,
                            hoverBorderWidth: 2,
                            pointBackgroundColor: "#f96332",
                            pointBorderColor: "#FFFFFF",
                            pointBorderWidth: 2,
                            pointRadius: 4,
                            borderWidth: 8
                        }]
                    },
                    options:
                        {
                            legend:
                                {
                                    display: false
                                }

                        }
                });
        }

        function change() {
            if(vm.myChart != null){
                vm.myChart.destroy();
            }
            myChart = null;
            labelsDatos = [];
            labelsDatosTri = [];
            labelsDatosSe = [];
            labelsDatosAnio = [];
            ultDatos = [];
            ultDatosTri = [];
            ultDatosSe = [];
            ultDatosAnio = [];
            //console.log(vm.resultadoTendenciasIndicador);
            if(vm.resultadoTendenciasIndicador.length !== 0) {
                var cont = 0;
                var acum = 0;
                var contSe = 0;
                var acumSe = 0;
                var contAn = 0;
                var acumAn = 0;
                angular.forEach(vm.resultadoTendenciasIndicador, function (item) {
                    var label = meses[item.mes-1] + ' - ' + item.anio;
                    labelsDatos.push(label);
                    ultDatos.push(parseFloat(item.valor).toFixed(2));
                    acum = acum + parseFloat(item.valor);
                    acumSe = acumSe + parseFloat(item.valor);
                    acumAn = acumAn + parseFloat(item.valor);
                    if (item.mes === 6 || item.mes === 12) {
                        var labelSe;
                        if(item.mes === 6){
                            labelSe = semestres[0] + ' - ' + item.anio;
                        }
                        else{
                            labelSe = semestres[1] + ' - ' + item.anio;
                        }
                        ultDatosSe.push(parseFloat(acumSe).toFixed(2));
                        labelsDatosSe.push(labelSe);
                        contSe = 0;
                        acumSe = 0;
                    }
                    if (item.mes === 12) {
                        var labelAnio = item.anio;
                        ultDatosAnio.push(parseFloat(acumAn).toFixed(2));
                        if(labelAnio == null){
                            labelAnio = dt.getFullYear();
                        }
                        labelsDatosAnio.push(labelAnio);
                        contAn = 0;
                        acumAn = 0;
                    }
                    if(item.mes === 3 || item.mes === 6 || item.mes === 9 || item.mes === 12){
                        var labelTri;
                        if (item.mes === 3){
                            labelTri = trimestres[0] + ' - ' + item.anio;
                        }
                        else if (item.mes === 6){
                            labelTri = trimestres[1] + ' - ' + item.anio;
                        }
                        else if (item.mes === 9){
                            labelTri = trimestres[2] + ' - ' + item.anio;
                        }
                        else{
                            labelTri = trimestres[3] + ' - ' + item.anio;
                        }
                        ultDatosTri.push(parseFloat(acum).toFixed(2));
                        labelsDatosTri.push(labelTri);
                        cont = 0;
                        acum = 0;
                    }
                    else {
                        cont = cont + 1;
                        contSe = contSe + 1;
                        contAn = contAn + 1;
                    }
                });
                if(cont !== 0){
                    ultDatosTri.push(parseFloat(acum).toFixed(2));
                }
                if(contSe !== 0){
                    ultDatosSe.push(parseFloat(acumSe).toFixed(2));
                }
                if(contAn !== 0){
                    ultDatosAnio.push(parseFloat(acumAn).toFixed(2));
                }
                cargaChart();
            }
            else{
            }
        }

        function cambioAnio() {
            vm.myChart.data.labels = vm.dataAnio.labels;
            vm.myChart.data.datasets[0].data = vm.dataAnio.datasets[0].data;
            vm.myChart.update();
            vm.disableTri = false;
            vm.disableMes = false;
            vm.disableSe = false;
            vm.disableAnio = true;
        }

        function cambioSe() {
            vm.myChart.data.labels = vm.dataSe.labels;
            vm.myChart.data.datasets[0].data = vm.dataSe.datasets[0].data;
            vm.myChart.update();
            vm.disableTri = false;
            vm.disableMes = false;
            vm.disableSe = true;
            vm.disableAnio = false;
        }

        function cambioTri() {
            vm.myChart.data.labels = vm.dataTri.labels;
            vm.myChart.data.datasets[0].data = vm.dataTri.datasets[0].data;
            vm.myChart.update();
            vm.disableTri = true;
            vm.disableMes = false;
            vm.disableSe = false;
            vm.disableAnio = false;
        }

        function cambioMes() {
            vm.myChart.data.labels = vm.dataMes.labels;
            vm.myChart.data.datasets[0].data = vm.dataMes.datasets[0].data;
            vm.myChart.update();
            vm.disableTri = false;
            vm.disableMes = true;
            vm.disableSe = false;
            vm.disableAnio = false;
        }
    }
})();
