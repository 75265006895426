(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('mainevidencias', {
            templateUrl: 'app/components/mainevidencias/mainevidencias.html',
            controller: 'MainEvidenciasController',
            controllerAs: 'vm',
            bindings: {
                eviShown: '=',
                proyectoId: '=',
                canalElegido: '=',
                usuarioId: '='
            }
        });
})();
