(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('DropdownController', DropdownController);

    DropdownController.$inject = [];

    function DropdownController () {
        var vm = this;

    }
})();
