(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .component('tarjetas', {
            templateUrl: 'app/components/tarjetas/tarjetas.html',
            controller: 'TarjetasController',
            controllerAs: 'vm',
            bindings: {
                resultadoGraficaSecundaria: '=',
                graficasSecundarias: '=',
                graficaNoConfigurada: '=',
                cargandoGraficaSecundarias: '='
            }
        });
})();
