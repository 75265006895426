(function() {
    'use strict';

    angular
        .module('perfectStoreDashboardApp')
        .controller('TablaController', TablaController);

    TablaController.$inject = ['$http','$sessionStorage','Principal'];

    function TablaController ($http,$sessionStorage,Principal) {
        var vm = this;
        vm.filtro = '';
        vm.pagina = 1;
        vm.paginas = 1;
        vm.datosTabla = [];
        vm.regex = regex;
        vm.cargaTabla = cargaTabla;
        vm.sumarPagina = sumarPagina;
        vm.restarPagina = restarPagina;
        vm.adelantarColumna = adelantarColumna;
        vm.retrocederColumna = retrocederColumna;
        vm.buscarCodigoProducto = buscarCodigoProducto;
        vm.buscarCodigoTienda = buscarCodigoTienda;
        vm.proyectColombia = proyectColombia;
        vm.nCol = nCol;
        vm.visiones = [];
        vm.secundarias = [];
        vm.tVal = tVal;
        vm.ExportToXLSX = ExportToXLSX;

        function cargaTabla() {
            vm.mostrarTablas = !vm.mostrarTablas;
            if (vm.mostrarTablas){
                var indicadorTabla = vm.resultadoTablaIndicador[0],
                    codTie = false,
                    codPro = false,
                    numMax = 0,
                    numMin = 0;
                const isPStore = indicadorTabla.indicador == 'Perfect Store';
                vm.filtro = '';
                vm.actualizandoData = false;
                vm.descargando = false;
                vm.proyectoId = angular.copy($sessionStorage.proyectoId);
                vm.usuarioId = Principal.identity().$$state.value.Id;;
                vm.visiones = vm.resultadoTablaIndicador[0].vision;
                vm.secundarias = vm.resultadoTablaIndicador[0].medidasSecundarias;
                vm.paginas = Math.ceil(vm.resultadoTablaIndicador.length / 10);
                vm.pagina = 1;
                vm.datosTabla = [];
                vm.paginas = vm.paginas > 100 ? 100 : vm.paginas;
                numMax = vm.pagina * 10;
                numMin = numMax - 9;
                for (numMin; numMin <= numMax; numMin++) {
                    if(vm.resultadoTablaIndicador.length >= numMin) {
                        vm.datosTabla.push(vm.resultadoTablaIndicador[numMin - 1]);
                    }
                }
                if (isPStore){
                    angular.forEach(indicadorTabla.vision, function (vision){
                        if (vision.OptionText === "CodigoTienda"){
                            codTie = true;
                        }
                        else if (vision.OptionText === "CodigoProducto"){
                            codPro = true;
                        }
                    });
                    if (!codTie && !codPro){
                        angular.forEach(indicadorTabla.vision, function (vision, i){
                            if (vision.OptionText === "NombreNegocio"){
                                buscarCodigoTienda(i);
                            }
                            else if (vision.OptionText === "DescripcionProducto"){
                                buscarCodigoProducto(i);
                            }
                        });
                    }
                }
            }
        }

        function nCol() {
            return vm.resultadoTablaIndicador[0].nombresColumnas.length > 0 ? true : false;
        }

        function tVal(t) {
            if (t !== undefined){
                return t.valor != -1 ? true : false;
            }
        }

        function sumarPagina() {
            var numMax = 0,
            numMin = 0,
            cantIndicadores = 0;
            if(vm.pagina < vm.paginas) {
                vm.pagina = vm.pagina + 1;
                numMax = vm.pagina * 10;
                numMin = numMax - 9;
                vm.datosTabla = [];
                cantIndicadores = vm.resultadoTablaIndicador.length;
                for (numMin; numMin <= numMax; numMin++) {
                    if (cantIndicadores >= numMin) {
                        vm.datosTabla.push(vm.resultadoTablaIndicador[numMin - 1]);
                    }
                }
            }
        }

        function restarPagina() {
            var numMax = 0,
            numMin = 0,
            cantIndicadores = 0;
            if(vm.pagina > 1) {
                vm.pagina = vm.pagina - 1;
                numMax = vm.pagina * 10;
                numMin = numMax - 9;
                vm.datosTabla = [];
                cantIndicadores = vm.resultadoTablaIndicador.length;
                for (numMin; numMin <= numMax; numMin++) {
                    if (cantIndicadores >= numMin) {
                        vm.datosTabla.push(vm.resultadoTablaIndicador[numMin - 1]);
                    }
                }
            }
        }

        function adelantarColumna(indice) {
            var tempVision = [],
                tempNombresColumnas = [];
            if(indice !== vm.resultadoTablaIndicador[0].vision.length - 1) {
                angular.forEach(vm.datosTabla, function (datos) {
                    tempVision = datos.vision[indice];
                    tempNombresColumnas = datos.nombresColumnas[indice];
                    datos.vision[indice] = datos.vision[indice + 1];
                    datos.vision[indice + 1] = tempVision;
                    datos.nombresColumnas[indice] = datos.nombresColumnas[indice + 1];
                    datos.nombresColumnas[indice + 1] = tempNombresColumnas;
                })
            }
        }

        function retrocederColumna(indice) {
            var tempVision = [],
                tempNombresColumnas = [];
            if(indice !== 0) {
                angular.forEach(vm.datosTabla, function (datos) {
                    tempVision = datos.vision[indice];
                    tempNombresColumnas = datos.nombresColumnas[indice];
                    datos.vision[indice] = datos.vision[indice - 1];
                    datos.vision[indice - 1] = tempVision;
                    datos.nombresColumnas[indice] = datos.nombresColumnas[indice - 1];
                    datos.nombresColumnas[indice - 1] = tempNombresColumnas;
                })
            }
        }

        function buscarCodigoTienda(i) {
            vm.actualizandoData = true;
            var listaId = [],
                codigos = {},
                IdsNegocios = {};
            const objTienda = {
                OptionValue : 'Codigo Tienda',
                OptionText: 'CodigoTienda',
                Table: ''
            };
            const objNegocio = {
                OptionValue : 'Id Negocio',
                OptionText: 'IdNegocio',
                Table: 'Negocio'
            };

            const objMarcelo = {
                OptionValue : 'Marcelo',
                OptionText: 'Marcelo',
                Table: ''
            };
            angular.forEach(vm.resultadoTablaIndicador, function (resultado) {
                var id = regex(resultado.nombresColumnasGenerales[i]);
                listaId.push(id);
            });
            $http.post('api/resultado/buscarCodigosNegocios', listaId).then(function (response) {
                angular.forEach(response.data,function(codigo){
                    codigos[codigo.Nombre] = (codigo.Codigo == "" || codigo.Codigo == null) ? " " : codigo.Codigo;
                })

                angular.forEach(response.data,function(codigo){
                    IdsNegocios[codigo.Nombre] = codigo.Id;
                })

                angular.forEach(vm.resultadoTablaIndicador, function (resultado) {

                     resultado.nombresColumnas.push(codigos[resultado.nombresColumnasGenerales[i]]);
                     resultado.vision.push(objTienda);
                });

                angular.forEach(vm.resultadoTablaIndicador, function (resultado) {

                    resultado.nombresColumnas.push(IdsNegocios[resultado.nombresColumnasGenerales[i]]);
                    resultado.vision.push(objNegocio);
               });

                vm.actualizandoData = false;
            });
        }

        function buscarCodigoProducto(i) {
            vm.actualizandoData = true;
            var listaId = [],
                codigos = {};
            const objProducto = {
                OptionValue : 'Codigo Producto',
                OptionText: 'CodigoProducto',
                Table: ''
            };
            angular.forEach(vm.resultadoTablaIndicador, function (resultado) {
                var id = regex(resultado.nombresColumnasGenerales[i]);
                listaId.push(id);
            });
            $http.post('api/resultado/buscarCodigosProductos', listaId).then(function (response) {
                angular.forEach(response.data,function(codigo){
                    codigos[codigo.Nombre] = (codigo.Codigo == "" || codigo.Codigo == null) ? " " : codigo.Codigo;
                })
                angular.forEach(vm.resultadoTablaIndicador, function (resultado) {
                    resultado.nombresColumnas.push(codigos[resultado.nombresColumnasGenerales[i]]);
                    resultado.vision.push(objProducto);
                });
                vm.actualizandoData = false;
            });
        }

        function ExportToXLSX() {
            vm.descargando = true;
            var arrayRespuestas = [],
                objRespuesta = {},
                dataExcel = {},
                jsonTablePS = '',
                visiones = [],
                tabla = '',
                valor = '',
                col = 0,
                indicadorTabla = vm.resultadoTablaIndicador[0];
            const isPStore = indicadorTabla.indicador.match('Perfect Store');
            if (isPStore){
                if (indicadorTabla.vision.length === 0){
                    angular.forEach(vm.resultadoTablaIndicador, function(e){
                        objRespuesta = {};
                        objRespuesta.Indicador = (e.nombresColumnas[0] ? e.nombresColumnas[0] : '');
                        objRespuesta.Puntaje = (e.valor ? e.valor : '') + (e.mascara ? e.mascara : '');
                        arrayRespuestas.push(objRespuesta);
                    });
                }
                else{
                    angular.forEach(vm.resultadoTablaIndicador, function(e){
                        col = 0;
                        objRespuesta = {};
                        angular.forEach(e.vision, function(v){
                            objRespuesta[v.OptionValue] = e.nombresColumnas[col];
                            col++;
                        });
                        objRespuesta.Resultado = (e.preMascara ? e.preMascara : '') + (e.valor ? e.valor : '') + (e.mascara ? e.mascara : '');
                        angular.forEach(e.medidasSecundarias, function(s){
                            objRespuesta[s.PreMascara] = (s.resultado ? s.resultado : '') + (s.mascara ? s.mascara : '');
                        });
                        arrayRespuestas.push(objRespuesta);
                    });
                }
                jsonTablePS = JSON.stringify(arrayRespuestas);
                dataExcel = {
                    "jsonTablePS": "" + jsonTablePS
                };
            }
            else{
                if (indicadorTabla.vision.length !== 0){
                    angular.forEach(indicadorTabla.vision, function (vision) {
                        tabla = vision.Table;
                        valor = vision.OptionText;
                        if(tabla == null){
                            visiones.push(valor);
                        }
                        else if(tabla === "Negocio"){
                            visiones.push("Ne.Id" + valor.replace("Nombre",""));
                            if (valor === "NombreNegocio"){ visiones.push("Ne.CodigoExterno") }
                            visiones.push("Ne." + valor + "Reportes");
                        }
                        else if(tabla === "RespuestaEncuesta"){
                            visiones.push("Re." + valor);
                        }
                        else if(tabla === "PreguntasEncuesta"){
                            visiones.push("Pe.Id" + valor);
                            visiones.push("Pe." + valor + "Reportes");
                        }
                        else if(tabla === "Producto"){
                            if (valor === "DescripcionProducto"){
                                visiones.push("P.CodigoProducto")
                                visiones.push("Re.IdProducto")
                            }
                            else{
                                visiones.push("P.Id" + valor)
                            }
                            visiones.push("P." + valor + "Reportes");
                        }
                        else if(tabla === "RutaOperativa"){
                            visiones.push("Ro.IdRutaOperativa");
                            visiones.push("Ro." + valor);
                        }
                        else if(tabla === "OpcionesSeleccionMultiple"){
                            visiones.push("Op.IdOpcionSeleccionMultiple")
                            visiones.push("Op." + valor);
                        }
                        else if(tabla === "ItemPromocional"){
                            visiones.push("Ip.IdItemPromocional")
                            visiones.push("Ip." + valor);
                        }
                        else if(tabla === "Marcas"){
                            if (valor === 'Empresa'){
                                visiones.push('M.IdEmpresa')
                            }
                            else{
                                visiones.push("M.IdMarca");
                            }
                            visiones.push("M." + valor);
                        }
                        else if(tabla === "Empresas"){
                            visiones.push("E.IdEmpresa");
                            visiones.push("E." + valor);
                        }
                        else if(tabla === "CategoriasProducto"){
                            visiones.push("Cp.IdCategoriaProducto");
                            visiones.push("Cp." + valor);
                        }
                        else if(tabla === "UbicacionesEnTienda"){
                            visiones.push("Ut.IdUbicacionEnTienda");
                            visiones.push("Ut." + valor);
                        }
                        else if(tabla === "TiposDeItemsPromocionales"){
                            visiones.push("Tip.IdTipoItemPromocional");
                            visiones.push("Tip." + valor);
                        }
                        else if(tabla === "TipoTiendaProyecto"){
                            visiones.push("Ttp.IdTipoTiendaProyecto");
                            visiones.push("Ttp." + valor);
                        }
                        else if(tabla === "CapacidadProductos"){
                            visiones.push("Re." + valor);
                        }
                    });
                    dataExcel = {
                        "idProyecto": "" + vm.proyectoId,
                        "idUsuario": "" + vm.usuarioId,
                        "nombrecanal": "" + indicadorTabla.canal,
                        "indicador": "" + indicadorTabla.indicador,
                        "fil": indicadorTabla.filtros,
                        "fechadesde": "" + indicadorTabla.fechas.FechaInicio,
                        "fechahasta": "" + indicadorTabla.fechas.FechaFin,
                        "vision": visiones
                    };
                }
                else {
                    objRespuesta.Resultado = (indicadorTabla.preMascara && indicadorTabla.valor !== -1 ? indicadorTabla.preMascara : '') + (indicadorTabla.valor !== -1 ? indicadorTabla.valor : 'NA') + (indicadorTabla.mascara && indicadorTabla.valor !== -1 ? indicadorTabla.mascara : '');
                    angular.forEach(indicadorTabla.medidasSecundarias, function(s){
                        if (!vm.proyectColombia(indicadorTabla,s)){
                            objRespuesta[s.Medida.Nombre] = (s.Medida.PreMascara ? s.Medida.PreMascara : '') + Intl.NumberFormat().format(s.resultado) + (s.Medida.Mascara ? s.Medida.Mascara : '');
                        }
                        else{
                            objRespuesta[s.Medida.Nombre] = (s.Medida.PreMascara ? s.Medida.PreMascara : '') + (vm.valor) + (s.Medida.Mascara ? s.Medida.Mascara : '');
                        }
                    });
                    jsonTablePS = JSON.stringify(objRespuesta);
                    dataExcel = {
                        "idUsuario": "" + vm.usuarioId,
                        "jsonTablePS": "" + jsonTablePS
                    };
                }
            }


            $http.post('api/tabla/descarga', dataExcel, { responseType: 'arraybuffer' }).then(function (response) {
                var nombreCanal = indicadorTabla.canal.toLowerCase();
                nombreCanal = nombreCanal.charAt(0).toUpperCase() + nombreCanal.slice(1);
                var nombreIndicador = indicadorTabla.indicador.replaceAll(' ','-');
                const newDate = new Date();
                const date = newDate.getDate()+ "-" + (newDate.getMonth() + 1) + "-" + newDate.getFullYear();
                const file = new Blob([response.data], { type: 'text/xlsx' });
                saveAs(file, nombreCanal + '_' + nombreIndicador + '_' + date + '.xlsx');
                vm.descargando = false;
            });
        }

        //Formato pesos para Colombia Precios
        function proyectColombia(indi, secu) {
            vm.valor = null;
            if (vm.proyectoId == 25 || vm.proyectoId == 60){
                if (indi.indicador == "Precios" && secu.Medida.IdMedidaSecundaria == 36){
                    vm.valor = parseFloat(secu.resultado).format(2, 3, '.', ',');
                    return true;
                }
            }
            return false;
        }

        Number.prototype.format = function(n, x, s, c) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = this.toFixed(Math.max(0, ~~n));
            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        }

        function regex(datos) {
            var myRe = /.*##([0-9]+)##/,
                match = datos.match(myRe);
            if(match == null){
                return "";
            }
            else{
                return parseInt(match[1]);
            }
        }
    }
})();
